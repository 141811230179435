import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Form, Collapse, Button, Row, Col } from "antd";
import currency from "helpers/currency";

import { Context } from "state/store";
import api from "helpers/api";
import systemAction from "state/actions/systemAction";
import orderAction from "state/actions/orderAction";
import PanelTimeline from "components/panelTimeline";
import CustomerDetail from "components/customerDetail";
import DeliveryPickup from "components/deliveryPickup";
import { touristSummary } from "helpers/buildSummary";
import StepIndicator from "components/stepIndicator";

const customerType = 'TOURIST';

const customerFormField = {
  salutation: true,
  fin_expiry_date: 'required',
  passport_full_name: 'required',
  passport_number: 'required',
  date_of_arrival: 'required',
  flight_number: 'required',
  email: 'required',
}

export default function CustomerInfo() {
  const { t } = useTranslation();
  const history = useHistory();
  const [state, dispatch] = useContext(Context);
  const {orderStep, customerInfo, packagePlan, simCardType, summary} = state.orderReducer;
  const {user} = state.userReducer;
  const {allowPage} = state.systemReducer;
  const [formCustomerDetail] = Form.useForm();
  const [formDeliveryPickup] = Form.useForm();

  const steps = [t('step1'), t('step2'), t('step3')];

  const propsDeliveryPickup = {
    data: !!customerInfo
      ? customerInfo.deliveryPickup
      : { delivery_type: 1 },
    form: formDeliveryPickup,
    pickupOnly: true,
    deliveryType: 1,
    customerType,
  }

  const propsCustomerDetail = {
    data: !!customerInfo ? customerInfo.customerDetail : null,
    form: formCustomerDetail,
    field: customerFormField,
    customerType
  }

  // get error or data by form
  const submitForm = (form) => {
    return form.validateFields()
      .then(res => res)
      .catch(err => err)
  }

  const sendOrderStep = async (data) => {

    let orderDetail = [];

    // order_detail
    summary.forEach(obj => {
      if(!!obj.item_id) {
        orderDetail.push(
          {item_id: obj.item_id, quantity: obj.quantity}
        )
      }
    })

    let order = {
      ...orderStep,
      ...data.customerDetail,
      ...data.deliveryPickup,
      date_of_arrival: data.customerDetail.date_of_arrival.format('YYYY-MM-DD'),
      customer_type: data.customerType,
      order_type: data.order_type,
      order_detail: JSON.stringify(orderDetail)
    }
    
    // send to api order step
    orderAction.orderStep(dispatch, order)
  }

  const onLoad = () => {
    systemAction.stopLoading(dispatch);
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }

  const submit = async() => {
    let error = false;
    let data = {
      customerType,
      customerDetail: await submitForm(formCustomerDetail),
      deliveryPickup: await submitForm(formDeliveryPickup),
      delivery_form: customerInfo.delivery_form,
    }
    
    /*Object.keys(data).map(obj => 
      error = error ? error : !!data[obj].errorFields
    );*/

    if(!error) {

      let step =  "/tourist/order-review"
      let newAllowStep = [...allowPage, step];

      systemAction.startLoading(dispatch);
      sendOrderStep({...data, summary})
      systemAction.allowPage(dispatch, newAllowStep);
      orderAction.customerInfo(dispatch, data);
      orderAction.type(dispatch, 'New Order');
      orderAction.customerInfo(dispatch, data);
      history.push(step)
    }
  }

  useEffect(onLoad, []);

  return (
    <div className="container" data-sticky-container >
      <StepIndicator steps={steps} current={1} />

      <Row gutter={24} className="margin-bottom">
        <Col xs={24} md={24}>
        <PanelTimeline title={t('lblSectionCustomerData')} >
          <CustomerDetail {...propsCustomerDetail} />
        </PanelTimeline>
        {customerInfo.delivery_form === true &&
        <PanelTimeline title={t('lblSectionPickup')} noLine>
          <Row gutter={16}>
            <Col xs={24} md={16}>           
              <DeliveryPickup {...propsDeliveryPickup} />
            </Col>
          </Row>
        </PanelTimeline>
        }
        </Col>
        <Col xs={24}>
          <div className="flex flex-right margin-top">
            <Button type="primary" htmlType="submit" onClick={() => submit()}>
              {t('btnNext')}
              <RightOutlined />
              </Button>
          </div>
          <Button className="margin-top" type="default" onClick={() => history.goBack()}>
            <LeftOutlined />
            {t('btnBack')}
          </Button>
        </Col>
      </Row>

    </div>
  );
}
