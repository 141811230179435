import api from 'helpers/api';
import storage from 'helpers/storage';

const userAction = {
  logout: (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        storage.remove().then(() => {
          dispatch({type: 'USER_LOGOUT'})
          resolve(true)
        });
      } catch (error) {
        reject(error.response)
      }
    })
  },
  login: (dispatch, params) => {
    return new Promise(async (resolve, reject) => {
      try {
        const user_name = params.user_name.toUpperCase();
        const {data} = await api.post('order_portal/auth_plain', {...params, user_name});

        if(!!data && !!data.error) {
          reject(!!data.error);
        } else {
          let auth = {
            id: data.id,
            rep_id: data.rep_id,
            session_id: data.session_id,
            expiry_date: data.expiry_date,
            full_name: data.first_name + ' ' + data.last_name,
          }
          let payload = {
            ...auth,
            current: {
              ...auth,
              master_name: data.master_firstname + ' ' + data.master_lastname
            }
          }
          storage.set('user', payload)
          dispatch({ type: "USER_LOGINED", payload })

          resolve(data);
        }
      } catch (error) {
        reject(error.response.data)
      }
    })
  }
};

export default userAction;