import { isString } from "lodash";

const currency = (number, decimal = 2, freeLabel = false) => {
  let symbol = '$';
  let n = isString(number) ? Number(number.replace(/[$,]/g,'')) : number;

  if(n === 0 && freeLabel) return 'Free';
  if(n < 0) {
    n = Math.abs(n);
    symbol = '- $';
  }

  return symbol + n.toFixed(decimal).replace(/\d(?=(\d{3})+\.)/g, '$&,');
}

export default currency;