import React from "react";
import { Collapse } from "antd";

const { Panel } = Collapse;

export default function TermCondition() {
  const data = [
    {
      title: 'Definition and Interpretation',
      content: {
        __html: `<ol>
            <li>The General Terms shall apply and govern each and/or all of the Services in addition to any Specific Terms, and in the event, there is a conflict of inconsistency between the Terms, it shall be resolved in a manner most favourable to the Service Provider.</li>
            <li>Broadband Services refer to mobile wireless broadband (FREEDOM), fixed broadband (FTTH internet) services (INFINITY) and WIFI services.</li>
            <li>This Specific Terms and Conditions for Broadband Services shall apply and govern all products and Services in relation to FREEDOM, INFINITY and wireless Plans depicted on the Platform under the umbrella of the General Terms.</li>
            <li>In this Specific Terms and Conditions for Broadband Services, words and expressions shall have the same meaning as outlined in Clause 1.1 of the ‘General Terms’ and Conditions of Services.</li>
            <li>Unless otherwise stated with reference to any other Terms, any reference made in this Specific Terms to any Clauses shall be interpreted as to Clauses within these terms.</li>
            <li>Unless otherwise stated to be for International or Roaming use, the provision of Services for data in these Specific Terms shall be interpreted for local use only.</li>
          </ol>`
        }
    },
    {
      title: 'Registration Information',
      content: {
        __html: `<ol>
            <li>Registration for Broadband Plans must be submitted in compliance with all applicable Registration Forms in addition to the supplementary information required by the Service Provider according to the classification of the Broadband Subscriber.</li>
            <li>Registration for Broadband Plans can be done either online via the Platform, at any of the Service Provider’s branch or via an authorised sales agent of the Service Provider.</li>
          </ol>`
        }
    },
    {
      title: 'Mobile Wireless Broadband Plans',
      content: {
        __html: `<ol>
            <li>The different types of mobile wireless broadband Plans Subscribers may engage in pursuant to Clause 2 are as listed on the Platform.</li>
          </ol>`
        }
    },
    {
      title: 'Fixed Broadband Plans',
      content: {
        __html: `<ol>
            <li>The different types of fixed broadband Plans Subscribers may engage in pursuant to Clause 2 are as listed on the Platform.</li>
            <li>The availability and provision of the fixed broadband Service is subject to the Subscriber’s installation address being fibre-ready and subject to the network availability.</li>
          </ol>`
        }
    },
    {
      title: 'Add-On Bundles',
      content: {
        __html: `<ol>
            <li>The available Data Add-On Bundles which can be purchased to supplement the mobile wireless broadband and/or fixed broadband  Services are as listed on the Platform.</li>
          </ol>`
        }
    },
    {
      title: 'Installation of Fixed Broadband Service',
      content: {
        __html: `<ol>
            <li>The Subscriber will receive a call from the Service Provider confirming the installation appointment date and time.</li>
            <li>The Subscriber must inform the Service Provider at least one (1) working day (before 4pm) prior to the appointment date for any rescheduling or cancellation of the installation. However, the Service Provider may not be able to guarantee the preferred date of the Subscriber. The Service Provider reserves the right to charge you a certain fee at the prevailing rate for any late rescheduling or cancellation request.</li>
            <li>The Subscriber consents to the Service Provider and/or our agents gaining access to the installation address provided by the Subscriber upon registration. The Service Provider will not be held responsible for any installation issues arising from errors in details of the installation address provided by you.</li>
            <li>For installation of the fixed broadband service, Subscribers must agree to the drilling area (if required) before beginning installation.</li>
            <li>The Subscriber must be present during installation, or in the event the Subscriber cannot be present, must designate a person aged 18 or above to be present on your behalf for installation of the Service, failing which the Service provider will not proceed with the installation.</li>
          </ol>`
        }
    },
    {
      title: 'Equipment',
      content: {
        __html: `<ol>
            <li>If the Subscriber is relocating, the Subscriber must provide the Service Provider with prior notice of their relocation. Relocation will be subject to the Service coverage area.</li>
            <li>The Subscriber may be subject to the applicable installation and/or relocation fees.</li>
            <li>The Subscriber will be required to bring over the equipment provided by the Service Provider (which includes accessories for equipment to connect to the fixed broadband or to the fibre wall socket) from the current installation address to the new relocation address.</li>
            <li>The Subscriber is responsible, at all times, for the safety of the Service Provider’s Equipment at the installation address provided. Upon termination of the Service, the Service Provider’s Equipment shall be returned in good working condition, failing which the Subscriber may be charged a penalty. </li>
            <li>The Equipment provided to you by the Service Provider remains the Service Provider’s property.</li>
            <li>The Equipment provided by the Service Provider on installation has a warranty that is reflective of the Subscriber’s contract with the Service Provider. The Subscriber may purchase their own equipment for using the Service, but the Service Provider shall not in any way warrant the quality of the Service from the Subscriber’s equipment.</li>
            <li>Warranty for the Equipment provide does not cover: </li>
            <ol type="a">
                <li>damage, lost, stolen or defects caused by any act, omission, misuse, negligence including usage of the Equipment contrary to instructions provided by the Service Provider or the manufacturer;</li>
                <li>where the serial number/IMEI has been removed or defaced;</li>
                <li>products that have been subject to unauthorised removal or deactivation of the Service Provider-lock function.</li>
            </ol>
            <li>The Service Provider may suggest and upsell devices to the Subscriber which may improve the Service connectivity at the installation address. The Subscriber may be charged either as a one-off fee OR as a monthly value added plan.</li>
            <li>The Subscriber will be tied to a commitment period for devices with a monthly instalment plan where the commitment period will be monthly, 12 or 24 months depending on the Plan and Service subscribed to.</li>
            <li>The Subscriber may not, during the commitment period: </li>
            <ol type="a">
                <li>terminate the Service;</li>
                <li>terminate or suspend the Service account or port out to another internet service provider;</li>
                <li>have your Service account terminated for non-payment of monthly bills;</li>
                <li>change or transfer ownership of the Subscriber’s account and / or the Service.</li>
            </ol>
            <li>If the Subscriber suspends Service during the commitment period, the device’s monthly instalment fee will continue to be charged to the Subscriber.</li>
          </ol>`
        }
    },
    {
      title: 'Specific Obligations',
      content: {
        __html: `<ol>
            <li>The Subscriber agrees that:
            </li>
              <ol type="a">
                <li>They have read, understood and accepted these Specific Terms and these Specific Obligations which regulates their use of the Services above including the General Terms and Conditions;</li>
                <li>Data Add-on purchases will be billed to the monthly bill for the respective broadband subscribers;</li>
                <li>Data Add-On packages can be purchased via the Service Provider’s Platform;</li>
                <li>Data Add-On purchases cannot be cancelled and are not refundable;</li>
                <li>For any Contract Plans depicted above or on the Platform, the Subscriber shall enter into the mandatory contractual term that shall commence from the date of subscribing to the Plan and shall pay the applicable fees or charges of the subscribed Plan;</li>
                <li>The standard warranty terms on any device Bundled with their Contract may vary according to its make and model;</li>
                <li>For any Plan depicted on the Platform, the Subscriber shall be obliged to pay the applicable deposit stipulated before its commencement of using the services;</li>
                <li>Fair Use Policy applies for mobile wireless or fixed broadband Services;</li>
                <li>The Subscriber may notify the Service Provider prior to expiry of their Contract Plan failing which the Contract Plan will be automatically renewed upon expiry of their existing Contract Plan;</li>
                <li>If they terminate any Contract plan prior to its completion, the Subscriber may be subject to penalty fee that take into consideration the remaining period of the Plan, the amount equivalent to the device (if applicable) and/or any additional fees at the discretion of the Service Provider;</li>
                <li>The terms and conditions for Mobi Specific Terms shall also be applicable in addition to this Specific Terms & Conditions for this Service;</li>
                <li>The Service Provider may terminate this Contract due to a breach by the Subscriber to any of the terms above or to any of its obligations and duties under the General Terms;</li>
                <li>Data roaming is available in the countries listed on the Platform subject to the prevailing data roaming charges and availability of the Service on the subscribed Plan;</li>
                <li>For any planned interruption Service(s), adequate notice will be provided via the Platform or any other mode of communication as deemed appropriate; and</li>
                <li>The Service Provider reserves the right at any time, without being liable to the Subscriber, to interrupt, bar, suspend, restrict the Service(s) for such time as the Service Provider sees fit if the Subscriber uses such Service(s) for unlawful activities or for fraudulent activities.</li>
              </ol>
            <li>
              The Service Provider agrees that:
            </li>
            <ol type="a">
              <li>They shall not charge the Subscriber for use of any of its support line, customer care or operator services unless otherwise stated;</li>
              <li>They shall notify the mobile wireless and fixed broadband users once they have used up to 80% and 100% of their local data allowance. However, Subscribers can also access their data usage via the Platform;</li>
              <li>The Subscriber may revise or upgrade their monthly subscription plan via any of the Service Provider’s channels;</li>
              <li>Any deposit payment made by the Subscriber shall be refunded, free of interest, to the Subscriber when their obligations under the Contract or Plan has been fully performed and there is no outstanding debt accrued;</li>
              <li>They shall provide the Subscriber with standard warranty terms on any device Bundled with their Contract which may vary according to its make and model.</li>
            </ol>
          </ol>`
        }
    },

  ]

  return (
    <div className="container term-condition">
      <h3 className="text-center text-bold margin-rm-top margin-md-bottom">
        Specific Terms and Conditions for Broadband Services
      </h3>

      <Collapse
        accordion
        expandIconPosition="end"
      >
        {data.map((obj, i) => 
          <Panel header={ (i + 1) + '. ' + obj.title} key={i + 1} >
            <div dangerouslySetInnerHTML={obj.content} />
          </Panel>
        )}
      </Collapse>
    </div>
  );
}