import * as _ from 'lodash';
import currency from './currency';

const getRangePrice = items => {
  let price = [];
  items.map(item =>
    price.push(item.price_amount)
  );
  price = _.sortedUniq(price);
  let low = currency(price[0]);
  let upper = price.length > 1
    ? ' - ' + currency(price[price.length-1])
    : '';

  return low + upper;
}

export default getRangePrice;