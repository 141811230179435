import moment from 'moment';

const deliveryDate = {
  isDisabled: (current, delivery_type, publicHoliday) => {
    const isHoliday = publicHoliday.find(obj => current.format('YYYY-MM-DD') === obj.holiday_date)
    const isSunday = moment(current).day() === 0
  
    let isMinDate = true
    if(delivery_type === 1) {
      isMinDate = current <= moment()
    } else if(delivery_type === 2) {
      isMinDate = current <= moment().add(1, 'day')
    } else {
      isMinDate = current <= moment().subtract(1, 'day')
    }
    
    return isHoliday || isSunday || isMinDate 
  },
  instanDeliveryDate: (publicHoliday, dateNow = moment()) => {
    const holiday = publicHoliday
    const isHoliday = holiday.find(obj => moment(obj.holiday_date) === dateNow)
    const isSunday = dateNow.day() === 0

    if(!isHoliday && !isSunday) {
      return dateNow
    } else {
      return deliveryDate.instanDeliveryDate(holiday, dateNow.add(1, 'day'))
    }
  }
}

export default deliveryDate;