import React from 'react';
import { Button, Checkbox } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function ReviewConsent({
  tnc,
  setTnc,
  submit,
  errorMessage
}) {
  const { t } = useTranslation();
  
  return (
    <>
      <Checkbox checked={tnc} onChange={() => setTnc(!tnc)}>
        <span>{t('lblTermCondition1')} </span>
        <Link to="/terms-and-conditions" target="_blank">
          {t('lblTermCondition2')}
        </Link>  
        <span> {t('lblTermCondition3')}</span>
      </Checkbox>
      {!!errorMessage.tnc && !tnc &&
        <p className="error-text">{errorMessage.tnc}</p>
      }
      <br />
      <Button
        type="primary"
        size="large"
        htmlType="submit"
        className="margin-top"
        onClick={() => submit()}
      >
        {t('btnSubmit')}
      </Button>
    </>
  );
};