import React from "react";
import { useRouteMatch, Route, Switch } from "react-router-dom";
import RouteGuard from "router/routeGuard";
import PackagePlan from 'pages/tourist/packagePlan';
import CustomerInfo from 'pages/tourist/customerInfo';
import OrderReview from 'pages/tourist/orderReview';
import OrderFinish from "pages/tourist/orderFinish";
import NotFound from "pages/notFound";

export default function RouteTourist() {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/package-plan`} component={PackagePlan} />
      <RouteGuard path={`${path}/customer-info`} component={CustomerInfo} />
      <RouteGuard path={`${path}/order-review`} component={OrderReview} />
      <RouteGuard path={`${path}/order-finish`} component={OrderFinish} />
      <Route path="*" component={NotFound}/>
    </Switch>
  );
}
