import * as _ from 'lodash';

const getChildFilter = list => {
  let filter = {};

  list.forEach(obj => {
    const attr = obj.details.attribute;
    Object.keys(attr).forEach(key => {
      if(!!attr[key] && attr[key] !== '-') {
        let fk = !!filter[key] ? filter[key] : [];
        filter[key] = [
          ...fk,
          attr[key]
        ]
      }
    })
  });

  Object.keys(filter).map(key => 
    filter[key] =  _.uniqBy(filter[key])
  );

  return filter;
}

export default getChildFilter;