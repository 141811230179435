/* 
  ------------------------------
  for order step and create order
  / item_id,
  / quantity
  and if item_id not empty/null/undefined
  ------------------------------
  ------------------------------
  / isCount -> for total calculation, default: false
  / child -> for tabs position item, default: null
  ------------------------------
*/

export const addSummary = (items = [], list = []) => {
  let result = list;

  items.forEach((obj) => {
    //console.log(obj)

    result = [
      ...result,
      {
        group: obj.group,
        item_id: !!obj.bundle_item_id ? obj.bundle_item_id : obj.id,
        sku_code: !!obj.sku_code ? obj.sku_code : null,
        name: !!obj.bundle_name ? obj.bundle_name : obj.name,
        price: !!obj.price ? obj.price : obj.price_amount,
        quantity: !!obj.quantity ? obj.quantity : 1,
        child: obj.child ? childSummary(obj.child) : null,
        isCount: !!obj.isCount
      }
    ];
  });

  return result;
}

export const childSummary = (items = []) => {
  let result = [];

  items.forEach((item) => {
    result.push({
      item_id: !!item.bundle_item_id ? item.bundle_item_id : item.id,
      sku_code: !!item.sku_code ? item.sku_code : null,
      name: !!item.bundle_name ? item.bundle_name : item.name,
      price: !!item.price ? item.price : item.price_amount,
      quantity: !!item.quantity ? item.quantity : 1,
      isCount: !!item.isCount
    });
  })
  
  return result;
}

export const quantitySummary = (qty, itemId, items = []) => {
  let tmpSummary = items.find(obj => obj.item_id === itemId);
  tmpSummary.quantity = qty;      
  tmpSummary.child.forEach(obj => {
    obj.quantity = qty
  });

  return [
    tmpSummary,
    ...items.filter(obj => obj.item_id !== itemId)
  ];
}

export const removeSummary = (selector, list = [], by = 'item_id') => {
  return list.filter(obj => obj[by] !== selector);
}

export const touristSummary = (item, sim_card) => {
  let result = [
    {
      group: 1,
      item_id: sim_card.product,
      name: sim_card.name,
      price: sim_card.product_price,
      quantity: item.product_count,
      isCount: true
    },{
    group: 1,
    item_id: item.product,
    name: item.name,
    price: item.product_price,
    quantity: item.product_count,
    isCount: true
  }];
  return result;
}

export const easiRechargeSummary = (item) => {
  let result = [{
    group: 1,
    item_id: item.product,
    name: item.name,
    price: item.product_price,
    quantity: item.product_count,
    isCount: true
  }];
  return result;
}