import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RightOutlined } from '@ant-design/icons';
import { Form, Button } from "antd";

import { Context } from "state/store";
import api from "helpers/api";
import systemAction from "state/actions/systemAction";
import orderAction from "state/actions/orderAction";
import PanelTimeline from "components/panelTimeline";
import CustomerDetail from "components/customerDetail";
import ChoosePackage from "./components/choosePackage";
import { touristSummary } from "helpers/buildSummary";

const customerType = 'WIFI';

const customerFormField = {
  email: 'required',
  mobile_phone: 'required'
}

export default function CustomerInfo() {
  const { t } = useTranslation();
  const history = useHistory();
  const [state, dispatch] = useContext(Context);
  const {orderStep, customerInfo, packagePlan} = state.orderReducer;
  const {user} = state.userReducer;
  const [formCustomerDetail] = Form.useForm();
  const [formChoosePackage] = Form.useForm();
  const [formDeliveryPickup] = Form.useForm();

  const propsCustomerDetail = {
    data: !!customerInfo ? customerInfo.customerDetail : null,
    form: formCustomerDetail,
    field: customerFormField
  }

  const propsChoosePackage = {
    data: !!packagePlan ? packagePlan : null,
    form: formChoosePackage,
    repId: user.rep_id,
    customerType
  }

  // get error or data by form
  const submitForm = (form) => {
    return form.validateFields()
      .then(res => res)
      .catch(err => err)
  }

  const sendOrderStep = async (data) => {
    let orderDetail = [];

    // order_detail
    Array(Number(data.choosePackage.product_count)).fill().map(() =>
      orderDetail.push({ 
        item_id: data.choosePackage.product,
        quantity: 1
      })
    )

    let order = {
      order_step: {
        ...data.customerDetail,
        nationality: 'Foreigner',
        date_of_arrival: data.customerDetail.date_of_arrival.format('YYYY-MM-DD'),
        customer_type: data.customerType,
        order_detail: JSON.stringify(orderDetail)
      }
    }

    dispatch({ type: "ORDER_STEP", payload: order })
    if(!orderStep || !orderStep.step_id) {
      let res = await api.post('order_portal/create_order_step', { order })
      if(!!res.data) order['step_id'] = res.data
    } else {
      order['step_id'] = orderStep.step_id
      await api.post('order_portal/update_order_step', { order })
    }
  }

  const submit = async() => {
    let error = false;
    let choosePackage = await submitForm(formChoosePackage);
    let data = {
      customerType,
      customerDetail: await submitForm(formCustomerDetail),
      deliveryPickup: await submitForm(formDeliveryPickup)
    }
    
    // check errorFields
    if(!!choosePackage.errorFields) error = true
    Object.keys(data).map(obj => 
      error = error ? error : !!data[obj].errorFields
    );

    if(!error) {
      let page = "/tourist/order-review"
      sendOrderStep({...data, choosePackage})

      systemAction.startLoading(dispatch);
      systemAction.allowPage(dispatch, [page]);
      orderAction.customerInfo(dispatch, data);
      orderAction.packagePlan(dispatch, choosePackage);
      orderAction.summary(dispatch, touristSummary(choosePackage));
      history.push(page)
    }
  }

  return (
    <div className="container">
      <div className="margin-md-top margin-bottom">
        <PanelTimeline title={t('lblSectionCustomerData')}>
          <CustomerDetail {...propsCustomerDetail} />
        </PanelTimeline>
        <PanelTimeline title={t('lblSectionProduct')} noLine>
          <ChoosePackage {...propsChoosePackage} />
        </PanelTimeline>

        <div className="flex flex-right margin-top">
          <Button type="primary" htmlType="submit" onClick={() => submit()}>
            {t('btnNext')}
            <RightOutlined />
          </Button>
        </div>
      </div>
    </div>
  );
}
