import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Row, Col, Collapse, Divider, Button, Switch, Modal, Form } from 'antd';
import currency from "helpers/currency";
import {
  PhoneOutlined,
  MailOutlined,
  GlobalOutlined,
  WifiOutlined,
  LeftOutlined,
  CheckOutlined,
  CloseOutlined
} from '@ant-design/icons';

import { addSummary, removeSummary } from "helpers/buildSummary";
import { Context } from "state/store";
import { def } from "config/const";
import api from "helpers/api";
import sourceAction from "state/actions/sourceAction";
import systemAction from "state/actions/systemAction";
import orderAction from "state/actions/orderAction";
import StepIndicator from "components/stepIndicator";
import PanelTimeline from "components/panelTimeline";
import CardPackage from "components/cardPackage";
//import QuantityEditor from "components/quantityEditor";
import Summary from "components/summary";
import ObjectValidator from "helpers/objectValidator";
import Device from "components/device";
import deviceSort from "helpers/deviceSort";
import getRangePrice from "helpers/getRangePrice";
import mainThumbnail from "helpers/mainThumbnail";
import noImage from "assets/images/no-image.png";
import useCheckMark from "hook/useCheckMark";
import useAutoNumber from "hook/useAutoNumber";
import Sticky from "sticky-js";
import OrderType from "components/orderType";
import icEffect from 'helpers/icEffect';

const { Panel } = Collapse;

export default function PackagePlan() {
  const { t } = useTranslation();
  const history = useHistory();
  const {bookingInventory, bookingNumber} = useCheckMark();
  const {generateNumber} = useAutoNumber();
  const [state, dispatch] = useContext(Context);
  const {orderStep, orderType, customerInfo, bookedId, packagePlan, autoNumber } = state.orderReducer;
  const {offerings} = state.sourceReducer;
  const {allowPage} = state.systemReducer;
  const {user} = state.userReducer;
  const [summary, setSummary] = useState([]);
  const [deviceGroup, setDeviceGroup] = useState({});
  const [autoNumberValue, setAutoNumberValue] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});
  const [selectedPackage, setSelectedPackage] = useState([]);
  const [selectedParent, setSelectedParent] = useState('');
  const [selectedChild, setSelectedChild] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState({});
  const [mainActive, setMainActive] = useState([]);
  const [deviceActive, setDeviceActive] = useState([]);
  const [modalOrderType, setModalOrderType] = useState(true);
  const [modalDevice, setModalDevice] = useState(false);
  const [simCardGroup, setSimCardGroup] = useState({});
  const [simCardActive, setSimCardActive] = useState([]);
  const [selectedSimCard, setSelectedSimCard] = useState(null);
  const [deviceFlag, setDeviceFlag] = useState(false);
  const [esimFlag, setEsimFlag] = useState(false);
  
  const steps = [t('step1'), t('step2'), t('step3')];

  const nationality = 'Foreigner';

  const params = {
        customerInfo: {
          customerType: 'TOURIST',
          customerDetail:{
            nationality
          }
        },
        rep_id: user.rep_id,
        orderType: 'New Order'
      }

  // effect after click package
  const selectPackage = value => {
    systemAction.startLoading(dispatch)
    // unselect device
    setSelectedParent('');
    setSelectedChild([]);
    setSelectedSimCard(null);
    setEsimFlag(false);

    let tmpSummary = [];
    let selected = selectedPackage;
    let exist = selected.find(obj => obj.id === value.id);

    if(!!exist) {
      // unselect
      selected = selected.filter(obj => obj.id !== value.id);
      setSelectedPackage(selected);
      setSummary(() => {
        let noDevice = removeSummary(def.group.device, summary, 'group')
            noDevice = removeSummary(def.group.promo, noDevice, 'group')
            noDevice = removeSummary(def.group.simCard, noDevice, 'group')

        return removeSummary(def.group.main, noDevice, 'group')
      });
      systemAction.stopLoading(dispatch)

    } else {
      // select
      value['quantity'] = 1;

      // category number
      let name = value.name.toUpperCase();
      value['number_category'] = name.search('GOLDEN') !== -1 ? 'Golden' : 'Normal';

      // single select
      selected = [ value ];

      setSelectedPackage(selected);
      tmpSummary = [{
        ...value,
        isCount: true,
        group: def.group.main
      }];

      sourceAction.dependencyCatalog(dispatch, params, value.id, value.id)
        .then(result => {
          // get bundle contract
          let bundleContractId = result.bundle_contract.length > 0
            ? result.bundle_contract[0].id
            : null;
          orderAction.bundleContract(dispatch, bundleContractId);

          // get member
          let child = [];
          let member = result.member_item
          member.forEach(obj => {
            if(obj.tag_id !== def.groupTag.internet) {
              child.push({
                ...obj
              })
            }
          });

          // get deposit
          let deposit = result.deposit
          if(deposit != null){
            deposit.forEach(obj => {
              child.push({
                ...obj,
                name: 'Deposit',
                isCount: true
              })
            });
          }

          // sub item of main
          tmpSummary[0]['child'] = child;

          setSummary(() => {
            // remove some item before add summary
            let rmSummary = removeSummary(def.group.main, summary, 'group')

            // push to summary
            return addSummary(tmpSummary, rmSummary)  
          });

          setSimCardGroup(selected[0].sim_option)

          // get device group by package mamber
          setDeviceGroup(
            selected.length === 1 && !!selected[0].devices
              ? deviceSort(selected[0].devices)
              : {}
          );
          systemAction.stopLoading(dispatch)
        })
        .catch(error => {
          console.log(error)
          systemAction.stopLoading(dispatch)
        })

    }
  }

  const selectSimCard = async (data) => {
    setEsimFlag(false);
    if(selectedSimCard !== data) {
      // select
      setSelectedSimCard(data);
      if(data.bundle_item_id != 0){
        setEsimFlag(true);
      }

      let tmpSummary = [{
        ...data,
        isCount: true,
        name: t('lblSummarySimCard'),
        group: def.group.simCard,
      }]

      setSummary(tempSummary => {
        // remove some item before add summary
        let rmSummary = removeSummary(def.group.simCard, tempSummary, 'group')

        // push to summary
        return addSummary(tmpSummary, rmSummary)
      });
      if(!!orderType && offerings.length == 0) {
        systemAction.startLoading(dispatch)
        sourceAction.offerings(dispatch, state.orderReducer)
          .finally(() => {
            systemAction.stopLoading(dispatch)
          })
      }
      
    }
    
  }

  const validate = () => {
    const schema = {
      selectedPackage: { require: () => !!selectedPackage.length ? '' :'Please choose plan' },
      simOption: { require: () => !!selectedSimCard ? '' : 'Please choose sim option' },
    }
    const error = ObjectValidator(schema)
    setErrorMessage(error)
    return error
  }

  const sendOrderStep = async (data, bookNumber = {}) => {
    let orderDetail = [];

    // order_detail
    data.forEach(obj => {
      if(!!obj.item_id) {
        Array(Number(obj.quantity)).fill().map(() =>
          orderDetail.push(
            {item_id: obj.item_id, quantity: 1}
          )
        )
      }
    })

    let order = {
      order_step: {
        ...bookNumber,
        order_detail: JSON.stringify(orderDetail),
        nationality
      }
    }
    
    dispatch({ type: "ORDER_STEP", payload: order })
    if(!orderStep || !orderStep.step_id) {
      let res = await api.post('order_portal/create_order_step', { order })
      if(!!res.data) order['step_id'] = res.data
    } else {
      order['step_id'] = orderStep.step_id
      await api.post('order_portal/update_order_step', { order })
    }
  }

  const submit = async () => {
    if(!!validate()) return;

    let newStep = '/tourist/customer-info';
    let newAllowStep = [...allowPage, newStep];
    let packagePlan = {
      device: !!selectedDevice ? selectedDevice : null,
      plan: [selectedPackage[0]],
    }

    //check valid delivery flag
    let delivery_form;
    if(esimFlag == true && deviceFlag == false){
      
      delivery_form = false;
    }else{
      delivery_form = true;
    }
    
    orderAction.customerInfo(dispatch, {...customerInfo, delivery_form});
    
    systemAction.startLoading(dispatch);
    sendOrderStep(summary);
    orderAction.summary(dispatch, summary);
    orderAction.packagePlan(dispatch, packagePlan);
    systemAction.allowPage(dispatch, newAllowStep);
    history.push(newStep);
  }

  const onOfferingsLoad = () => {
    if(!!packagePlan && packagePlan.plan.length) {
      offerings.forEach((group, groupKey) => {
        group.member.forEach((member, memberKey) => {
          const match = packagePlan.plan.find(i => i.id === member.id)
          if(!!match) {
            selectPackage(member)
            setMainActive([String(groupKey)])
          }
        })
      })
    }
    if(!!autoNumber) setAutoNumberValue(autoNumber)
  }

  const onLoad = () => {
    new Sticky('.sticky');

    if(!bookedId) {
      orderAction.getBookedId(dispatch);
    }
    orderAction.customerInfo(dispatch, {...customerInfo, nationality});

    if(offerings.length == 0) {
      systemAction.startLoading(dispatch)
      sourceAction.offerings(dispatch, params)
        .finally(() => {
          systemAction.stopLoading(dispatch)
        })
    }

    
    systemAction.allowPage(dispatch, ['/easi/package-plan']);
    
    if(orderType === 'Port-IN') setAutoNumberValue(true)
  }

  useEffect(onLoad, []);
  useEffect(onOfferingsLoad, [offerings]);

  return (
    <>
    <div className="container" data-sticky-container>
      <StepIndicator steps={steps} current={0} />

      <Row gutter={24} className="margin-bottom">
        <Col xs={24} md={16}>

          <PanelTimeline title={t('lblSummaryMainSim')}>

            {!!errorMessage.selectedPackage && !selectedPackage.length &&
              <p className="error-text">{errorMessage.selectedPackage}</p>
            }

            <Collapse
              ghost
              accordion
              className="accordion"
              expandIconPosition="end"
              activeKey={mainActive}
              onChange={(res) => setMainActive([res])}
            >
              {offerings.map((group, groupKey) => 
                <Panel header={group.name.toUpperCase()} key={groupKey}>
                  {group.member.map((member, memberKey) =>
                    <CardPackage
                      key={memberKey}
                      active={!!selectedPackage.find(obj => obj.id === member.id)}
                      onClick={() => selectPackage(member)}
                    >
                      <h6 className="text-brand text-bold margin-rm flex flex-between">
                        <span>{member.name}</span>
                        <span>{member.price}</span>
                      </h6>
                      <Divider className="margin-sm-top margin-sm-bottom" />
                      <div className="flex">
                        <div className="flex-auto">
                          {member.spec &&
                          <React.Fragment>
                            {member.spec.call ? <span className="padding-right"><PhoneOutlined /> {member.spec.call}</span> : ''}
                            {member.spec.text ? <span className="padding-right"><MailOutlined /> {member.spec.text} </span> : ''}
                            {member.spec.data ? <span className="padding-right"><GlobalOutlined /> {member.spec.data} </span> : ''}
                            {member.spec.roaming ? <span className="padding-right"><WifiOutlined /> {member.spec.roaming} </span> : ''}
                          </React.Fragment>
                          }
                        </div>
                        {/* {orderType !== 'Port-IN' &&
                          <QuantityEditor
                            quantity={initQuantity(member.id)}
                            setQuantity={(qty, event) => setQuantity(qty, member.id, event)}
                            disabled={!selectedPackage.find(obj => obj.id === member.id)}
                          />
                        } */}
                      </div>
                    </CardPackage>
                  )}
                </Panel>
              )}
            </Collapse>
          </PanelTimeline>

          <PanelTimeline title={t('lblSectionProductSimCard')} noLine>
          {!!errorMessage.simOption &&
            <p className="error-text">
              {errorMessage.simOption}
            </p>
          }
          {!!simCardGroup &&
                  <Row gutter={16} className="margin-bottom padding-sm-left padding-sm-right">
                  {Object.keys(simCardGroup).map((parent, k) =>
                    <Col xs={24} md={8} key={parent} className="padding-rm">
                      <CardPackage
                        active={simCardGroup[parent] == selectedSimCard}
                        onClick={() => selectSimCard(simCardGroup[parent])}
                      >
                        <h5 className="text-brand text-bold margin-sm-top margin-sm-bottom">
                          {simCardGroup[parent]['bundle_name']}
                        </h5>
                        <p className="margin-rm">
                          { currency(simCardGroup[parent]['price_amount']) }
                        </p>
                      </CardPackage>
                    </Col>
                  )}
                  </Row>
              }
          </PanelTimeline>

        </Col>

        <Col xs={24} md={8}>
          <div className="sticky" data-margin-top="90">
            <Summary
              data={summary}
              submit={() => submit()}
              mainLabel={t('lblSummaryMainSim')}
              showQty={true}
            />
          </div>
        </Col>

        <Col xs={24}>
          <Button className="margin-top" type="default" onClick={() => history.goBack()}>
            <LeftOutlined />
            {t('btnBack')}
          </Button>
        </Col>
      </Row>

      

    </div>
    </>
  );
}
