const initState = {
  orderType: null,
  orderStep: null,
  orderResult: null,
  customerInfo: null,
  bundleContract: null,
  packagePlan: null,
  autoNumber: false,
  simNumber: null,
  referral: null,
  bookedId: null,
  summary: [],
  otp: null
}

const orderReducer = (state = initState, action) => {
  switch (action.type) {
    case 'ORDER_TYPE':
      return {
        ...state,
        orderType: action.payload
      }
    case 'ORDER_STEP':
      return {
        ...state,
        orderStep: action.payload
      }
    case 'ORDER_PREVIOUS_PROVIDER':
      return {
        ...state,
        previousProvider: action.payload
      }
    case 'ORDER_CUSTOMER_INFO':
      return {
        ...state,
        customerInfo: action.payload
      }
    case 'ORDER_PACKAGE_PLAN':
      return {
        ...state,
        packagePlan: action.payload
      }
    case 'ORDER_BUNDLE_CONTRACT':
      return {
        ...state,
        bundleContract: action.payload
      }
    case 'ORDER_BOOKED_ID':
      return {
        ...state,
        bookedId: action.payload
      }
    case 'ORDER_BOOKING_INVENTORY':
      return {
        ...state
      }
    case 'ORDER_AUTO_NUMBER':
      return {
        ...state,
        autoNumber: action.payload
      }
    case 'ORDER_SIM_NUMBER':
      return {
        ...state,
        simNumber: action.payload
      }
    case 'ORDER_OTP':
      return {
        ...state,
        otp: action.payload
      }
    case 'ORDER_REFERRAL':
      return {
        ...state,
        referral: action.payload
      }
    case 'ORDER_SUMMARY':
      return {
        ...state,
        summary: action.payload
      }
    case 'ORDER_RESULT':
      return {
        ...state,
        orderResult: action.payload
      }
    case 'ORDER_RESET':
      return {
        ...initState
      }
    case 'ORDER_FINISH':
      return {
        ...initState,
        orderStep: state.orderStep,
        orderResult: state.orderResult
      }
    default: return state
  }
}

export default orderReducer


