import React from "react";
import { Steps } from 'antd';

const { Step } = Steps;

export default function StepIndicator({steps, current}) {

  const items = steps.map((item, key) => ({ key: key, title: item }));
  return (
    <div className="width-max-xxl margin-auto margin-lg-bottom">
      <Steps current={current} items={items} />
    </div>
  );
};