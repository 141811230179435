import api from 'helpers/api';
import genBookingId from 'helpers/genBookingId';
import { stepParams } from 'helpers/paramsOrder';

const orderAction = {
  type: (dispatch, payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: "ORDER_TYPE", payload })
        resolve(true)
      } catch (error) {
        reject(error)
      }
    })
  },
  customerInfo: (dispatch, payload) => {
    dispatch({ type: "ORDER_CUSTOMER_INFO", payload })
  },
  bundleContract: (dispatch, payload) => {
    dispatch({ type: "ORDER_BUNDLE_CONTRACT", payload })
  },
  packagePlan: (dispatch, payload) => {
    dispatch({ type: "ORDER_PACKAGE_PLAN", payload })
  },
  previousProvider: (dispatch, payload) => {
    dispatch({ type: "ORDER_PREVIOUS_PROVIDER", payload })
  },
  autoNumber: (dispatch, payload) => {
    dispatch({ type: "ORDER_AUTO_NUMBER", payload })
  },
  simNumber: (dispatch, payload) => {
    dispatch({ type: "ORDER_SIM_NUMBER", payload })
  },
  summary: (dispatch, payload) => {
    dispatch({ type: "ORDER_SUMMARY", payload })
  },
  otp: (dispatch, payload) => {
    dispatch({ type: "ORDER_OTP", payload })
  },
  reset: (dispatch) => {
    dispatch({ type: "ORDER_RESET" })
  },
  finish: (dispatch) => {
    dispatch({ type: "ORDER_FINISH" })
  },
  referral: (dispatch, payload) => {
    dispatch({ type: "ORDER_REFERRAL", payload })
  },
  result: (dispatch, payload) => {
    dispatch({ type: "ORDER_RESULT", payload })
  },
  getBookedId: (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        let bookedId = genBookingId();
        dispatch({ type: "ORDER_BOOKED_ID", payload: bookedId })
        resolve(bookedId)
      } catch (error) {
        reject(error)
      }
    })
  },
  uploadDocument: (dispatch, file, orderId, subId) => {
    const fileData = {
      NRCI_F: { prefix: 'NRCI_F_', sufix: '', docType: '3' },
      NRCI_B: { prefix: 'NRCI_B_', sufix: '', docType: '3' },
      SF: { prefix: 'SF_', sufix: '', docType: '2' },
      OF_1: { prefix: 'OF_', sufix: '_1', docType: '1' },
      OF_2: { prefix: 'OF_', sufix: '_2', docType: '1' },
      OF_3: { prefix: 'OF_', sufix: '_3', docType: '1' },
    }

    return new Promise((resolve, reject) => {
      try {
        file.forEach(async (item) => {
          dispatch({ type: 'ORDER_UPLOAD_DOCUMENT' })

          await api.post('order_portal/upload_file_identity', { 
            order_id: orderId,
            document: {
              document_type_id: fileData[item.type]['docType'],
              name: fileData[item.type]['prefix'] + subId + fileData[item.type]['sufix'] + item.ext,
              size:  item.size,
              contents: item.src,
            }
          })
        })
        resolve(true)
      } catch (error) {
        reject(false)
      }
    })
  },
  orderStep: (dispatch, orderStep, billingAddress= {}, deliveryAddress = {}) => {
    return new Promise(async (resolve, reject) => {
      try {
        let order = stepParams(orderStep, billingAddress, deliveryAddress);
        
        if(!order.step_id) {
          let res = await api.post('order_portal/create_order_step', { order })
          if(!!res.data) order.step_id = res.data
        } else {
          await api.post('order_portal/update_order_step', { order })
        }

        const payload = {
          ...order.order_step,
          step_id: order.step_id
        }
        dispatch({ type: "ORDER_STEP", payload })
        resolve(true);
      } catch (error) {
        reject(false)
      }
    })
  },
  createOrder: (dispatch, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await api.post('order_portal/create_order_portal', data)  
        dispatch({ type: "ORDER_CREATE" })
        resolve(result)
      } catch (error) {
        reject(error)
      }
    })
  }
};

export default orderAction;