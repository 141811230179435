import React, { useContext } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { Context } from "state/store";

const RouteGuard = ({ component: Component, auth, ...rest }) => {
  const [state] = useContext(Context);
  const {allowPage} = state.systemReducer;
  const {pathname} = useLocation();
  const parentPathname = pathname.split('/')[1];
  
  return (
    <Route
      {...rest}
      render={props => {
        const hasStep = allowPage.find(obj => obj === pathname)
        if(!hasStep) {
          return <Redirect to={`/${parentPathname}/package-plan`} />
        } else {
          return <Component {...props} />
        }
      }}
    />
  )
};

export default RouteGuard;
