import React from "react";
import { useRouteMatch, Route, Switch } from "react-router-dom";
import RouteGuard from "router/routeGuard";
import CustomerInfo from 'pages/easi/customerInfo';
import PackagePlan from 'pages/easi/packagePlan';
import OrderReview from 'pages/easi/orderReview';
import OrderFinish from "pages/easi/orderFinish";
import ChooseNumber from 'pages/chooseNumber';
import NotFound from "pages/notFound";

export default function RouteEasi() {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/package-plan`} component={PackagePlan} />
      <RouteGuard path={`${path}/customer-info`} component={CustomerInfo} />
      <RouteGuard path={`${path}/choose-number`} component={ChooseNumber} />
      <RouteGuard path={`${path}/order-review`} component={OrderReview} />
      <RouteGuard path={`${path}/order-finish`} component={OrderFinish} />
      <Route path="*" component={NotFound}/>
    </Switch>
  );
}
