const initState = {
  bill: null,
  type: null
}

const paymentReducer = (state = initState, action) => {
  switch (action.type) {
    case 'PAYMENT_BILL':
      return {
        ...state,
        bill: action.payload
      }
    case 'PAYMENT_TYPE':
      return {
        ...state,
        type: action.payload
      }
    case 'PAYMENT_RESET':
      return {
        ...initState
      }
    default: return state
  }
}

export default paymentReducer


