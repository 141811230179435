import { env } from "config/const";
import * as _ from 'lodash';

const mainThumbnail = list => {
  let thumb = '';

  list.forEach(item => {
    if(!thumb && !!item.images && item.images.length) {
      thumb = _.startsWith(item.images[0].image_file, 'https://')
        ? item.images[0].image_file
        : env.bucket + item.images[0].image_file;
    }
  });

  return thumb;
}

export default mainThumbnail;