import React from 'react';
import Style from './style.module.scss';

export default function LoadingFull({visible}) {
  if(visible) {
    return (
      <div className="ant-modal-root">
        <div className="ant-modal-mask">
          <img className={Style.imgLoading} src={require('assets/images/loading-dst.gif')} alt="dst loading" />
        </div>
      </div>
    );
  } else {
    return '';
  }
};