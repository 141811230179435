// use for short .env variable
export const env = {
  apiServer: process.env.REACT_APP_API_SERVER,
  appUrl: process.env.REACT_APP_URL,
  appMode: process.env.REACT_APP_MODE,
  bypassOtp: (process.env.REACT_APP_BYPASS_OTP.toLowerCase() === 'true'),
  bypassMsisdn: (process.env.REACT_APP_BYPASS_MSISDN.toLowerCase() === 'true'),
  bucket: process.env.REACT_APP_BUCKET
}

// use for static variable
export const def = {
  sessionExpired: [
    'invalid session id',
    'session expired'
  ],
  orderTypeId: {
    'New Order': 1,
    'Port-IN': 2
  },
  internalPortIn: [3, 4], // public.internet_provider.id
  promoTag: {
    'New Order': 510,
    'Port-IN': 509
  },
  groupTag: {
    'main': 1,
    'internet': 2,
    'voice': [6],
    'device': [120, 108],
    'promo': [110, 105, 509, 510]
  },
  servicePath: [
    'easi',
    'mobi',
    'infinity',
    'tourist'
  ],
  customerTypeUrl: {
    'MOBI-PREPAID': '/easi',
    'MOBI-POSTPAID': '/mobi',
    'FREEDOM': '/freedom',
    'TOURIST': '/tourist',
    'RES': '/infinity'
  },
  byPassStock: [
    'RES',
    'FREEDOM',
    'MOBI-PREPAID'
  ],
  forceWarehouse: [
    'MOBI-POSTPAID'
  ],
  forceWarehouseID: 5,
  billDay: {
    1: 31,
    8: 7,
    16: 15,
    23: 22
  },
  group: {
    'main'  : 1,
    'voice' : 2,
    'delivery fee': 3,
    'device': 4,
    'device deposit': 5,
    'other' : 6,
    'promo' : 7,
    'addon' : 8,
    'simCard' : 9,
  },
  // Old Api MSISDN
  msisdnCategory: [
    'Normal',
    'Golden',
    'Recycled'
  ],
  defaultAddress: {
    district: "1",
    mukim: "1",
    village: "1",
    postal_code: "1",
    street: "PICKUP",
    simpang: "-", 
    block:"1",
    building_name: "PICKUP",
    floor: "1",
    unit: "1",
    home_number: "1"
  }
}