import React, { useEffect, useState, useRef } from "react";
import { message, Button } from "antd";
import { CameraOutlined } from "@ant-design/icons";
import jsQR from 'jsqr'
import Styles from './style.module.scss';

export default function QrCode({
  show,
  setShow,
  setResult
}) {
  const refVideo = useRef(null);
  const [qrcode, setQRcode] = useState(null);
  const [start, setStart] = useState(null);
  const [camera, setCamera] = useState([]);

  let video;
  let canvas;
  let canvasElement;
  const size = {
    width: 341,
    height: 341
  }

  const startCamera = deviceId => {    
    video = document.getElementById('video');
    canvasElement = document.getElementById("canvas");
    canvas = canvasElement.getContext("2d");

    const constraint =  {
      ...size,
      deviceId
    }

    navigator.mediaDevices.getUserMedia({ video: constraint }).then(function(stream) {
      video.srcObject = stream;
      video.play();
      requestAnimationFrame(startQR);
    });
  }

  const stopCamera = () => {
    video = document.getElementById('video');
    const tracks = video.srcObject.getTracks();
    tracks[0].stop();
  }

  const startQR = () => {
    if (video.readyState === video.HAVE_ENOUGH_DATA) {
      canvasElement.height = video.videoHeight;
      canvasElement.width = video.videoWidth;
      canvas.drawImage(video, 0, 0, canvasElement.width, canvasElement.height);
      var imageData = canvas.getImageData(0, 0, canvasElement.width, canvasElement.height);
      var code = jsQR(imageData.data, imageData.width, imageData.height, {
        inversionAttempts: "dontInvert",
      });
      if (code) {
        drawLine(code.location.topLeftCorner, code.location.topRightCorner, "#FF3B58");
        drawLine(code.location.topRightCorner, code.location.bottomRightCorner, "#FF3B58");
        drawLine(code.location.bottomRightCorner, code.location.bottomLeftCorner, "#FF3B58");
        drawLine(code.location.bottomLeftCorner, code.location.topLeftCorner, "#FF3B58");

        setQRcode(code.data);
      }
    }
    requestAnimationFrame(startQR);
  }

  const drawLine = (begin, end, color) => {
    canvas.beginPath();
    canvas.moveTo(begin.x, begin.y);
    canvas.lineTo(end.x, end.y);
    canvas.lineWidth = 2;
    canvas.strokeStyle = color;
    canvas.stroke();
  }

  const onLoad = () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      setShow(false);
      message.info("Can't access camera");
      return;
    }

    navigator.permissions.query({name: 'camera'})
      .then((permissionObj) => {
        if(permissionObj.state === 'denied') {
          message.info('Please allow camera permision!', 3);
        }
      })
      .catch(err => console.log('Got error :', err));

    navigator.mediaDevices.enumerateDevices()
      .then(function (devices) {
        let allCamera = devices.filter(d => d.kind === 'videoinput');
        setCamera(allCamera[allCamera.length - 1].deviceId);
      })
      .catch(err => console.log(err.name + ": " + err.message));
  }

  const onClickStart = () => {
    if(start) {
      if(qrcode) {
        startCamera(camera);
        setQRcode(null);
      } else {
        startCamera(camera);
      }
    } else if(start === false) {
      stopCamera();
    }
  }

  const onGetQrcode = () => {
    if (!!qrcode) {
      stopCamera();
      setStart(null);
    }
  }

  useEffect(onLoad, []);
  useEffect(onClickStart, [start]);
  useEffect(onGetQrcode, [qrcode]);

  return (
    <div className={Styles.scanArea}>
      <p className={Styles.title}>QRCode Scanner</p>

      <div className={!start ? Styles.hidden : ''}>
        <video id="video" ref={refVideo} playsInline={true}></video>
        <canvas id="canvas"></canvas>
      </div>

      {!start && !qrcode &&
        <div className={`${Styles.vCenter} ${Styles.iconBg}`}>
          <CameraOutlined />
        </div>
      }
      
      {qrcode &&
        <div className={`${Styles.vCenter} text-center`}>
          <p className="margin-rm">Code : <b>{qrcode}</b></p>
        </div>
      }

      <div className={Styles.action}>
        <Button
          type="ghost"
          onClick={() => setStart(!start)}
        >
          {start && !qrcode ? 'Stop' : 'Start'}
        </Button>
        {!qrcode &&
        <Button
          type="ghost"
          disabled={start}
          onClick={() => { if (!start) setShow(!show) }}
        >
          Close
        </Button>
        }
        {qrcode &&
        <Button
          type="ghost"
          onClick={() => {
            setResult(qrcode)
            setQRcode(null)
            setShow(!show)
          }}
        >
          Oke
        </Button>
        }
      </div>
    </div>
  );
}
