const icEffect = {
  nationality: (icNumber) => {
    switch (!!icNumber && icNumber.substr(0,2)) {
      case '00': case '01':
        return 'Brunei Darussalam';
      case '30': case '31':
        return 'Permanent Resident';
      case '50': case '51':
        return 'Foreigner';
      default:
        return null;
    }
  },
  originCountry: (icNumber) => {
    switch (!!icNumber && icNumber.substr(0,2)) {
      case '00': case '01': case '30': case '31':
        return false;
      case '50': case '51':
        return true;
      default:
        return false;
    }
  },
  yellowRedCard: (icNumber) => {
    switch (!!icNumber && icNumber.substr(0,2)) {
      case '00': case '01': case '30': case '31':
        // yellow or red card
        return true;
      default:
        return false;
    }
  },
}

export default icEffect;