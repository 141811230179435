/**
 * Payment Action
 *
 * Populate payment and send related payment
 */

import api from 'helpers/api';

const paymentAction = {
  reset: (dispatch) => {
    dispatch({ type: "PAYMENT_RESET" })
  },
  type: (dispatch, payload) => {
    dispatch({ type: "PAYMENT_TYPE", payload })
  },
  getBill: (dispatch, params) => {
    return new Promise(async (resolve, reject) => {
      try {
        const {data} = await api.post('order_portal/checkout_session_subscription', params);

        if(!!data) {
          dispatch({ type: 'PAYMENT_BILL', payload: data });
          resolve(data);
        } else {
          reject(false);
        }
      } catch (error) {
        console.log(error.response)
        reject(error.response)
      }
    })
  }
};

export default paymentAction;