import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RightOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Form, Button, Row, Col, Input, Tooltip, message} from "antd";

import { Context } from "state/store";
import api from "helpers/api";
import systemAction from "state/actions/systemAction";
import orderAction from "state/actions/orderAction";
import PanelTimeline from "components/panelTimeline";
import ChoosePackage from "./components/choosePackage";
import { easiRechargeSummary } from "helpers/buildSummary";

const customerType = 'MOBI-PREPAID';

export default function CustomerInfo() {
  const { t } = useTranslation();
  const history = useHistory();
  const [state, dispatch] = useContext(Context);
  const {packagePlan} = state.orderReducer;
  const {user} = state.userReducer;
  const [formChoosePackage] = Form.useForm();
  const [formMsisdn] = Form.useForm();
  const [subscriptionId, setSubscriptionId] = useState([]);
  const [validMsisdn, setValidMsisdn] = useState(false);

  const propsChoosePackage = {
    data: !!packagePlan ? packagePlan : null,
    form: formChoosePackage,
    repId: user.rep_id,
    customerType
  }

  const handleMsisdnOnBlur = val => { 
    systemAction.startLoading(dispatch);

    let params = {
      msisdn: val
    }

    if (val.length < 7) {
      setValidMsisdn(false)
      systemAction.stopLoading(dispatch)
      message.error('Please insert a valid mobile number');
      return false;
    }

    api.post('order_portal/isMobiPrepaid', params)
      .then(({data}) => {
        systemAction.stopLoading(dispatch)
        message.success('OK, please choose your package');
        setSubscriptionId(data);
        setValidMsisdn(true)
      })
      .catch(error => {
        console.log(error);
        systemAction.stopLoading(dispatch)
        message.error(t('msgSystemTrouble'))
      })
  }

  // get error or data by form
  const submitForm = (form) => {
    return form.validateFields()
      .then(res => res)
      .catch(err => err)
  }

  const submit = async() => {
    let error = false;
    let msisdn = await submitForm(formMsisdn);
    let choosePackage = validMsisdn ? await submitForm(formChoosePackage) : {};
    let data = {
      customerType,
      msisdn: subscriptionId.msisdn_no,
      subscriptionId: subscriptionId.subscription_id
    }
    
    // check errorFields
    if(!!msisdn.errorFields) error = true
    if(!!choosePackage.errorFields) error = true
    Object.keys(data).map(obj => 
      error = error ? error : !!data[obj].errorFields
    );

    if(!error) {
      let page = "/easi-recharge/order-review"
      systemAction.startLoading(dispatch);
      systemAction.allowPage(dispatch, [page]);
      orderAction.customerInfo(dispatch, data);
      orderAction.packagePlan(dispatch, choosePackage);
      orderAction.summary(dispatch, easiRechargeSummary(choosePackage));
      history.push(page)
    }
  }

  return (
    <div className="container">
      <div className="margin-md-top margin-bottom">
        <PanelTimeline
          title="Mobile Number"
          noLine={!validMsisdn}
        >
          <Row gutter={16}>
            <Col xs={24} md={8}>                
              <Form form={formMsisdn} initialValues={{store_id: ""}}>
                <Form.Item
                  name="mobile-number"
                  rules={[
                    { 
                      required: true,
                      message: 'Mobile Number is required'
                    },
                    {
                      pattern: new RegExp(/^(6737\d{6}|6738\d{6}|673228\d{4}|673229\d{4}|7\d{6}|8\d{6}|228\d{4}|229\d{4})$/g),
                      message: "Invalid format number"
                    }
                  ]}
                >
                  <Input
                    size="large"
                    placeholder="Mobile Number*"
                    suffix={
                      <Tooltip title="Mobile number must be numeric and at least 7 characters">
                        <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                      </Tooltip>
                    }
                    onBlur={e => handleMsisdnOnBlur(e.target.value)}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </PanelTimeline>

        {!!validMsisdn &&
        <PanelTimeline title="Choose Package" noLine> 
          <ChoosePackage {...propsChoosePackage} />
        </PanelTimeline>
        }

        <div className="flex flex-right margin-top">
          <Button type="primary" htmlType="submit" onClick={() => submit()}>
            {t('btnNext')}
            <RightOutlined />
          </Button>
        </div>
      </div>
    </div>
  );
}
