/**
 * System Action
 *
 * Action for manipulate system
 * ex: global loading, routing guard
 */

import api from 'helpers/api';

const systemAction = {
  startLoading: (dispatch) => {
    dispatch({ type: 'SYS_START_LOADING', payload: true });
  },
  stopLoading: (dispatch) => {
    dispatch({ type: 'SYS_STOP_LOADING', payload: false });
  },
  allowPage: (dispatch, payload) => {
    dispatch({ type: 'SYS_ALLOW_PAGE', payload: payload });
  },
  errorLog: (dispatch, message = '', method = '') => {
    return new Promise(async (resolve, reject) => {
      try {
        let params = { method, message };
        await api.post('order_portal/error_log', params);
        dispatch({ type: 'SYS_ERROR' });
        resolve(true);
      } catch (error) {
        console.log(error);
        reject(false)
      }
    })
  },
  resetPage: (dispatch) => {
    dispatch({ type: 'SYS_RESET' });
  }
};

export default systemAction;