import React from 'react';
import Router from 'router/route';
import Store from 'state/store';
import { message } from 'antd';

window.alert = (res) => {
  message.error(res, 5);
}

export default function App() {
  return (
    <Store>
      <Router />
    </Store>
  );
}