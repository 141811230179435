import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Row, Col, Form, Input, Select, Spin, message } from 'antd';
import api from 'helpers/api';
import useDebounce from 'hook/useDebounce';

const { Option } = Select;

export default function AddressForm({data, form}) {
  const { t } = useTranslation();
  const [postal, setPostal] = useState([]);
  const [address, setAddress] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [isOtherPostal, setIsOtherPostal] = useState(false);
  const [searchAddress, setSearchAddress] = useState(null);
  const debounceAddress = useDebounce(searchAddress, 500);

  const setItemAddress = (village_id) => {
    let selectedAddress = address.find((obj) => obj.village === Number(village_id));
    form.setFieldsValue({
      address: selectedAddress.address,
      district: selectedAddress.district,
      mukim: selectedAddress.mukim,
    });
  }

  const searchPostalCode = (village_id) => {
    api.post('order_portal/get_postal_code', {village_id})
    .then(result => {
      setPostal([...result.data, {id: 0, pos_code: 'Other'}]);
    })
    .catch(error => {
      console.log(error);
    })
  }

  const onLoad = () => {
    if(!!data) {
      setSearchAddress(data.search_address);
      searchPostalCode(data.village);
    }
  }

  const getAddress = () => {
    let from = form.getFieldsValue();

    let params = {
      address: searchAddress,
      category: from.filter,
      page: 1
    }

    if(searchAddress != null) {
      setFetching(true);
      form.setFieldsValue({search_address: searchAddress});

      api.post('order_portal/get_address_list', params)
        .then(result => {
          setAddress(result.data);
          setFetching(false);
        })
        .catch(error => {
          console.log(error);
          setFetching(false);
          message.error(t('msgSystemTrouble'), 3)
        })
    }
  }

  useEffect(onLoad, []);
  useEffect(getAddress, [debounceAddress]);

  return (
    <Form form={form} initialValues={!!data ? data : {filter: "All"}}>
      <Row gutter={16}>
        <Col xs={24} md={6}>
          <Form.Item name="filter">
            <Select
              size="large"
              placeholder="Address Filter"
            >
              <Option value="All">All</Option>
              <Option value="district">District</Option>
              <Option value="mukim">Mukim</Option>
              <Option value="village">Village</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={isOtherPostal ? 10 : 13}>
          <Form.Item
            name="village"
            rules={[
              { required: true, message: 'This is required' }
            ]}
          >
            <Select
              showSearch
              size="large"
              placeholder={t('inputSelect') +' '+ t('inputDistrict') +' / '+ t('inputMukim') +' / '+ t('inputVillage') + '*'}
              defaultActiveFirstOption={false}
              showArrow={true }
              filterOption={false}
              onFocus={() => setSearchAddress('')}
              onSearch={(value) => setSearchAddress(value)}
              onSelect={(value) => { setItemAddress(value); searchPostalCode(value) }}
              notFoundContent={fetching ? <Spin size="small" /> : "Please enter characters"}
            >
              {address.map(d => <Option key={d.village}>{d.address}</Option>)}
            </Select>
          </Form.Item>
          
          <Form.Item hidden name="search_address"><Input /></Form.Item>
          <Form.Item hidden name="address"><Input /></Form.Item>
          <Form.Item hidden name="district"><Input /></Form.Item>
          <Form.Item hidden name="mukim"><Input /></Form.Item>
        </Col>
        <Col xs={24} md={isOtherPostal ? 4 : 5}>
          <Form.Item
            name="postal_code"
            rules={[
              { required: true, message: 'Postal Code is required' }
            ]}
          >
            <Select
              size="large"
              placeholder={t('inputPostalCode') + '*'}
              notFoundContent="No data"
              onSelect={(value) => setIsOtherPostal(value === 'Other')}
            >
              {postal.map((d, k) => 
                <Option value={d.pos_code} key={k}>{d.pos_code}</Option>
              )}
            </Select>
          </Form.Item>
        </Col>
        {isOtherPostal &&
          <Col xs={24} md={4}>
            <Form.Item
              name="postal_code_other"
              rules={[
                { required: true, message: 'Postal Code Other is required'},
                { len: 6, message: 'Invalid Postal Code Other' }
              ]}
            >
              <Input size="large" placeholder={t('inputPostalCodeOther') + '*'} />
            </Form.Item>
          </Col>
        }
      </Row>
        
      <Row gutter={16}>
        <Col xs={24}>
          <Form.Item
            name="street"
            rules={[
              { required: true, message: 'Street is required' }
            ]}
          >
            <Input size="large" placeholder={t('inputStreet') + '*'} />
          </Form.Item>
        </Col>
      </Row>
        
      <Row gutter={16}>
        <Col xs={24} md={8}>
          <Form.Item
            name="simpang"
            rules={[
              { required: true, message: 'Simpang is required' }
            ]}
          >
            <Input size="large" placeholder={t('inputSimpang') + '*'} />
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item
            name="block"
            rules={[
              { max: 10, message: 'Block is too long' }
            ]}
          >
            <Input size="large" placeholder={t('inputBlock')} />
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item
            name="home_number"
            rules={[
              { required: true, message: 'Home Number is required' },
              { max: 10, message: 'Home Number is too long' }
            ]}
          >
            <Input size="large" placeholder={t('inputHomeNumber') + '*'} />
          </Form.Item>
        </Col>
      </Row>
        
      <Row gutter={16}>
        <Col xs={24} md={8}>
          <Form.Item
            name="building_name"
            rules={[
              { max: 100, message: 'Building Name is too long' }
            ]}
          >
            <Input size="large" placeholder={t('inputBuildingName')}/>
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item
            name="floor"
            rules={[
              { max: 10, message: 'Floor is too long' }
            ]}
          >
            <Input size="large" placeholder={t('inputFloor')} />
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item
            name="unit"
            rules={[
              { max: 10, message: 'Unit is too long' }
            ]}
          >
            <Input size="large" placeholder={t('inputUnit')} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};