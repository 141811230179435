const initState = {
  loading: false,
  allowPage: []
}

const systemReducer = (state = initState, action) => {
  switch (action.type) {
    case 'SYS_START_LOADING':
      return {
        ...state,
        loading: true
      }
    case 'SYS_STOP_LOADING':
      return {
        ...state,
        loading: false
      }
    case 'SYS_ALLOW_PAGE':
      return {
        ...state,
        allowPage: action.payload
      }
    case 'SYS_ERROR':
      return {
        ...state
      }
    case 'SYS_RESET':
      return {
        ...initState
      }
    default: return state
  }
}

export default systemReducer


