import React, { useState, useContext, useEffect } from "react";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Layout, Menu, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { Context } from 'state/store';
import userAction from "state/actions/userAction";
import orderAction from "state/actions/orderAction";
import LoginForm from 'components/loginForm';
import Style from './style.module.scss';

const { confirm } = Modal;

const Header = () => {
  const { i18n } = useTranslation();
  let lastLang = i18n.language;

  const history = useHistory();
  const [state, dispatch] = useContext(Context);
  const {isLogined, user} = state.userReducer;
  const [modalLogin, setModalLogin] = useState(false);
  const [logined, setLogined] = useState(false);
  const location = useLocation();

  const propsLoginForm = {
    show: modalLogin, 
    setShow: setModalLogin
  }

  const changeLanguage = () => {
    if (lastLang === "en") lastLang = "bn";
    else lastLang = "en";
    i18n.changeLanguage(lastLang);
  };

  const isHome = () => {
    return location.pathname === '/';
  }

  const logout = () => {
    confirm({
      title: 'Are you sure to logout?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        userAction.logout(dispatch).finally(() => {
          orderAction.reset(dispatch);
          history.push('/')
        })
      }
    });
  }

  useEffect(() => {
    setLogined(isLogined);
  }, [isLogined]);

  return (
    <>
      <Layout.Header className="fixed shadow">
        <div className="container padding-rm flex flex-between">
          <a href="https://dst.com.bn/"><img src={require("assets/images/logo_white.png")} alt="dst-Logo" width="120" /></a>
          <Menu selectable={false} mode="horizontal" className={Style.menu}>
            {logined && isHome() &&
              <Menu.Item className={Style.menuItem} key="2">Welcome, {user.full_name}</Menu.Item>
            }
            {logined && isHome() && 
              <Menu.Item className={Style.menuItem} key="3" onClick={logout} >Logout</Menu.Item>
            }
            {!logined && isHome() &&
              <Menu.Item className={Style.menuItem} key="4" onClick={() => setModalLogin(true)}>Login</Menu.Item>
            }
            <Menu.Item className={Style.menuItem} key="5" onClick={() => changeLanguage()}>
              {lastLang === 'bn'
                ? <><b>BM</b> | EN</>
                : <>BM | <b>EN</b></>
              }              
            </Menu.Item>
          </Menu>
        </div>
      </Layout.Header>

      <LoginForm {...propsLoginForm} />
    </>
  );
};

export default Header;
