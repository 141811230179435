import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Form, Input, Button, Alert, Typography, Modal } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import { Context } from 'state/store';
import { def } from "config/const";
import { useHistory, useLocation } from "react-router-dom";
import userAction from "state/actions/userAction";

const { Text } = Typography;

export default function LoginForm({show, setShow}) {
  const { t } = useTranslation();
  const history = useHistory();
  const {pathname} = useLocation();
  const [, dispatch] = useContext(Context);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const parentPathname = pathname.split('/')[1];

  const loginSubmit = value => {
    setIsLoading(true)
    userAction.login(dispatch, value)
      .then(() => {
        setIsLoading(false)
        setShow(false)

        let onService = def.servicePath.find(item => item === parentPathname)
        if(!!onService) {
          history.push(`/${parentPathname}/customer-info`)
        } else {
          history.push('/')
        }
      })
      .catch(err =>{
        setIsLoading(false)
        setErrorMessage(err)
      })
  }

  return (
    <Modal
      width={320}
      footer={null}
      closable={false}
      maskClosable={true}
      onCancel={() => setShow(false)}
      open={show}
      centered
    >
      <Form
        name="login"
        initialValues={{ remember: true }}
        onFinish={(value) => loginSubmit(value)}
      >
        <h4 className="margin-bottom">Login</h4>
        
        {!!errorMessage &&
          <Alert
            type="error"
            className="text-small margin-bottom"
            message={<Text type="danger">{errorMessage}</Text>}
          />
        }

        <Form.Item
          name="user_name"
          rules={[
            { required: true, message: 'Please input your Username!'
          }]}
        >
          <Input
            placeholder={t('inputUsername')}
            prefix={<UserOutlined className="site-form-item-icon" />}      
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            { required: true, message: 'Please input your Password!' }
          ]}
        >
          <Input.Password
            type="password"
            placeholder={t('inputPassword')}
            prefix={<LockOutlined className="site-form-item-icon" />}
          />
        </Form.Item>

        <Button type="primary" htmlType="submit" className="login-form-button" loading={isLoading}>
          Log in
        </Button>
      </Form>
    </Modal>
  );
}
