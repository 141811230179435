import React from "react";
import { notification, message } from "antd";
import { WarningOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import { def, env } from "config/const";
import api from "helpers/api";
import * as _ from "lodash";

const useCheckMark = () => {
  const { t } = useTranslation();

  const blackList = async (id_number) => {  
    const params = { 
      id_number
    }
    const {data} =  await api.post('order_portal/get_black_list', params);

    // if(data) {
    //   notification.open({
    //     message: 'Oops Sorry...',
    //     description: 'Can\'t create order, call customer service for details.',
    //     icon: <WarningOutlined style={{ color: 'red' }} />,
    //   });
    // }
    
    return data;
  }

  const bookingInventory = async (device, bookedId, customerInfo) => {
    try {
      if(_.isEmpty(device)) return true;
      const isForceWarehouse = def.forceWarehouse.includes(customerInfo.customerType)

      let delivery_type = !!customerInfo.deliveryPickup
        ? parseInt(customerInfo.deliveryPickup.delivery_type)
        : 2

      let store_id = !!customerInfo.deliveryPickup && !!customerInfo.deliveryPickup.store_id
        ? { store_id: customerInfo.deliveryPickup.store_id }
        : { store_id: 10 }

      let params = {
        booked_id: bookedId,
        delivery_type,
        ...store_id,
        warehouse_id: (isForceWarehouse || delivery_type !== 1 ) ? def.forceWarehouseID : null,
        check_stock: !def.byPassStock.includes(customerInfo.customerType),
        order_details: []
      }
      device.sku_code.map(obj => params.order_details.push({
        name: device.bundle_name,
        sku_code: obj
      }));
      
      await api.post('order_portal/booking_inventory_items', params);
      return true;

    } catch (error) {

      if(!!error.response) {
        notification.open({
          message: 'Oops Sorry...',
          description: error.response.data,
          icon: <WarningOutlined style={{ color: 'red' }} />,
        });
      } else {        
        message.error(t('msgSystemTrouble'), 3)
      }
      return false
    }
  }

  const bookingNumber = async (msisdn, bookedNumber = null) => {
    if(env.bypassMsisdn) return true;

    let result = false;
    await Promise.all(
      Object.keys(msisdn).map(async key => {
        await Promise.all(
          msisdn[key].map(async (item, i) => {
            if(!bookedNumber || bookedNumber[key][i]['number'] !== item.number) {
              try {
                let params = {
                  // newOP: true,
                  msisdnID: item.id,
                  msisdnNo: item.number
                }
                const response = await api.post('order_portal/book_number', params);
                result = response.status === 200;
              } catch (error) {
                message.error(error.response.data);
              }
            } else {
              // number booked
              result = true;
            }
          })
        )
      })
    )

    return result;
  }

  const imsiNumber = async (simNumber, errorMessage) => {  
    let result = true
    let error = {...errorMessage}

    await Promise.all(
      Object.keys(simNumber).map(async key => {
        await Promise.all(
          simNumber[key].map(async (item, index) => {
            let params = {
              imsi: item.imsi,
              take: 1
            }
    
            const {data} = await api.post('order_portal/get_imsi', params)
            
            if(result) result = !!data.length
            if(!data.length) error[key][index].imsi = 'IMSI number Not Exist!'
          })
        )
      })
    )

    // if(!result) {      
    //   notification.open({
    //     message: 'Oops Sory...',
    //     description: 'Your IMSI has problem!',
    //     icon: <WarningOutlined style={{ color: 'red' }} />,
    //   });
    // }

    return {
      result,
      error
    };
  }

  return {
    blackList,
    bookingInventory,
    bookingNumber,
    imsiNumber
  }
};

export default useCheckMark;