import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Row, Col, Descriptions, message } from "antd";

import { Context } from "state/store";
import { touristParams } from "helpers/paramsOrder";
import systemAction from "state/actions/systemAction";
import orderAction from "state/actions/orderAction";
import ObjectValidator from "helpers/objectValidator";
import api from "helpers/api";
import Summary from "components/summary";
import ReviewCustomerInfo from "components/reviewCustomerInfo";
import ReviewConsent from "components/reviewConsent";

export default function OrderReview() {
  const { t } = useTranslation();
  const history = useHistory();
  const [state, dispatch] = useContext(Context);
  const {allowPage} = state.systemReducer;
  const {user} = state.userReducer;
  const order = state.orderReducer;
  const { customerInfo } = state.orderReducer;
  const [errorMessage, setErrorMessage] = useState({});
  const [tnc, setTnc] = useState(false);

  const propsReviewConsent = {
    tnc,
    setTnc,
    submit:() => submit(),
    errorMessage
  }

  const validate = () => {
    const schema = {
      tnc: { require: () => !!tnc ? '' :'Please confirm Terms & Conditions' }
    }
    const error = ObjectValidator(schema)
    setErrorMessage(error)
    return error
  }

  const sendOrderStep = (order_id) => {
    // populate data for order step
    let data = {
      ...order.orderStep,
      success_flag: 1,
      order_id
    }

    // send to api order step
    orderAction.orderStep(dispatch, data)
  }

  const submit = async () => {
    if(!!validate()) return;

    systemAction.startLoading(dispatch);
    let page = "/tourist/order-finish"
    let newAllowPage = [...allowPage, page]
    let dataObject = touristParams({...order, ...user})

    orderAction.createOrder(dispatch, dataObject)
      .then(async ({data}) => {
        if (data.success) {
          orderAction.result(dispatch, data.response)
          systemAction.allowPage(dispatch, newAllowPage)
          sendOrderStep(data.response.order_id)
          history.push(page)
        } else {
          systemAction.errorLog(dispatch, data, 'create_order_portal')
          systemAction.stopLoading(dispatch)
          message.error(t('msgSystemTrouble'), 3)
        }
      }).catch(error => {
        console.log(error.response);
        systemAction.stopLoading(dispatch)
        message.error(t('msgSystemTrouble'), 3)
      })
  }
  
  const onLoad = () => {
    systemAction.stopLoading(dispatch);
    console.log(order)
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }
  useEffect(onLoad, []);

  return (
    <div className="container">
      <Row gutter={24} className="margin-bottom review">
        <Col xs={24} lg={16} className="info">
          <ReviewCustomerInfo {...order} />

          {customerInfo.delivery_form === true &&
          <Descriptions title={t('lblDataPickupAddress')} size="small" className="margin-md-top header-margin-sm">
            <Descriptions.Item>Brunei International airport</Descriptions.Item>
          </Descriptions>
          }
        </Col>
        <Col xs={24} lg={8} className="summary">
          <Summary
            data={order.summary}
            mainLabel={t('lblSummaryMainInternet')}
            showQty={true}
            muted
          />
        </Col>
        <Col xs={24} lg={24} className="text-center">          
          <ReviewConsent {...propsReviewConsent} />
        </Col>
      </Row>
    </div>
  );
}
