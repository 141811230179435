import axios from "axios";
import storage from 'helpers/storage';
import { env, def } from "config/const";

const api = axios.create({
  baseURL: env.apiServer,
  responseType: "json",
});

api.interceptors.request.use((request) => {
  const user = storage.get('user', null);
  const sessionId = !!user ? {session_id: user.session_id} : {}

  return {
    ...request,
    data: {
      ...request.data,
      ...sessionId
    }
  }
})

api.interceptors.response.use((response) => response, (error) => {
  if(
    !!error.response &&
    def.sessionExpired.includes(error.response.data.toLowerCase())
  ) {
    alert(error.response.data)
    storage.remove('user')
    setTimeout(() => {
      window.location.reload()
    }, 3000);
  } else if(error.message === 'Network Error') {
    alert(error.message)
  } else {
    let params = {
      method: error.response.config.url.replace('order_portal/', ''),
      message: JSON.stringify({
        message: error.response.data,
        body: JSON.parse(error.response.config.data)
      })
    };
    api.post('order_portal/error_log', params)
  } 
  throw error;
});

export default api;
