const initState = {
  offerings: [],
  countryList: [],
  counterList: [],
  // customerClassType: [],
  publicHoliday: [],
  provider: [],
  billingCycle: []
}

const sourceReducer = (state = initState, action) => {
  switch (action.type) {
    case 'GET_OFFERINGS':
      return {
        ...state,
        offerings: action.payload
      }
    case 'RESET_OFFERINGS':
      return {
        ...state,
        offerings: []
      }
    case 'GET_COUNTRY':
      return {
        ...state,
        countryList: action.payload
      }
    case 'GET_COUNTER_LIST':
      return {
        ...state,
        counterList: action.payload
      }
    // case 'GET_CUSTOMER_CLASS_TYPE':
    //   return {
    //     ...state,
    //     customerClassType: action.payload
    //   }
    case 'GET_PUBLIC_HOLIDAY':
      return {
        ...state,
        publicHoliday: action.payload
      }
    case 'GET_PROVIDER':
      return {
        ...state,
        provider: action.payload
      }
    case 'GET_BILLING_CYCLE':
      return {
        ...state,
        billingCycle: action.payload
      }
    case 'GET_BILLING_CYCLE':
      return {
        ...state,
        billingCycle: action.payload
      }
    default: return state
  }
}

export default sourceReducer


