import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Row, Col, Descriptions, message } from "antd";

import { Context } from "state/store";
import { touristParams } from "helpers/paramsOrder";
import systemAction from "state/actions/systemAction";
import orderAction from "state/actions/orderAction";
import ObjectValidator from "helpers/objectValidator";
import api from "helpers/api";
import Summary from "components/summary";
import ReviewCustomerInfo from "components/reviewCustomerInfo";
import ReviewConsent from "components/reviewConsent";

export default function OrderReview() {
  const { t } = useTranslation();
  const history = useHistory();
  const [state, dispatch] = useContext(Context);
  const {allowPage} = state.systemReducer;
  const {user} = state.userReducer;
  const order = state.orderReducer;
  const [errorMessage, setErrorMessage] = useState({});
  const [tnc, setTnc] = useState(false);

  const propsReviewConsent = {
    tnc,
    setTnc,
    submit:() => submit(),
    errorMessage
  }

  const validate = () => {
    const schema = {
      tnc: { require: () => !!tnc ? '' :'Please confirm Terms & Conditions' }
    }
    const error = ObjectValidator(schema)
    setErrorMessage(error)
    return error
  }

  const sendOrderStep = (orderId) => {
    let orderStep = order.orderStep
        orderStep.order_step['success_flag'] = '1'
        orderStep.order_step['order_id'] = orderId
    
    api.post('order_portal/update_order_step', { order: orderStep })
    dispatch({ type: "ORDER_STEP", payload: orderStep })

  }

  const submit = () => {
    if(!!validate()) return;

    systemAction.startLoading(dispatch);
    let page = "/tourist/order-finish"
    let newAllowPage = [...allowPage, page]
    let dataObject = touristParams({...order, ...user})

    api.post('order_portal/create_order_portal', dataObject)
      .then(async function (result) {
        await sendOrderStep(result.data.response.order_id)
        orderAction.result(dispatch, result.data.response)
        systemAction.allowPage(dispatch, newAllowPage)
        history.push(page)
      })
      .catch(error => {
        console.log(error);
        systemAction.stopLoading(dispatch)
        message.error(t('msgSystemTrouble'), 3)
      })
  }
  
  const onLoad = () => {
    systemAction.stopLoading(dispatch);
  }
  useEffect(onLoad, []);

  return (
    <div className="container">
      <Row gutter={24} className="margin-bottom review">
        <Col xs={24} lg={16} className="info">
          <ReviewCustomerInfo {...order} />

          <Descriptions title={t('lblDataPickupAddress')} size="small" className="margin-md-top header-margin-sm">
            <Descriptions.Item>Brunei International airport</Descriptions.Item>
          </Descriptions>
        </Col>
        <Col xs={24} lg={8} className="summary">
          <Summary
            data={order.summary}
            mainLabel={t('lblSummaryMainInternet')}
            showQty={true}
            muted
          />
        </Col>
        <Col xs={24} lg={24} className="text-center">          
          <ReviewConsent {...propsReviewConsent} />
        </Col>
      </Row>
    </div>
  );
}
