import React from 'react';
import { Card } from 'antd';
import Style from './style.module.scss';

export default function CardPackage({children, active, onClick}) {
  return (
    <Card
      hoverable
      bodyStyle={{paddingBottom:'10px'}}
      className={`${Style.radioCard} ${active ? Style.active : ''}`}
      bordered={false}
      onClick={onClick}
    >
      {children}
    </Card>
  );
};