import { def } from "config/const"

const user = (data) => {
  return {
    contact_type_id: 1,
    contact_type: 'Primary Billing',
    password: null,
    nric: data.identity_number,
    email: data.email,
    salutation: data.salutation,
    first_name: data.full_name,
    last_name: null,
    gender: data.gender ? data.gender : null,
    fin_expiry_date: null,
    date_of_birth: data.birth_date ? data.birth_date : null,
    date_of_arrival: data.date_of_arrival ? data.date_of_arrival : null,
    flight_number: data.flight_number ? data.flight_number : null,
    nationality: data.nationality,
    home_phone: data.phone ? data.phone : null,
    mobile: data.mobile_phone ? data.mobile_phone : null,
    work_phone: data.work_phone ? data.work_phone : null,
    fax: null,
  }
}

const order = (data) => {
  console.log(data);
  return {
    type: data.orderType,
    subscription_type: 'New',
    source_type_id: '2',
    promo_code: null,
    sales_type: 'Retail',
    app_ref_number: null,
    event_rep_id: null,
    details: JSON.parse(data.order_detail),
    channel_id: '33',
    contract_id: null
  }
}  

const address = (data) => {
  return {
    district: data.district,
    mukim: data.mukim,
    village: data.village,
    postal_code: data.postal_code ? data.postal_code : data.postal_code_other,
    street: data.street,
    simpang: data.simpang,
    block: data.block ? data.block : '',
    building_name: data.building_name ? data.building_name : '',
    floor: data.floor ? data.floor : '',
    unit: data.unit ? data.unit : '',
    home_number: data.home_number
  }
}

export const stepParams = (data, billing, service) => {
  const deliveryAddress = {
    // for service
    district: service.district ? service.district : null,
    mukim: service.mukim ? service.mukim : null,
    village: service.village ? service.village : null,
    postal_code: service.postal_code ? service.postal_code : null,
    street: service.street ? service.street : null,
    simpang: service.simpang ? service.simpang : null,
    block: service.block ? service.block : null,
    home_number: service.home_number ? service.home_number : null,
    building_name: service.building_name ? service.building_name : null,
    floor: service.floor ? service.floor : null,
    unit: service.unit ? service.unit : null,
    // for delivery
    district_delivery: service.district ? service.district : null,
    mukim_delivery: service.mukim ? service.mukim : null,
    village_delivery: service.village ? service.village : null,
    postal_code_delivery: service.postal_code ? service.postal_code : null,
    street_delivery: service.street ? service.street : null,
    simpang_delivery: service.simpang ? service.simpang : null,
    block_delivery: service.block ? service.block : null,
    home_number_delivery: service.home_number ? service.home_number : null,
    building_name_delivery: service.building_name ? service.building_name : null,
    floor_delivery: service.floor ? service.floor : null,
    unit_delivery: service.unit ? service.unit : null
  }

  const billingAddress = {
    district_billing: billing.district ? billing.district : null,
    mukim_billing: billing.mukim ? billing.mukim : null,
    village_billing: billing.village ? billing.village : null,
    postal_code_billing: billing.postal_code ? billing.postal_code : null,
    street_billing: billing.street ? billing.street : null,
    simpang_billing: billing.simpang ? billing.simpang : null,
    block_billing: billing.block ? billing.block : null,
    home_number_billing: billing.home_number ? billing.home_number : null,
    building_name_billing: billing.building_name ? billing.building_name : null,
    floor_billing: billing.floor ? billing.floor : null,
    unit_billing: billing.unit ? billing.unit : null,
  }

  return {
    step_id: data.step_id ? data.step_id : null,
    order_step: {
      success_flag: data.success_flag ? data.success_flag : 0,
      order_type: data.order_type ? data.order_type : null,
      order_id: data.order_id ? data.order_id : null,
      customer_type: data.customer_type ? data.customer_type : null,
      salutation: data.salutation ? data.salutation : null,
      full_name: data.full_name ? data.full_name : null,
      identity_number: data.identity_number ? data.identity_number: null,
      email: data.email ? data.email : null,
      mobile_phone: data.mobile_phone ? data.mobile_phone : null,
      phone: data.phone ? data.phone : null,
      work_phone: data.work_phone ? data.work_phone : null,
      birth_date: data.birth_date ? data.birth_date : null,
      nationality: data.nationality ? data.nationality : null,
      gender: data.gender ? data.gender : null,
      flight_number: data.flight_number ? data.flight_number : null,
      date_of_arrival: data.date_of_arrival ? data.date_of_arrival : null,
      order_detail: data.order_detail ? data.order_detail : null,
      book_number: data.book_number ? data.book_number : null,
      previous_provider_id: data.previous_provider_id ? data.previous_provider_id : null,
      store_id: data.store_id ? data.store_id : null,
      delivery_type: data.delivery_type ? data.delivery_type : null,
      delivery_date: data.delivery_date ? data.delivery_date : null,
      ...deliveryAddress,
      ...billingAddress,
    }
  }
}

export const easiParams = ({
  previousProvider,
  bundleContract,
  bookedId,
  orderType,
  orderStep,
  customerInfo,
  simNumber,
  referral,
  rep_id
}) => {
  const isForceWarehouse = def.forceWarehouse.includes(customerInfo.customerType)
  const delivery_type = parseInt(customerInfo.deliveryPickup.delivery_type)
  let delivery;
  if(customerInfo.delivery_form === true){
    delivery = {
      type: delivery_type,
      date: typeof customerInfo.deliveryPickup.delivery_date !== 'string'
        ? customerInfo.deliveryPickup.delivery_date.format('YYYY-MM-DD')
        : customerInfo.deliveryPickup.delivery_date,
      store_id: delivery_type === 1
        ? customerInfo.deliveryPickup.store_id
        : null,
      msisdn: simNumber,
    }
  }else{
    delivery = {
      msisdn: simNumber,
      esim_flag: true,
    };
  }

  
  return {
    booked_id: bookedId,
    warehouse_id: (isForceWarehouse || delivery_type !== 1 ) ? def.forceWarehouseID : null,
    referral: referral,
    rep_id: rep_id,
    customer: {
      type: customerInfo.customerType,
      referrer_customer_id: null,
      id: null,
      class_type: 'Regular',
      class_type_id: 1,
      users: [{
        ...user(orderStep),
        fin_expiry_date: typeof customerInfo.customerDetail.fin_expiry_date !== 'string'
          ? customerInfo.customerDetail.fin_expiry_date.format('YYYY-MM-DD')
          : customerInfo.customerDetail.fin_expiry_date,
        origin_country: customerInfo.customerDetail.origin_country
          ? customerInfo.customerDetail.origin_country
          : null
      }]
    },
    order: {
      ...order({ ...orderStep, orderType }),
      previous_provider_id: !!previousProvider ? previousProvider : null,
      contract_id: bundleContract
    },
    subscription: {
      service_type: customerInfo.customerType,
      tp_status: 30,
      net_co: 'ON',
      addresses: [
        {
          type: 'Service',
          ...address(customerInfo.serviceAddress)
        },
        {
          type: 'Billing',
          ...address(customerInfo.billingAddress)
        }
      ]
    },
    delivery
  }
}

export const mobiParams = ({
  previousProvider,
  bundleContract,
  bookedId,
  orderType,
  orderStep,
  customerInfo,
  simNumber,
  referral,
  rep_id
}) => {
  const isForceWarehouse = def.forceWarehouse.includes(customerInfo.customerType)
  const delivery_type = parseInt(customerInfo.deliveryPickup.delivery_type)

  let delivery;
  if(customerInfo.delivery_form === true){
    delivery = {
      type: delivery_type,
      date: typeof customerInfo.deliveryPickup.delivery_date !== 'string'
        ? customerInfo.deliveryPickup.delivery_date.format('YYYY-MM-DD')
        : customerInfo.deliveryPickup.delivery_date,
      store_id: delivery_type === 1
        ? customerInfo.deliveryPickup.store_id
        : null,
      msisdn: simNumber,
    }
  }else{
    delivery = {
      msisdn: simNumber,
      esim_flag: true,
    };
  }
  

  return {
    booked_id: bookedId,
    warehouse_id: (isForceWarehouse || delivery_type !== 1 ) ? def.forceWarehouseID : null,
    referral: referral,
    rep_id: rep_id,
    customer: {
      type: customerInfo.customerType,
      referrer_customer_id: null,
      id: null,
      class_type: 'Regular',
      class_type_id: 1,
      users: [{
        ...user(orderStep),        
        fin_expiry_date: typeof customerInfo.customerDetail.fin_expiry_date !== 'string'
          ? customerInfo.customerDetail.fin_expiry_date.format('YYYY-MM-DD')
          : customerInfo.customerDetail.fin_expiry_date,
        origin_country: customerInfo.customerDetail.origin_country
          ? customerInfo.customerDetail.origin_country
          : null
      }],
      payment_info: {
        payment_type_id: 15,
        status_id: 4,
        details_encrypted: '',
        details: ''
      }
    },
    order: {
      ...order({ ...orderStep, orderType }),
      previous_provider_id: !!previousProvider ? previousProvider : null,
      contract_id: bundleContract
    },
    subscription: {
      service_type: customerInfo.customerType,
      bill_day: customerInfo.billingCycle ? customerInfo.billingCycle.cycle_name : 1,
      tp_status: 30,
      net_co: 'ON',
      addresses: [
        {
          type: 'Service',
          ...address(customerInfo.serviceAddress)
        },
        {
          type: 'Billing',
          ...address(customerInfo.billingAddress)
        }
      ]
    },
    delivery
  }
}

export const freedomParams = ({
  previousProvider,
  bundleContract,
  bookedId,
  orderType,
  orderStep,
  customerInfo,
  simNumber,
  referral,
  rep_id
}) => {
  const isForceWarehouse = def.forceWarehouse.includes(customerInfo.customerType)
  const delivery_type = parseInt(customerInfo.deliveryPickup.delivery_type)
  let delivery;
  if(customerInfo.delivery_form === true){
    delivery = {
      type: delivery_type,
      date: typeof customerInfo.deliveryPickup.delivery_date !== 'string'
        ? customerInfo.deliveryPickup.delivery_date.format('YYYY-MM-DD')
        : customerInfo.deliveryPickup.delivery_date,
      store_id: delivery_type === 1
        ? customerInfo.deliveryPickup.store_id
        : null,
      msisdn: simNumber,
    }
  }else{
    delivery = {
      msisdn: simNumber,
      esim_flag: true,
    };
  }

  return {
    booked_id: bookedId,
    warehouse_id: (isForceWarehouse || delivery_type !== 1 ) ? def.forceWarehouseID : null,
    referral: referral,
    rep_id: rep_id,
    customer: { 
      type: customerInfo.customerType,
      referrer_customer_id: null,
      id: null,
      class_type: 'Regular',
      class_type_id: 1,
      users: [{
        ...user(orderStep),
        fin_expiry_date: typeof customerInfo.customerDetail.fin_expiry_date !== 'string'
          ? customerInfo.customerDetail.fin_expiry_date.format('YYYY-MM-DD')
          : customerInfo.customerDetail.fin_expiry_date,
        origin_country: customerInfo.customerDetail.origin_country
          ? customerInfo.customerDetail.origin_country
          : null
      }],
      payment_info: {
        payment_type_id: 15,
        status_id: 4,
        details_encrypted: '',
        details: ''
      }
    },
    order: {
      ...order({ ...orderStep, orderType }),
      previous_provider_id: !!previousProvider ? previousProvider : null,
      contract_id: bundleContract
    },
    subscription: {
      service_type: customerInfo.customerType,
      bill_day: customerInfo.billingCycle ? customerInfo.billingCycle.cycle_name : 1,
      tp_status: 30,
      net_co: 'ON',
      addresses: [
        {
          type: 'Service',
          ...address(customerInfo.serviceAddress)
        },
        {
          type: 'Billing',
          ...address(customerInfo.billingAddress)
        }
      ]
    },
    delivery
  }
}

export const infinityParams = ({
  previousProvider,
  bundleContract,
  bookedId,
  orderType,
  orderStep,
  customerInfo,
  referral,
  rep_id
}) => {
  const isForceWarehouse = def.forceWarehouse.includes(customerInfo.customerType)
  const delivery_type = 2

  return {
    booked_id: bookedId,
    warehouse_id: (isForceWarehouse || delivery_type !== 1 ) ? def.forceWarehouseID : null,
    referral: referral,
    rep_id: rep_id,
    customer: {
      type: customerInfo.customerType,
      referrer_customer_id: null,
      id: null,
      class_type: 'Regular',
      class_type_id: 1,
      users: [{
        ...user(orderStep),
        fin_expiry_date: typeof customerInfo.customerDetail.fin_expiry_date !== 'string'
          ? customerInfo.customerDetail.fin_expiry_date.format('YYYY-MM-DD')
          : customerInfo.customerDetail.fin_expiry_date,
        origin_country: customerInfo.customerDetail.origin_country
          ? customerInfo.customerDetail.origin_country
          : null
      }],
      payment_info: {
        payment_type_id: 15,
        status_id: 4,
        details_encrypted: '',
        details: ''
      }
    },
    order: {
      ...order({ ...orderStep, orderType }),
      previous_provider_id: !!previousProvider ? previousProvider : null,
      contract_id: bundleContract
    },
    subscription: {
      service_type: customerInfo.customerType,
      tp_status: 30,
      net_co: 'ON',
      addresses: [
        {
          type: 'Service',
          ...address(customerInfo.serviceAddress)
        },
        {
          type: 'Billing',
          ...address(customerInfo.billingAddress)
        }
      ]
    }
  }
}

export const touristParams = ({
  bundleContract,
  orderStep,
  orderType,
  customerInfo
}) => {

  let delivery;
  if(customerInfo.delivery_form === true){
    delivery = {
      type: 1,
      store_id: 1,
      date: orderStep.date_of_arrival,
      msisdn: []
    }
  }else{
    delivery = {
      esim_flag: true,
    };
  }

  console.log(customerInfo)
  
  return {
    rep_id: 1,
    customer: {
      type: customerInfo.customerType,
      referrer_customer_id: null,
      id: null,
      class_type: 'Foreign',
      class_type_id: 13,
      users: [
        {
          ...user(orderStep),
          fin_expiry_date: typeof customerInfo.customerDetail.fin_expiry_date !== 'string'
            ? customerInfo.customerDetail.fin_expiry_date.format('YYYY-MM-DD')
            : customerInfo.customerDetail.fin_expiry_date,
        }
      ]
    },
    order: {
      ...order({ ...orderStep, orderType }),
      previous_provider_id: null,
      contract_id: bundleContract
    },
    subscription: {
      service_type: customerInfo.customerType,
      tp_status: 30,
      net_co: 'ON',
      addresses: [
        { ...def.defaultAddress, type: 'Service' },
        { ...def.defaultAddress, type: 'Billing' }
      ]
    },
    delivery
  }
}

export const easiRechargeParams = ({
  customerInfo,
  packagePlan,
  rep_id
}) => {

  let items = [];
  const generateItemList = () => {
    for (let i = 0; i < packagePlan.product_count; i++) {
      items.push(packagePlan.product);
    }
    return items;
  };
  generateItemList();

  return {
    msisdn: customerInfo.msisdn,
    items: items,
    dealer_id: rep_id,
    service_line: '',
    subscription_id: customerInfo.subscriptionId,
    usage_type: 2,
    amount: packagePlan.product_total_price
  }
}