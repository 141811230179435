import React from "react";
import { Col, Layout, Row } from 'antd';
import Styles from './style.module.scss';
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <Layout.Footer>
      <div className={Styles.footer}>
        <div className="container text-center">
          <img className="margin-md-bottom" src={require("assets/images/dst_logo.png")} alt="dst-logo" />

          <div className="flex flex-around width-md margin-auto">
            <div><img className={Styles.snsIcon} src={require("assets/images/icon/facebook.png")} alt="dst-facebook" /></div>
            <div><img className={Styles.snsIcon} src={require("assets/images/icon/instagram.png")} alt="dst-instagram" /></div>
            <div><img className={Styles.snsIcon} src={require("assets/images/icon/youtube.png")} alt="dst-youtube" /></div>
          </div>
        </div>
      </div>
      <div className={Styles.copyright}>
        <Row>
          <Col span={12} className="container margin-rm-bottom text-left">
            Copyright &copy;{new Date().getFullYear()} dst. All Rights Reserved
          </Col>
          <Col span={12} className="container margin-rm-bottom text-right">
            <a href="https://dst.com.bn/wp-content/uploads/2020/01/Master-Terms-and-Conditions-of-Services-22.1.2020.pdf" className={Styles.link}>Terms & Conditions</a> | <a href="https://dst.com.bn/legal/" className={Styles.link}>Privacy Policy</a>
          </Col>
        </Row>
      </div>
    </Layout.Footer>
  );
}

export default Footer;