import React, { useEffect, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";

import { Context } from "state/store";
import { env } from "config/const";
import paymentAction from "state/actions/paymentAction";
import systemAction from "state/actions/systemAction";
import orderAction from "state/actions/orderAction";
import OrderSuccess from "components/orderSuccess";

export default function OrderFinish() {
  const history = useHistory();
  const {pathname} = useLocation();
  const [state, dispatch] = useContext(Context);
  const {orderResult} = state.orderReducer;

  const toPayment = (type) => {
    systemAction.startLoading(dispatch);
    paymentAction.type(dispatch, type);
    history.push('/payment')
  }
  
  const onLoad = () => {
    orderAction.finish(dispatch);
    systemAction.allowPage(dispatch, [pathname]);

    let params = {
      subscription_id: orderResult.subscription_id,
      app_url: env.appUrl
    }
    paymentAction.getBill(dispatch, params)
      .finally(() => systemAction.stopLoading(dispatch))
      .catch(() => systemAction.stopLoading(dispatch))
  }
  useEffect(onLoad, []);

  return (
    <div className="container">
      <OrderSuccess payment={type => toPayment(type)} />
    </div>
  );
};