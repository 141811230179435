import React from 'react';
import { MinusCircleOutlined } from '@ant-design/icons';
import Style from './style.module.scss';

export default function PanelTimeline({children, title, noLine}) {
  return (
    <section className={`${Style.panel}${!!noLine? ' ' + Style.noLine : ''}`}>
      <div className={Style.icon}>
        <MinusCircleOutlined /> 
        <h3>{title}</h3>
      </div>
      <div className={Style.content}>
        {children}
      </div>
    </section>
  );
};