import { useLocation } from 'react-router-dom';
import { env } from 'config/const';
import ReactGA from 'react-ga';

function SendPageGA() {
  const {pathname} = useLocation();
  if(env.appMode === 'production') {
    ReactGA.pageview(pathname);
  }

  return (null);
}

export default SendPageGA;