import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Form, Select, Radio, Input, Button, Modal, Col, Row } from 'antd';
import { LoadingOutlined, SolutionOutlined } from '@ant-design/icons';
import { Context } from 'state/store';
import sourceAction from 'state/actions/sourceAction';
import systemAction from "state/actions/systemAction";
import orderAction from 'state/actions/orderAction';
import useCheckMark from "hook/useCheckMark";

const { Option } = Select;

export default function OrderType({
  field,
  orderType,
  usedProvider,
  modalOrderType,
  setModalOrderType,
  submitOrderType,
  customerType
}) {
  const { t } = useTranslation();
  const [state, dispatch] = useContext(Context);
  const {provider} = state.sourceReducer;
  const [type, setType] = useState(null);
  const {blackList} = useCheckMark();
  const history = useHistory();
  const [disableSubmitBotton, setDisableSubmitBotton] = useState(true);
  const [disabledIcField, setDisabledIcField] = useState(false);
  const [hideProviderForm, setHideProviderForm] = useState(true);
  const [showloadingLabel, setShowloadingLabel] = useState(false);
  const [phone, setPhone] = useState(null);
  const [mobilePhone, setMobilePhone] = useState(null);
  const [chosenProvider, setChosenProvider] = useState(null);
  const [validateStatus, setValidateStatus] = useState(null);
  const [help, setHelp] = useState(null);
  const [identityNumber, setIdentityNumber] = useState(null);
  const [disableCheckingBotton, setDisableCheckingBotton] = useState(true);

  const onSetType = () => {
    if(!!identityNumber === false && !!type) {
      setValidateStatus("error");
      setHelp("*IC Number / Passport Number is required");
      setDisableSubmitBotton(true);
      setDisableCheckingBotton(true);
    } else if(type === field.order_type.no) {
      setDisableSubmitBotton(false);
    } else if(type === field.order_type.yes) {
      setDisableSubmitBotton(true);
    }

    if(type === field.order_type.yes && !provider.length) {
      sourceAction.provider(dispatch);
    }
  }

  const onProviderChange = (value) => {
    setChosenProvider(value);
    if(!!value && (!!phone || !!mobilePhone)) {
      setDisableSubmitBotton(false);
    } else {
      setDisableSubmitBotton(true);
    }
  }

  const onPhoneChange = (value) => {
    setPhone(value.target.value);
    if(!!value.target.value && !!chosenProvider) {
      setDisableSubmitBotton(false);
    } else {
      setDisableSubmitBotton(true);
    }
  }

  const onMobilePhoneChange = (value) => {
    setMobilePhone(value.target.value);
    if(!!value.target.value && !!chosenProvider) {
      setDisableSubmitBotton(false);
    } else {
      setDisableSubmitBotton(true);
    }
  }

  const onChangeID = (value) => { 

    setIdentityNumber(value.target.value);
    setValidateStatus(null);
    setHelp(null);
    setDisableCheckingBotton(true);

    if(value.target.value != '' && value.target.value != null && !(/[^0-9a-zA-Z]/.test(value.target.value))){
      setDisableCheckingBotton(false);
    }

    if(type === field.order_type.no) {
      setDisableSubmitBotton(false);
    }
  }

  const clearID = event => {
    document.getElementById("greeting-form").reset();
    setIdentityNumber(null);
    setValidateStatus(null);
    setHelp(null);
    setDisableSubmitBotton(true);
    setDisabledIcField(false);
    setHideProviderForm(true);
    setType(null);
    setDisableCheckingBotton(true);
    
  }

  const checkingID = async () => {
    setDisableCheckingBotton(true);
    setValidateStatus("validating");
    setHideProviderForm(true);
    setShowloadingLabel(true);
    setDisabledIcField(true);
    if(customerType == 'MOBI-PREPAID'){
        setValidateStatus("success");
        setHideProviderForm(false);
        setShowloadingLabel(false);
    }
    else if(identityNumber !== "" && identityNumber !== undefined ) {
      const isBlackList = await blackList(identityNumber);
      if(isBlackList) {
        setDisabledIcField(false);
        setShowloadingLabel(false);
        setValidateStatus("error");
        setHelp('*We are unable to authenticate your ID. Please contact our DSTCare 151 for assistance.');
      }
      else{
        setValidateStatus("success");
        setHideProviderForm(false);
        setShowloadingLabel(false);
      }
    }else{
      setDisabledIcField(false);
      setHideProviderForm(true);
      setShowloadingLabel(false);
    }
  }

  useEffect(onSetType, [type]);

  return (
    <Modal
      width={420}
      footer={null}
      closable={false}
      maskClosable={false}
      open={modalOrderType && !orderType}
      onCancel={() => setModalOrderType(false)}
      centered
    >
      <Form id="greeting-form" onFinish={(value) => submitOrderType(value)}>
        <p className="text-bold text-left">{t('lblIndentityNumber')}</p>
        <Row gutter={24} className="margin-bottom">
          <Col xs={20}>
          <Form.Item
            name="identity_number"
            rules={[
              { required: true, message: '*IC Number / Passport Number is required'},
              { pattern: new RegExp(/^[a-zA-Z0-9]+$/), message: '*Only alphanumeric characters allowed'},
            
            ]}
            validateStatus={validateStatus}
            help={help}
            hasFeedback={disabledIcField}
          >
            <Input size="large" disabled={disabledIcField} placeholder={t('inputIdentityNumber') + '*'} onChange={onChangeID}
            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}/>
            
          </Form.Item>
          </Col>
          <Col xs={4}>
          { hideProviderForm === false &&
          <>
            
              <div className="flex flex-right">
                <Button type="link" danger onClick={() => clearID()}>{t('btnClear')}</Button>
              </div>
            </>
          }
          </Col>
          
        </Row>
        
        { hideProviderForm === false &&
         <>
          <p className="text-bold text-left">{t('lblExistingCustomer')}</p>
        
          <Form.Item
            className="text-left"
            name="order_type"
            rules={[
              { required: true, message: '*This is required' }
            ]}
          >
            <Radio.Group onChange={(val) => setType(val.target.value)}>
              <Radio className="radio-pile" value={field.order_type.yes}>
              {t('btnYes')}
              </Radio>
              <Radio className="radio-pile" value={field.order_type.no}>
              {t('btnNo')}
              </Radio>
            </Radio.Group>
          </Form.Item>
            
          {type === field.order_type.yes &&
          <>
            <Form.Item
              name="previous_provider_id"
              rules={[
                { required: true, message: '*Existing Service Provider is required' }
              ]}
            >
              <Select
                size="large"
                placeholder={t('inputExistingProvider') + '*'}
                notFoundContent="No data"
                onChange={onProviderChange}
              >
                {provider.map(d => 
                  (usedProvider.includes(d.id) &&
                    <Option key={d.id}>{d.name}</Option>
                  )
                )}
              </Select>
            </Form.Item>
            
            {!!field.mobile_phone &&
              <Form.Item
                name="mobile_phone"
                rules={[
                  { 
                    required: field.mobile_phone === 'required',
                    message: '*Mobile Phone is required'
                  },
                  {
                    pattern: new RegExp(/^(6737\d{6}|6738\d{6}|673228\d{4}|673229\d{4}|7\d{6}|8\d{6}|228\d{4}|229\d{4})$/g),
                    message: "*Invalid format number"
                  },
                  { min: 7, max: 10, message: "*Mobile Number must be between 7 and 10 characters" }
                ]}
              > 
                <Input size="large" placeholder={t('inputMobile') + '*'} onChange={onMobilePhoneChange} />
              </Form.Item>
            }

            {!!field.phone &&
              <Form.Item
                name="phone"
                rules={[
                  { required: field.phone === 'required', message: '*Land Line Number is required' },
                  { pattern: '^[0-9]+$', message: "*Invalid format number" },
                  { min: 7, max: 10, message: "*Land Line Number must be between 7 and 10 characters" }
                ]}
              >
                <Input size="large" placeholder={t('inputPhoneHome') + '*'} onChange={onPhoneChange} />
              </Form.Item>
            }
          </>
          }
        </>
        }
        <Row gutter={20} className="margin-bottom">
          <Col xs={10}>
            <div className="flex flex-left">
              <Button type="primary" shape="round" ghost onClick={() => history.goBack()} block>
                {t('btnBack')}
              </Button>
            </div>
          </Col>
          <Col xs={10}>
            <div className="flex flex-right">
              { hideProviderForm === true &&
                <>
                <Button type="primary" shape="round" disabled={disableCheckingBotton} onClick={() => checkingID()} block >{t('btnProceed')}</Button>
                </>
              }
              { hideProviderForm === false &&
                <>
                <Button type="primary" shape="round"  disabled={disableSubmitBotton}  htmlType="submit" block>{t('btnProceed')}</Button>
                </>
              }
            </div>
          </Col>
        </Row>
        </Form>
    </Modal>
  );
};
