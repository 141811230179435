import React, { useEffect, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Context } from "state/store";
import { env } from "config/const";
import paymentAction from "state/actions/paymentAction";
import systemAction from "state/actions/systemAction";
import orderAction from "state/actions/orderAction";
import OrderSuccess from "components/orderSuccess";
import StepIndicator from "components/stepIndicator";

export default function OrderFinish() {
  const history = useHistory();
  const { t } = useTranslation();
  const {pathname} = useLocation();
  const [state, dispatch] = useContext(Context);
  const {orderResult} = state.orderReducer;
  const {isLogined} = state.userReducer;

  const steps = [t('step1'), t('step2'), t('step3')];

  const onLoad = () => {
    orderAction.finish(dispatch);
    systemAction.allowPage(dispatch, [pathname]);

    let params = {
      subscription_id: orderResult.subscription_id,
      app_url: env.appUrl
    }
    paymentAction.getBill(dispatch, params)
      .finally(() => systemAction.stopLoading(dispatch))
      .catch(() => systemAction.stopLoading(dispatch))

    
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }
  useEffect(onLoad, []);

  const toPayment = (type) => {
    systemAction.startLoading(dispatch);
    paymentAction.type(dispatch, type);
    history.push('/payment')
  }

  return (
    <div className="container">
      <StepIndicator steps={steps} current={3} />

      <OrderSuccess payment={type => toPayment(type)} hasWallet={isLogined} />
    </div>
  );
};