import React, { useContext, useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router";
import { Context } from "state/store";
import { def } from "config/const";
import sourceAction from "state/actions/sourceAction";
import systemAction from "state/actions/systemAction";
import orderAction from "state/actions/orderAction";
import useQuery from "hook/useQuery";
import moment from "moment";

export default function Parse() {
  const query = useQuery();
  const history = useHistory();
  const [, dispatch] = useContext(Context);
  const [toHome, setToHome] = useState(false);

  const getParams = (init) => {
    return query.get(init) ? query.get(init) : null
  }

  const getParamDate = (init) => {
    return query.get(init) ? moment(query.get(init)) : null
  }

  const getParamAddress = (data) => {
    return data
      ? {
        filter: "All",
        address: !!data ? `${data.district_name} , ${data.mukim_name} , ${data.village_name}` : null,
        search_address: !!data.village_name ? data.village_name : null,
        district: !!data.district_id ? data.district_id : null,
        mukim: !!data.mukim_id ? data.mukim_id : null,
        village: !!data.village_id ? String(data.village_id) : null,
        postal_code: getParams('postal_code'),
        street: getParams('street'),
        simpang: getParams('simpang'),
        block: getParams('block'),
        building_name: getParams('building_name'),
        floor: getParams('floor'),
        unit: getParams('unit'),
        home_number: getParams('home_number'),
      }
      : null
  }
  
  const decorateParams = (address) => {
    let salutation = getParams('salutation');

    return {
      sameAddress: true,
      customerType: getParams('customer_type'),
      customerDetail: {
        salutation: salutation ? salutation : 'Mr',
        full_name: getParams('name'),
        identity_number: getParams('ic_number'),
        fin_expiry_date: getParamDate('ic_expiry'),
        email: getParams('email'),
        mobile_phone: getParams('mobile_phone'),
        work_phone: getParams('work_phone'),
        birth_date: getParamDate('birth_date'),
        date_of_arrival: getParamDate('arrival_date'),
        flight_number: getParams('flight_number'),
        nationality: getParams('nationality'),
        origin_country: getParams('country'),
      },
      deliveryPickup: {
        delivery_type: getParams('delivery_type'),
        delivery_date: getParamDate('delivery_date'),
        store_id: getParams('store_id'),
        store_name: getParams('store_name'),
        store_address: getParams('store_address')
      },
      billingCycle: {
        cycle_name: getParams('cycle_name')
      },
      billingAddress: getParamAddress(address),
      serviceAddress: getParamAddress(address)
    }
  }

  const onLoad = () => {
     const startProcess = async () => {
      systemAction.startLoading(dispatch);

      if(!!getParams('customer_type') && !!def.customerTypeUrl[getParams('customer_type')]) {      
        let res = await sourceAction.addressByVillage(dispatch, getParams('village'));
        let params = decorateParams(res);
        orderAction.customerInfo(dispatch, params);

        if(!!getParams('product_package')) {
          let packagePlan = {
            plan: [{id: Number(getParams('product_package'))}],
            device: !!getParams('device') ? {bundle_item_id: Number(getParams('device'))} : null,
            addon: !!getParams('addon') ? {bundle_item_id: Number(getParams('addon'))} : null,
            promo: !!getParams('promo') ? {bundle_item_id: Number(getParams('promo'))} : null,
          }
          orderAction.packagePlan(dispatch, packagePlan);
        }

        systemAction.stopLoading(dispatch);

        let network = ''
        if(getParams('network') == '5G' ){
          network = '-5g';
        }

        history.push(def.customerTypeUrl[params.customerType] + network + '/customer-info');
      } else {
        systemAction.stopLoading(dispatch);
        setToHome(true);
      }
    }

    startProcess()    
  }

  useEffect(onLoad, []);

  if(toHome){
    return <Redirect to="/" />
  } else {
    return null;
  }
}

/* Note: 
 * http://localhost:4100/parse?customer_type=MOBI-POSTPAID&salutation=Mr&name=Tes%20Name&ic_number=50123CUS122&ic_expiry=2021-10-11&country=Indonesia&email=test.mail%40yopmail.com&mobile_phone=6737123123&work_phone=6737123123&birth_date=1990-01-01&delivery_type=1&delivery_date=2021-04-29&store_name&store_address&cycle_name=1&village=5&postal_code=BS8675&street=Muaral&simpang=Peti%20Surat&block&building_name&floor&unit&home_number=86
*/