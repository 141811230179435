import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import RouteAuth from "router/routeAuth";
import ScrollToTop from "router/scrollToTop";
import RouteTourist from 'router/routeTourist';
import RouteInfinity from 'router/routeInfinity';
import RouteEasi from 'router/routeEasi';
import RouteEasiRecharge from 'router/routeEasiRecharge';
import RouteFreedom from 'router/routeFreedom';
import RouteWifiPin from "./routeWifiPin";
import RouteMobi from 'router/routeMobi';
import RouteMobi5G from 'router/routeMobi5G';
import RouteFreedom5G from 'router/routeFreedom5G';
import Layout from 'layouts/layout';
import Home from 'pages/home';
import NotFound from 'pages/notFound';
import Payment from "pages/payment";
import Scanner from "pages/parse";
import TermCondition from "pages/termCondition";
import Parse from "pages/parse";
import SendPageGA from "./sendPageGA";

export default () => {
  return (
    <Router>
      <Layout>
		    <ScrollToTop />
        <Switch>
          <Route exact path='/' component={Home}/>
          <Route path='/easi' component={RouteEasi}/>
          <Route path='/mobi' component={RouteMobi}/>
          <Route path='/freedom' component={RouteFreedom}/>
          <Route path='/infinity' component={RouteInfinity}/>
          <Route path='/tourist' component={RouteTourist}/>
          <Route path='/retrive_payment/:orderId' component={Payment}/>
          <Route path='/payment' component={Payment}/>
          <Route path='/scanner-test' component={Scanner}/>
          <Route path='/wifi-pin' component={RouteWifiPin}/>
          <Route path='/mobi-5g' component={RouteMobi5G}/>
          <Route path='/freedom-5g' component={RouteFreedom5G}/>
          <Route path='/terms-and-conditions' component={TermCondition}/>
          <Route path='/parse' component={Parse}/>
          <RouteAuth path='/easi-recharge' component={RouteEasiRecharge}/>
          <Route path="*" component={NotFound}/>
        </Switch>
      </Layout>
      <SendPageGA />
    </Router>
  );
}
