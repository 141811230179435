import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Row, Col, Form, Switch, Button } from "antd";
import { CheckOutlined, CloseOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';

import { Context } from "state/store";
import useDependency from "hook/useDependency";
import systemAction from "state/actions/systemAction";
import orderAction from "state/actions/orderAction";
import StepIndicator from "components/stepIndicator";
import PanelTimeline from "components/panelTimeline";
import CustomerDetail from "components/customerDetail";
import AddressForm from "components/addressForm";
import Sticky from "sticky-js";

const customerFormField = {
  salutation: true,
  full_name: 'required',
  identity_number: 'required',
  email: 'required',
  mobile_phone: 'required',
  phone: true,
  work_phone: true,
  birth_date: 'required',
  nationality: 'required',
  origin_country: 'required',
  fin_expiry_date: 'required'
}

export default function CustomerInfo() {
  const { t } = useTranslation();
  const dependency = useDependency();
  const history = useHistory();
  const [state, dispatch] = useContext(Context);
  const {orderStep, customerInfo, summary} = state.orderReducer;
  const {allowPage} = state.systemReducer;
  const [formCustomerDetail] = Form.useForm();
  const [formServiceAddress] = Form.useForm();
  const [formBillingAddress] = Form.useForm();
  const [sameAddress, setSameAddress] = useState(false);
  const [nationality, setNationality] = useState(null);

  const steps = [t('step1'), t('step2'), t('step3')];

  const propsCustomerDetail = {
    data: !!customerInfo ? customerInfo.customerDetail : null,
    setNationality: val => { setNationality(val) },
    customerType: customerInfo.customerType,
    form: formCustomerDetail,
    field: customerFormField
  }

  const propsServiceAddress = {
    data: !!customerInfo ? customerInfo.serviceAddress : null,
    form: formServiceAddress
  }

  const propsBillingAddress = {
    data: !!customerInfo ? customerInfo.billingAddress : null,
    form: formBillingAddress
  }

  // get error or data by form
  const submitForm = (form) => {
    return form.validateFields()
      .then(res => res)
      .catch(err => err)
  }

  const sendOrderStep = (data) => {
    let orderDetail = [];

    // order_detail
    summary.forEach(obj => {
      if(!!obj.item_id) {
        orderDetail.push(
          {item_id: obj.item_id, quantity: obj.quantity}
        )
      }
    })

    // populate data for order step
    let order = {
      ...orderStep,
      ...data.customerDetail,
      birth_date: data.customerDetail.birth_date.format('YYYY-MM-DD'),
      gender: data.customerDetail.salutation === 'Mr' ? 'M' : 'F',
      customer_type: data.customerType,
      order_type: data.order_type,
      order_detail: JSON.stringify(orderDetail)
    }

    // send to api order step
    orderAction.orderStep(dispatch, order, data.billingAddress, data.serviceAddress)
  }

  const submit = async() => {
    let error = false;
    let billingAddress = await submitForm(formBillingAddress);
    let serviceAddress = await submitForm(formServiceAddress);

    let data = {
      customerType: customerInfo.customerType,
      sameAddress,
      serviceAddress,
      customerDetail: await submitForm(formCustomerDetail),
      billingAddress: !sameAddress
        ? billingAddress
        : serviceAddress
    }
    
    // check errorFields
    Object.keys(data).map(obj => 
      error = error ? error : !!data[obj].errorFields
    );
    
    if (!error) {
      // check black list
      let step = "/infinity/order-review"
      let newAllowStep = [...allowPage, step];

      systemAction.startLoading(dispatch);
      systemAction.allowPage(dispatch, newAllowStep);
      orderAction.customerInfo(dispatch, data);
      sendOrderStep(data)
      history.push(step)
    }
  }

  const onChangeNationality = () => {
    const fetchData = async () => {
      if(!!nationality) {
        dependency.plan(nationality);
        dependency.device(nationality);
      }
    }

    fetchData();
  }

  const onLoad = () => {
    new Sticky('.sticky');
    systemAction.stopLoading(dispatch);
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }
  
  useEffect(onChangeNationality, [nationality]);
  useEffect(onLoad, []);

  return (
    <div className="container" data-sticky-container >
      <StepIndicator steps={steps} current={1} />

      <Row gutter={24} className="margin-bottom">
        <Col xs={24}>
          <PanelTimeline title={t('lblSectionCustomerData')}>
            <CustomerDetail {...propsCustomerDetail} />
          </PanelTimeline>

          <PanelTimeline title={t('lblSectionServiceAddress')}>
            <AddressForm {...propsServiceAddress}  />
          </PanelTimeline>

          <PanelTimeline title={t('lblSectionBillingAddress')} noLine>
            <p className="text-bold margin-bottom">
              Is the Billing Address the same as the service address ?
              <br></br>
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                defaultChecked={sameAddress}
                onClick={value => setSameAddress(value)}
              />
            </p>
            {!sameAddress &&
              <AddressForm {...propsBillingAddress}  />
            }
          </PanelTimeline>
        </Col>
        
        <Col xs={24}>
          <div className="flex flex-right margin-top">
            <Button type="primary" htmlType="submit" onClick={() => submit()}>
              {t('btnNext')}
              <RightOutlined />
              </Button>
          </div>
          <Button className="margin-top" type="default" onClick={() => history.goBack()}>
            <LeftOutlined />
            {t('btnBack')}
          </Button>
        </Col>
      </Row>
    </div>
  );
}
