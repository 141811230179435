import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'antd/dist/antd.min.css';
import 'style.scss';
import 'config/i18n';

import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import App from './app';
import * as serviceWorker from 'serviceWorker';
import { env } from 'config/const';

if(env.appMode === 'production') {
  ReactGA.initialize('UA-164129019-2', {
    debug: process.env.NODE_ENV !== 'production'
  });
}

if(process.env.NODE_ENV === 'production') {
  console.log = function () {};
}

ReactDOM.render(
  <App />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();