import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Row, Col, Descriptions, message } from "antd";
import { LeftOutlined } from "@ant-design/icons";

import api from "helpers/api";
import useOtp from "hook/useOtp";
import { Context } from "state/store";
import { infinityParams } from "helpers/paramsOrder";
import scrollToClass from "helpers/scrollToClass";
import ObjectValidator from "helpers/objectValidator";
import systemAction from "state/actions/systemAction";
import orderAction from "state/actions/orderAction";
import StepIndicator from "components/stepIndicator";
import Summary from "components/summary";
import UploadImage from "components/uploadImage";
import ReviewOtp from "components/reviewOtp";
import ReviewConsent from "components/reviewConsent";
import ReviewCustomerInfo from "components/reviewCustomerInfo";
import ReviewReferral from "components/reviewReferral";

export default function OrderReview() {
  const { t } = useTranslation();
  const history = useHistory();
  const {sendOtp, verifyOtp} = useOtp();

  const [state, dispatch] = useContext(Context)
  const {allowPage} = state.systemReducer
  const {isLogined, user} = state.userReducer
  const order = state.orderReducer
  const [errorMessage, setErrorMessage] = useState({})
  const [referralCode, setReferralCode] = useState(null)
  const [otpCode, setOtpCode] = useState(null)
  const [tnc, setTnc] = useState(false)
  const [nrciF, setNrciF] = useState(null)
  const [nrciB, setNrciB] = useState(null)
  const [sF, setSF] = useState(null)
  const [oF1, setOF1] = useState(null)
  const [oF2, setOF2] = useState(null)
  const [oF3, setOF3] = useState(null)

  const steps = [t('step1'), t('step2'), t('step3')];

  const propsReviewReferral = {
    customerInfo: order.customerInfo,
    plan: order.packagePlan.plan,
    referralCode,
    setReferralCode,
    errorMessage
  }

  const propsReviewOtp = {
    otp: order.otp,
    otpCode,
    setOtpCode,
    sendOtp: () => sendOtp(order.customerInfo.customerDetail.mobile_phone),
    errorMessage
  }

  const propsReviewConsent = {
    tnc,
    setTnc,
    submit:() => submit(),
    errorMessage
  }


  const validate = () => {
    const validReferral = (!referralCode && !order.referral) || (!!referralCode && !!order.referral)

    const schema = {
      nrciF: { require: () => !!nrciF ? '' :'Please input file' },
      nrciB: { require: () => !!nrciB ? '' :'Please input file' },
      sF: { require: () => !isLogined || !!sF ? '' :'Please input file' },
      tnc: { require: () => !!tnc ? '' :'Please confirm Terms & Conditions' },
      referral: { require: () => validReferral ? '' :'Please validate referral' },
      otpCode: { 
        length: () => !!otpCode && otpCode.length === 6 ? '' :'OTP must be 6 characters',
        require: () => !!otpCode ? '' :'Please Input OTP',
      }
    }
    const error = ObjectValidator(schema)
    setErrorMessage(error)
    scrollToClass('error-text')

    return error
  }

  const uploadFile = async ({order_id, subscription_id}) => {
    let files = [
      {...nrciF, type: 'NRCI_F'},
      {...nrciB, type: 'NRCI_B'}
    ]

    if(isLogined) {
      files.push({...sF, type: 'SF'})
      if(!!oF1) files.push({...oF1, type: 'OF_1'})
      if(!!oF2) files.push({...oF2, type: 'OF_2'})
      if(!!oF3) files.push({...oF3, type: 'OF_3'})
    }

    await orderAction.uploadDocument(dispatch, files, order_id, subscription_id)
  }

  const sendOrderStep = (order_id) => {
    // populate data for order step
    let data = {
      ...order.orderStep,
      success_flag: 1,
      order_id
    }

    // send to api order step
    orderAction.orderStep(dispatch, data)
  }
  
  const submit = async () => {
    if(!!validate()) return;

    let isVerified = await verifyOtp(otpCode);
    if(!isVerified) return;
      
    systemAction.startLoading(dispatch);
    let page = "/infinity/order-finish"
    let newAllowPage = [...allowPage, page]
    let dataObject = infinityParams({...order, ...user})
    
    orderAction.createOrder(dispatch, dataObject)
      .then(async ({data}) => {
        if (data.success) {
          await uploadFile(data.response)
          orderAction.result(dispatch, data.response)
          systemAction.allowPage(dispatch, newAllowPage)
          sendOrderStep(data.response.order_id)
          history.push(page)
        } else {
          systemAction.errorLog(dispatch, data, 'create_order_portal')
          systemAction.stopLoading(dispatch)
          message.error(t('msgSystemTrouble'), 3)
        }
      }).catch(error => {
        console.log(error.response);
        systemAction.stopLoading(dispatch)
        message.error(t('msgSystemTrouble'), 3)
      })
  }

  const onLoad = () => {
    systemAction.stopLoading(dispatch);
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }
  useEffect(onLoad, []);

  return (
    <div className="container">
      <StepIndicator steps={steps} current={2} />

      <Row gutter={24} className="margin-bottom review">
        <Col xs={24} lg={16} className="info">
          <ReviewCustomerInfo {...order} />

          <Row gutter={24} className="margin-bottom margin-md-top">
            <Col xs={24} md={12}>
              <Descriptions title={t('lblDataServiceAddress')} size="small" className="header-margin-sm">
                <Descriptions.Item>{order.customerInfo.serviceAddress.address}</Descriptions.Item>
              </Descriptions>
            </Col>
            <Col xs={24} md={12}>
              <Descriptions title={t('lblDataBillingAddress')} size="small" className="header-margin-sm">
                <Descriptions.Item>{order.customerInfo.billingAddress.address}</Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>

          <Row gutter={24} className="margin-bottom margin-md-top">
            <Col xs={24}>
              <Descriptions title="UPLOAD FILE" size="small" className="image-upload">
                <Descriptions.Item>
                  <div className="witdh-full margin-sm-bottom">
                    <UploadImage setImage={setNrciF} label="IC Number Front Side*" />
                    {!!errorMessage.nrciF && !nrciF &&
                      <p className="error-text">
                        {errorMessage.nrciF}
                      </p>
                    }
                  </div>
                  <div className="witdh-full margin-sm-bottom">
                    <UploadImage setImage={setNrciB} label="IC Number Back Side*" />
                    {!!errorMessage.nrciB && !nrciB &&
                      <p className="error-text">
                        {errorMessage.nrciB}
                      </p>
                    }
                  </div>
                  {isLogined &&
                  <>
                    <div className="witdh-full margin-sm-bottom">
                      <UploadImage setImage={setSF} label="Subcription Form*" />
                      {!!errorMessage.sF && !sF &&
                        <p className="error-text">
                          {errorMessage.sF}
                        </p>
                      }
                    </div>
                    <div className="witdh-full margin-sm-bottom">
                      <UploadImage setImage={setOF1} label="Other File #1" />
                    </div>
                    <div className="witdh-full margin-sm-bottom">
                      <UploadImage setImage={setOF2} label="Other File #2" />
                    </div>
                    <div className="witdh-full margin-sm-bottom">
                      <UploadImage setImage={setOF3} label="Other File #3" />
                    </div>
                  </>
                  }
                </Descriptions.Item>
              </Descriptions>
              <small>{t('lblTermUpload')}</small>
            </Col>
          </Row>
        </Col>

        <Col xs={24} lg={8}>
          <Row gutter={24}>
            <Col xs={24} className="summary">
              <Summary
                data={order.summary}
                mainLabel={t('lblSummaryMainSim')}
                showQty={true}
                muted
              />
            </Col>
            {process.env.REACT_APP_RAF_FORM === 'true' && (
            <>
              <Col xs={24} className="referral margin-top padding-top">
                <Descriptions title="REFER A FRIEND" size="small">
                  <Descriptions.Item>
                    <ReviewReferral {...propsReviewReferral} />
                  </Descriptions.Item>
                </Descriptions>
              </Col>
              </>
            )}
          </Row>
        </Col>
        <Col xs={24} lg={12} className="verify">
          <ReviewOtp {...propsReviewOtp} />
        </Col>
        <Col xs={24} lg={12} className="text-center">
          <ReviewConsent {...propsReviewConsent} />
        </Col>
      </Row>

      <Row gutter={24}>
        <Button className="margin-top" type="default" onClick={() => history.goBack()}>
          <LeftOutlined />
          {t('btnBack')}
        </Button>
      </Row>
    </div>
  );
}
