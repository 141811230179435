import React, { useContext } from 'react';
import { Layout as LayoutContainer, BackTop, Button } from 'antd';
import { UpOutlined } from '@ant-design/icons';
import { Context } from 'state/store';
import Header from 'layouts/header';
import Footer from 'layouts/footer';
import LoadingFull from 'components/loadingFull';

const { Content } = LayoutContainer;

const Layout = ({children}) => {
  const [state] = useContext(Context);
  const {loading} = state.systemReducer;

  return (
    <LayoutContainer>
      <Header />
      <Content>
        
        {children}
      </Content>
      <Footer />
      
      <BackTop>
        <Button type="primary" shape="circle" icon={<UpOutlined />} />
      </BackTop>

      <LoadingFull visible={loading} />
    </LayoutContainer>
  );
};

export default Layout;