const deviceSort = (devices) => {
  let ordered = {}
  
  for(let key in devices) {
    ordered[key] = {};
    Object.keys(devices[key])
      .sort((a,b)=> devices[key][a][0].price_amount - devices[key][b][0].price_amount)
      .map((item) => ordered[key][item] = devices[key][item]);
  }

  return ordered
}

export default deviceSort;