import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { Context } from "state/store";

const RouteAuth = ({ component: Component, auth, ...rest }) => {
  const [state] = useContext(Context);
  const {isLogined} = state.userReducer;
  
  return (
    <Route
      {...rest}
      render={props => {
        if(!isLogined) {
          return <Redirect to="/" />
        } else {
          return <Component {...props} />
        }
      }}
    />
  )
};

export default RouteAuth;
