import React, { useState, useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Row, Col, Button, Select, Input, Tooltip, Divider, Spin, Dropdown, Menu, Modal } from 'antd';
import { RightOutlined, LeftOutlined, InfoCircleOutlined, ScanOutlined } from '@ant-design/icons';

import { Context } from 'state/store';
import api from "helpers/api";
import useDebounce from "hook/useDebounce";
import useCheckMark from "hook/useCheckMark";
import systemAction from "state/actions/systemAction";
import StepIndicator from "components/stepIndicator";
import PanelTimeline from "components/panelTimeline";
import Summary from "components/summary";
import Barcode from "components/barcode";
import QrCode from "components/qrCode";
import sourceAction from "state/actions/sourceAction";
import orderAction from "state/actions/orderAction";
import ObjectValidator from "helpers/objectValidator";

const { Option } = Select;

const categoryNumber = {
  'Normal': 1,
  'Golden': 2
}

export default function ChooseNumber() {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const {bookingNumber} = useCheckMark();
  const [state, dispatch] = useContext(Context);
  const {allowPage} = state.systemReducer;
  const {isLogined } = state.userReducer;
  const {orderStep, packagePlan, simNumber, summary, customerInfo} = state.orderReducer;
  const [fetching, setFetching] = useState(true);
  const [numbers, setNumbers] = useState([]);
  const [errorMessage, setErrorMessage] = useState({})
  const [slotNumber, setSlotNumber] = useState({});
  const [searchNumber, setSearchNumber] = useState(null);
  const [searchCategory, setSearchCategory] = useState(null);
  const [itemKey, setItemKey] = useState(null);
  const [showQRcode, setShowQRcode] = useState(false);
  const [showBarcode, setShowBarcode] = useState(false);
  const debounceNumber = useDebounce(searchNumber, 500);
  
  const steps = [t('step1'), t('step2'), t('step3')];

  const propsBarcode = {
    show: showBarcode,
    setShow: res => setShowBarcode(res),
    setResult: res => setImsi(res, itemKey.productId, itemKey.key)
  }

  const propsQRcode = {
    show: showQRcode,
    setShow: res => setShowQRcode(res),
    setResult: res => setImsi(res, itemKey.productId, itemKey.key)
  }

  const getProductName = itemId => {
    return packagePlan.plan.find(obj => obj.id === Number(itemId)).name
  }
  
  // Old Api MSISDN
  const selectNumber = (res, productId, key) => {
    let selectedNumber = numbers.find(obj => obj.msisdnId === res)
    let newListNumbers = numbers.filter(obj => obj.msisdnId !== res)
    let tmpSlotNumber = {...slotNumber}
        tmpSlotNumber[productId][key].id = selectedNumber.msisdnId
        tmpSlotNumber[productId][key].number = selectedNumber.msisdnNo
    setSlotNumber(tmpSlotNumber)
    setNumbers(newListNumbers)
  }

  // New Api MSISDN
  /* const selectNumber = (res, productId, key) => {
    let selectedNumber = numbers.find(obj => obj.id === Number(res))
    let newListNumbers = numbers.filter(obj => obj.id !== Number(res))
    let tmpSlotNumber = {...slotNumber}
        tmpSlotNumber[productId][key].id = selectedNumber.id
        tmpSlotNumber[productId][key].number = selectedNumber.msisdn
    setSlotNumber(tmpSlotNumber)
    setNumbers(newListNumbers)
  } */

  const setImsi = (imsi, productId, key) => {
    let tmpSlotNumber = {...slotNumber}
        tmpSlotNumber[productId][key].imsi = imsi
 
    setSlotNumber(tmpSlotNumber)
  }

  const validateSchema = key => {
    return {
      number: { require: () => !!key.number ? '' : 'Number Field is Required' },
      imsi: {
        length: () => (!isLogined || (!!key.imsi && key.imsi.length === 15)) || customerInfo.esimFlag === true ? '' : 'IMSI number must be 15 numeric',
        require: () => (!isLogined || !!key.imsi)  || customerInfo.esimFlag === true ? '' : 'IMSI number is Required'
      },
    }
  }

  const validate = () => {
    let error = {...errorMessage};
    let result = false;

    Object.keys(slotNumber).forEach(elm => {
      slotNumber[elm].forEach((obj, i) => {
        let message = ObjectValidator(
          validateSchema(obj)
        )

        if(!!message) error[elm][i] = message
        if(!result) result = !!message
      })
    })

    setErrorMessage(error)
    return result
  }

  const sendOrderStep = number => {
    let order = orderStep;
        order.order_step['book_number'] = JSON.stringify(number);
    
    api.post('order_portal/update_order_step', { order })
    dispatch({ type: "ORDER_STEP", payload: order })
  }

  const submit = async () => {
    if(!!validate()) return;

    systemAction.startLoading(dispatch);
    const parentPathname = location.pathname.split('/')[1];
    let newStep = `/${parentPathname}/customer-info`;
    let newAllowStep = [...allowPage, newStep];

    /* const checkImsi = await imsiNumber(slotNumber, errorMessage);
    setErrorMessage(checkImsi.error);
    if(!checkImsi.result) return; */

    // New Api MSISDN
    let bookedNumber = orderStep.order_step['book_number']
      ? JSON.parse(orderStep.order_step['book_number'])
      : null;
    const resultBookNumber = await bookingNumber(slotNumber, bookedNumber);
    if(!resultBookNumber) return;

    sendOrderStep(slotNumber);
    orderAction.autoNumber(dispatch, false);
    orderAction.simNumber(dispatch, slotNumber);
    systemAction.allowPage(dispatch, newAllowStep);
    history.push(newStep);
  }

  const onDebounceNumber = () => {
    // Old Api MSISDN
    if(searchNumber != null && searchCategory != null) {
      async function search() {
        setFetching(true)

        let params = {
          category: "Recycled", 
          sim_no: searchNumber,
        }
        let numberList = await sourceAction.msisdn(dispatch, params)
        
        // recall if Normal number is empty
        // if(numberList.length === 0 && def.msisdnCategory[searchCategory - 1] === 'Normal') {
        //   params.category = 'Recycled'
        //   numberList = await sourceAction.msisdn(dispatch, params)
        // }
        
        setFetching(false)
        setNumbers(numberList)
      }
      search();
    }

    // New Api MSISDN
    /* if(searchNumber != null) {
      let params = {
        category: searchCategory,
        sim_no: searchNumber,
      }
      setFetching(true)
      sourceAction.msisdn(dispatch, params)
        .then(res => {
          setFetching(false)
          setNumbers(res)
        })
        .catch(error => {
          console.log(error);
          setFetching(false)
          message.error(t('msgSystemTrouble'), 3)
        })
    } */
  }

  const onLoad = () => {
    systemAction.stopLoading(dispatch);

    let slot = {}
    let error = {}
    packagePlan.plan.forEach(obj => {
      let number = []
      let numberError = []
      Array(obj.quantity).fill().forEach(() => {
        number.push({
          category: categoryNumber[obj.number_category],
          id: null,
          number: null,
          imsi: null
        })
        
        numberError.push({
          number: null,
          imsi: null
        })
      })
      slot[obj.id] = number;
      error[obj.id] = numberError;
    });

    setSlotNumber(!!simNumber ? simNumber : slot);
    setErrorMessage(error);
  }

  useEffect(onLoad, []);
  useEffect(onDebounceNumber, [debounceNumber]);
  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  
  const menu = (
    <Menu>
      <Menu.Item key="1" icon={<ScanOutlined />} onClick={() => setShowBarcode(true)}>
        Barcode Scanner
      </Menu.Item>
      <Menu.Item key="2" icon={<ScanOutlined />} onClick={() => setShowQRcode(true)}>
        QRcode Scanner
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="container">
      <StepIndicator steps={steps} current={1} />

      <Row gutter={24} className="margin-bottom">
        <Col xs={24} md={24}>
          <PanelTimeline title="Choose Number" noLine>
          {Object.keys(slotNumber).map((productId) =>
          <React.Fragment key={productId}>
            {slotNumber[productId].map((obj, key) =>
              <Row gutter={16} key={key}>
                <Col xs={24} md={16}>
                  <h5 className="margin-rm">{getProductName(productId)}</h5>
                </Col>
                <Col xs={24} md={8}>
                  <Row gutter={16}>
                    <Col xs={24}>
                      <Select
                        showSearch
                        size="large"
                        className="width-full"
                        placeholder="Search Number*"
                        defaultActiveFirstOption={false}
                        showArrow={true }
                        filterOption={false}
                        value={obj.number}
                        onFocus={() => {
                          setSearchCategory(obj.category)
                          setSearchNumber('')
                        }}
                        onSearch={value => setSearchNumber(value)}
                        onSelect={res => selectNumber(res, productId, key)}
                        notFoundContent={fetching 
                          ? <Spin size="small" /> 
                          : "Number not found"
                        }
                      >
                        {/* Old Api MSISDN */}
                        {numbers.map(d => <Option key={d.msisdnId}>{d.msisdnNo}</Option>)}
                        
                        {/* New Api MSISDN */}
                        {/* {numbers.map(d => <Option key={d.id}>{d.msisdn}</Option>)} */}
                      </Select>

                      <p className="error-text">
                        {errorMessage[productId][key].number}
                      </p>
                    </Col>

                    {isLogined && customerInfo.esimFlag === false &&
                    <>
                      <Col xs={24} md={24} className="flex">
                        <Input
                          size="large"
                          className="width-full"
                          placeholder="IMSI Number*"
                          maxLength={15}
                          value={obj.imsi}
                          onChange={e => setImsi(e.target.value.replace(/[^\d]/gi, ''), productId, key)}
                          suffix={
                            <Tooltip title="IMSI number must be 15 numeric">
                              <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                            </Tooltip>
                          }
                        />
                        <Dropdown
                          overlay={menu}
                          trigger={['click']}
                          onVisibleChange={() => setItemKey({productId, key})}
                        >
                          <Button size="large">
                            <ScanOutlined />
                          </Button>
                        </Dropdown>
                      </Col>

                      <Col>
                        <p className="error-text">
                          {errorMessage[productId][key].imsi}
                        </p>
                      </Col>
                    </>
                    }
                  </Row>
                </Col>

                {slotNumber.length - 1 !== key && 
                  <Divider />
                }
              </Row>
            )}
          </React.Fragment>
          )}
          </PanelTimeline>
        </Col>
        
        <Col xs={24} className="flex flex-between">
          <Button className="margin-top" type="default" onClick={() => history.goBack()}>
            <LeftOutlined />
            {t('btnBack')}
          </Button>
          
          <Button className="margin-top" type="primary" onClick={() => submit()}>
            {t('Next')}
            <RightOutlined />
          </Button>
        </Col>
      </Row>

      <Modal
        width={360}
        footer={null}
        closable={false}
        maskClosable={false}
        onCancel={() => setShowBarcode(false)}
        className="scanner"
        open={showBarcode}
        centered
      >
        <Barcode {...propsBarcode} />
      </Modal>

      <Modal
        width={360}
        footer={null}
        closable={false}
        maskClosable={true}
        className="scanner"
        onCancel={() => setShowQRcode(false)}
        open={showQRcode}
        centered
      >
        <QrCode {...propsQRcode} />
      </Modal>
    </div>
  );
}
