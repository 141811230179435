import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { CheckCircleTwoTone, RightOutlined } from '@ant-design/icons';

export default function OrderSuccess({payment, finish, hasWallet}) {
  const { t } = useTranslation();

  return (
    <div className="width-max-xl margin-auto margin-lg-top margin-md-bottom text-center">
      <h1><CheckCircleTwoTone twoToneColor="#52c41a" /></h1>
      <h3>{t('lblThanks')}</h3>
      <h5>
        {payment 
          ? t('lblThanksDescription')
          : t('lblThanksDescriptionWithoutPayment')
        }
      </h5>

      {!!payment &&
      <div className="flex">
        {!!hasWallet &&
          <Button
            block
            size="large"
            type="primary"
            className="margin-sm-right"
            onClick={() => payment('wallet')}
            >
            {t('btnPayWallet')}
            <RightOutlined />
          </Button>
        }
        <Button
          block
          size="large"
          type="primary"
          onClick={() => payment('cc')}
        >
          {t('btnPayNow')}
          <RightOutlined />
        </Button>
      </div>
      }

      {!!finish &&
      <Button
        type="primary"
        className="margin-sm-top"
        onClick={finish}
      >
        {t('btnFinish')}
      </Button>
      }
    </div>
  );
};