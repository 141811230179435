import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Context } from 'state/store';

import orderAction from "state/actions/orderAction";
import sourceAction from "state/actions/sourceAction";
import ButtonService from "components/buttonService";
import Styles from './style.module.scss';

export default function Home() {
  const { t } = useTranslation();
  const history = useHistory();
  const [state, dispatch] = useContext(Context);
  const {isLogined} = state.userReducer;

  const service = [
    { target: '/infinity/package-plan', login: false, bg: 'dst-infinity.png' },
    { target: '/mobi/package-plan', login: false, bg: 'dst-mobi.png' },
    { target: '/freedom/package-plan', login: false, bg: 'dst-freedom.png' },
    { target: '/easi/package-plan', login: false, bg: 'dst-easi.png' },
    { target: '/easi-recharge/customer-info', login: true, bg: 'dst-easi-recharge.png' },
    { target: '/tourist/package-plan', login: false, bg: 'dst-easi-tourist.png'  },
    { target: '/mobi-5g/package-plan', login: true, bg: 'dst-mobi-5g.png' },
    { target: '/freedom-5g/package-plan', login: true, bg: 'dst-freedom-5g.png' },
    //{ target: '/wifi-pin/customer-info', login: false, bg: 'dst-no-image.png' },
  ]

  const target = (link) => {
    orderAction.reset(dispatch);
    sourceAction.resetOfferings(dispatch);
    history.push(link)
  }

  return (
    <div className="container padding-large padding-rm-left padding-rm-right">
      <h3 className={`${Styles.serviceTitle} text-center margin-md-top margin-sm-bottom`}>{t('lblServices')}</h3>
      
      <div className={Styles.serviceGroup}>
      {service.map((obj, key) => 
        !obj.login
          ? <ButtonService key={key} {...obj} onClick={(val) => target(val)} />
          : (isLogined ? <ButtonService key={key} {...obj} onClick={(val) => target(val)} /> : null)
      )}
      </div>
    </div>
  );
}
