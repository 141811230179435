import React, { useContext, useEffect, useState } from 'react';
import { Button, Input, notification } from 'antd';
import { CheckCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Context } from 'state/store';
import sourceAction from 'state/actions/sourceAction';
import systemAction from "state/actions/systemAction";
import orderAction from 'state/actions/orderAction';

export default function ReviewReferral({
  customerInfo,
  plan,
  referralCode,
  setReferralCode,
  errorMessage
}) {
  const { t } = useTranslation();
  const [, dispatch] = useContext(Context);
  const [isValid, setIsValid] = useState(false);
  const [inlineMessage, setInlineMessage] = useState('');
  const [rewardMessage, setRewardMessage] = useState('');

  const showNotif = (error, message) => {
    notification.open({
      message: error ? 'Sorry...' : 'Success',
      description: message,
      icon: error
        ? <WarningOutlined style={{ color: 'red' }} />
        : <CheckCircleOutlined style={{ color: 'green' }} />
    });
  }

  const validateReferral = () => {
    if(!inlineMessage) {
      systemAction.startLoading(dispatch)
      sourceAction.validateReferral(dispatch, referralCode, customerInfo, plan)
        .then((result) => {
          delete result.first_name
          orderAction.referral(dispatch, result)
          setIsValid(true)
          showNotif(false, 'Referral is valid')
          setRewardMessage(result.reward.display_message)
          systemAction.stopLoading(dispatch)
        })
        .catch((e) => {
          setRewardMessage('')
          showNotif(true, e)
          systemAction.stopLoading(dispatch)
        })
    }
  }

  const resetData = () => {
    orderAction.referral(dispatch, null)
    setReferralCode(null)
    setIsValid(false)
    setRewardMessage('')
  }

  const watchReferralCode = () => {
    const rafMobileLine = /^673\d+$/
    const msisdn = customerInfo.customerDetail.mobile_phone
    const serviceLine = customerInfo.customerDetail.phone
    setRewardMessage('')

    if(rafMobileLine.test(referralCode)) {
      setInlineMessage('Please insert without 673')
    } else if(referralCode === msisdn.replace("673", "")) {
      setInlineMessage('Can\'t use your ' + t('inputMobile'))
    } else if(referralCode === serviceLine) {
      setInlineMessage('Can\'t use your ' + t('inputPhoneHome'))
    } else {
      setInlineMessage('')
    }
  }
  useEffect(watchReferralCode, [referralCode]);

  const watchIsValid = () => {
    if(isValid) {
      setInlineMessage('')
    }
  }
  useEffect(watchIsValid, [isValid])

  const watchErrorMessage = () => {
    setInlineMessage(errorMessage.referral)
  }
  useEffect(watchErrorMessage, [errorMessage])
  
  return (
    <div className="width-full">
      <div className="flex">
        <Input
          size="large"
          placeholder={t('inputMobile') + ' / ' + t('inputPhoneHome')}
          minLength={6}
          maxLength={10}
          onChange={e => setReferralCode(e.target.value.replace(/[^0-9]/gi, ''))}
          value={referralCode}
          disabled={isValid}
        />
        <Button
          size="large"
          type="primary"
          className="margin-rm"
          onClick={() => isValid ?  resetData() : validateReferral() }
          disabled={!!inlineMessage}
        >
          {isValid ? 'Reset' : 'Validate'}
        </Button>
      </div>
      {!!inlineMessage &&
        <p className="error-text">
          {inlineMessage}
        </p>
      }
      {!!rewardMessage &&
        <p className="reward-text">
          {rewardMessage}
        </p>
      }
    </div>
  );
};