const scrollToClass = (className) => {
  setTimeout(() => {
    const yOffset = -150
    const selector = className
    const element = document.getElementsByClassName(selector)

    let i = 0
    let target = 0
    while (element[i]) {
      const nextTarget = element[i].getBoundingClientRect().top + window.pageYOffset + yOffset
      if(target == 0 || nextTarget < target) target = nextTarget
      i++
    };

    if(target !== 0) {
      window.scrollTo({top: target, behavior: 'smooth'});
    }
  }, 100)
};

export default scrollToClass;
