import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Row, Col, Collapse, Divider, Button } from 'antd';
import { GlobalOutlined, LeftOutlined, UpCircleOutlined, DownCircleOutlined } from '@ant-design/icons';

import { addSummary, removeSummary } from "helpers/buildSummary";
import { Context } from "state/store";
import { def } from "config/const";
import { env } from "config/const";
import * as _ from 'lodash';
import currency from "helpers/currency";
import api from "helpers/api";
import sourceAction from "state/actions/sourceAction";
import systemAction from "state/actions/systemAction";
import orderAction from "state/actions/orderAction";
import scrollToClass from "helpers/scrollToClass";
import StepIndicator from "components/stepIndicator";
import PanelTimeline from "components/panelTimeline";
import CardPackage from "components/cardPackage";
import Summary from "components/summary";
import ObjectValidator from "helpers/objectValidator";
import OrderType from "components/orderType";
import Device from "components/device";
import useCheckMark from "hook/useCheckMark";
import deviceSort from "helpers/deviceSort";
import getRangePrice from "helpers/getRangePrice";
import mainThumbnail from "helpers/mainThumbnail";
import noImage from "assets/images/no-image.png";
import Sticky from "sticky-js";
import icEffect from 'helpers/icEffect';

const { Panel } = Collapse;
const customerType = "RES";
const orderTypeField = {
  order_type: {
    yes: 'Port-IN',
    no: 'New Order'
  },
  phone: 'required',
}


export default function PackagePlan() {
  const { t } = useTranslation();
  const history = useHistory();
  const [state, dispatch] = useContext(Context);
  const {bookingInventory} = useCheckMark();
  const {orderStep, orderType, bookedId, packagePlan, customerInfo} = state.orderReducer;
  const {offerings} = state.sourceReducer;
  const {allowPage} = state.systemReducer;
  const [summary, setSummary] = useState([]);
  const [deviceGroup, setDeviceGroup] = useState({});
  const [promoGroup, setPromoGroup] = useState([]);
  const [errorMessage, setErrorMessage] = useState({});
  const [selectedPackage, setSelectedPackage] = useState([]);
  const [selectedPromo, setSelectedPromo] = useState(null);
  const [selectedParent, setSelectedParent] = useState('');
  const [selectedChild, setSelectedChild] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState({});
  const [deviceActive, setDeviceActive] = useState([]);
  const [mainActive, setMainActive] = useState([]);
  const [addonGroup, setAddonGroup] = useState({});
  const [addonActive, setAddonActive] = useState([]);
  const [selectedAddon, setSelectedAddon] = useState({});
  const [modalOrderType, setModalOrderType] = useState(true);
  const [modalDevice, setModalDevice] = useState(false);
  
  const steps = [t('step1'), t('step2'), t('step3')];

  const propsOrderType = {
    field: orderTypeField,
    usedProvider: [1, 2, 3],
    modalOrderType,
    setModalOrderType,
    submitOrderType: val => submitOrderType(val),
    orderType,
    customerType
  }

  const submitOrderType = value => {
    const nationality = icEffect.nationality(value.identity_number)
    let customerDetail = {identity_number: value.identity_number,
      nationality: nationality,
      salutation: "Mr"
    }
    orderAction.type(dispatch, value.order_type)
      .then(() => {
        // set mobile phone to global state
        if(value.order_type === orderTypeField.order_type.yes) {
          if(value.phone != null) {
            customerDetail = {...customerDetail,
              phone: value.phone
            }
          }
          orderAction.previousProvider(dispatch, value.previous_provider_id);
        }
        orderAction.customerInfo(dispatch, {...customerInfo, customerDetail})
        setModalOrderType(false);
      })
      .catch(err => console.log(err))
  }

  const getPromotionMemberItem = id => {
    return api.post('order_portal/get_promotion_member_item', {bundle_id: id})
  }
  
  // effect after click package
  const selectPackage = value => {
    systemAction.startLoading(dispatch)
    // unselect device
    setSelectedParent('');
    setSelectedChild([]);
    setSelectedDevice({});
    setSelectedAddon({});
    
    // unselect prmo
    setSelectedPromo({});

    let tmpSummary = [];
    let selected = selectedPackage;
    let exist = selected.find(obj => obj.id === value.id);

    if(!!exist) {
      // unselect
      selected = selected.filter(obj => obj.id !== value.id);
      setSelectedPackage(selected);
      setSummary(
        removeSummary(value.id, summary)
      );
      systemAction.stopLoading(dispatch)
    } else {
      // select
      value['quantity'] = 1;

      // single select 
      selected = [ value ];

      setSelectedPackage(selected);
      tmpSummary = [{
        ...value,
        isCount: true,
        group: def.group.main
      }];

      sourceAction.dependencyCatalog(dispatch, state.orderReducer, value.id, value.id)
        .then(async result => {
          // get bundle contract
          let bundleContractId = result.bundle_contract.length > 0
            ? result.bundle_contract[0].id
            : null;
          orderAction.bundleContract(dispatch, bundleContractId);

          // get member
          let child = [];
          let member = result.member_item
          member.forEach(obj => {
            if(obj.tag_id !== def.groupTag.internet) {
              child.push({
                ...obj
              })
            }
          });

          // get deposit
          let deposit = result.deposit
          if(deposit != null) {
            deposit.forEach(obj => {
              tmpSummary.push({
                ...obj,
                name: 'DEPOSIT',
                isCount: true,
                item_id: null,
                group: def.group.other
              })
            });
          }

          // sub item of main
          tmpSummary[0]['child'] = child;

          // get relation
          let relation = typeof result.relation === 'object'
            ? Object.values(result.relation)
            : result.relation

          // populate relation
          await Promise.all(
            relation.map(async obj => {
              if(def.groupTag.voice.includes(obj.tag_id)){
                tmpSummary.push({
                  ...obj,
                  group: def.group.voice,
                  isCount: true
                })
              } else if(def.groupTag.device.includes(obj.tag_id)){
                tmpSummary.push({
                  ...obj,
                  group: def.group.device,
                  isCount: true
                })
              } else if(def.groupTag.promo.includes(obj.tag_id)) {
                
                const {data} = await getPromotionMemberItem(obj.id)
                let promo = data.member_item;

                tmpSummary.push({
                  ...obj,
                  child: promo,
                  group: def.group.promo,
                  isCount: true
                })
              } else {
                tmpSummary.push({
                  ...obj,
                  group: def.group.other,
                  isCount: true
                })
              }
            })
          )

          setSummary(
            addSummary(tmpSummary)
          );

          let addon = {};
          if (selected.length === 1  && typeof selected[0].devices['ADDON'] !== 'undefined') {
            addon = selected[0].devices['ADDON'];
          }
          

          setAddonGroup(
            selected.length === 1 && !!addon
              ? addon
              : {}
          );

          // get device group by package mamber
          let device = {}
          if (selected.length === 1 && typeof selected[0].devices !== 'undefined'){
            Object.keys(selected[0].devices).forEach(key => {
              if(key !== 'ADDON'){
                device[key] = selected[0].devices[key];
              }
            })
          }

        
          
          // get device group by package mamber
          setDeviceGroup(
            selected.length === 1 && !!device
              ? deviceSort(device)
              : {}
          );


          // get promo group by package mamber
          setPromoGroup(
            selected.length === 1 && !!selected[0].mobi_promo
              ? selected[0].mobi_promo
              : []
          );

          systemAction.stopLoading(dispatch)
          
        })
        .catch(error => {
          console.log(error)
          systemAction.stopLoading(dispatch)
        })
    }

    
    
    
  }

  const selectDeviceGroup = (parent, list = []) => {
    if(selectedParent !== parent) {
      setSelectedParent(parent);
      setSelectedChild(list);
      if(!!packagePlan && packagePlan.device != null) {
        if(!!packagePlan.device.bundle_name){
          selectDevice(packagePlan.device);
        }else{
          setModalDevice(true);
        }
      }else{
        setModalDevice(true);
      }
      
    } else {
      if(!!packagePlan && packagePlan.device){
        packagePlan.device = null;
      }
      
      // unselect 
      setSelectedParent('');
      setSelectedChild([]);
      setSelectedDevice({});

      // pop from summary
      setSummary(() => {
        let noDevice = removeSummary(def.group.device, summary, 'group')
            noDevice = removeSummary(def.group.promo, noDevice, 'group')
        return removeSummary(def.group['device deposit'], noDevice, 'group')
      });
    }
  }
  
  const selectDevice = async (data) => { 
    systemAction.startLoading(dispatch)
    setModalDevice(false);  
    // select
    setSelectedDevice(data);

    let tmpSummary = [{
      ...data,
      isCount: true,
      group: def.group.device
    }]

    // get promo
    const promo = await sourceAction.promoBundle(dispatch, state.orderReducer, selectedPackage[0].id, data.bundle_item_id)
    if(!!promo && promo.length > 0) {
      promo.map(obj =>
        tmpSummary.push({
          ...obj,
          isCount: true,
          group: def.group.promo,
        })
      )
    }

    const dependency = await sourceAction.dependencyCatalog(dispatch, state.orderReducer, data.bundle_item_id)
    // get relation
    if(dependency.relation != null){
      dependency.relation.map(obj =>
        tmpSummary.push({
          ...obj,
          isCount: true,
          group: def.group.device,
        })
      );
    }

    // get device deposit
    if(dependency.deposit != null){
      dependency.deposit.forEach(obj => {
        tmpSummary.push({
          ...obj,
          isCount: true,
          name: t('lblSummaryDeviceDeposit'),
          group: def.group['device deposit'],
        })
      });
    }
    
    setSummary(tempSummary => {
      // remove some item before add summary
      let rmSummary = removeSummary(def.group.device, tempSummary, 'group')
          rmSummary = removeSummary(def.group.promo, rmSummary, 'group')
          rmSummary = removeSummary(def.group['device deposit'], rmSummary, 'group')

      // push to summary
      return addSummary(tmpSummary, rmSummary)
    });
    systemAction.stopLoading(dispatch)
  }

  const selectPromo = async (data) => {
    if(selectedPromo.item_id !== data.item_id) {
      // select promo member
      let member = await api.post('order_portal/get_promotion_member_item', {bundle_id: data.item_id})
          member = member.data.member_item

      let child = [];
      if(member.length > 0) {
        member.map(item => 
          child.push({
            ...item,
          })
        )
      }

      let tmpSummary = {
        ...data,
        child,
        isCount: true,
        group: def.group.promo
      }

      setSelectedPromo(data)

      setSummary(
        addSummary(
          [tmpSummary], 
          removeSummary(def.group.promo, summary, 'group')
        )
      );
    } else {
      // unselect
      setSelectedPromo({})

      // pop from summary
      setSummary(
        addSummary(
          removeSummary(def.group.promo, summary, 'group')
        )
      );
    }
  }

  const selectAddon = async (data) => {
    
    if(selectedAddon !== data) {
      // select
      setSelectedAddon(data);

      let tmpSummary = [{
        ...data,
        isCount: true,
        name: t('lblSummaryAddon'),
        group: def.group.addon,
      }]
      

      setSummary(tempSummary => {
        // remove some item before add summary
        let rmSummary = removeSummary(def.group.addon, tempSummary, 'group')

        // push to summary
        return addSummary(tmpSummary, rmSummary)
      });
    } else {
      if(!!packagePlan && packagePlan.addon){
        packagePlan.addon = null;
      }
      
      // unselect 
      setSelectedAddon({});

      // pop from summary
      setSummary(() => {

        return removeSummary(def.group.addon, summary, 'group')
      });
    }
    
  }

  const getThumb = (data) => {
    let thumb = '';

    if(!thumb && !!data && data.length) {
      thumb = _.startsWith(data[0].image_file, 'https://')
        ? data[0].image_file
        : env.bucket + data[0].image_file;
    }

    return thumb;

  }

  const validate = () => {
    const schema = {
      selectedPackage: { require: () => !!selectedPackage.length ? '' :'Please choose plan' },
    }
    const error = ObjectValidator(schema)
    setErrorMessage(error)
    scrollToClass('error-text')
    return error
  }

  const sendOrderStep = async (data) => {
    let orderDetail = [];
    
    // order_detail
    data.forEach(obj => {
      if(!!obj.item_id) {
        orderDetail.push(
          { item_id: obj.item_id, quantity: obj.quantity}
        )
      }
    })

    let order = {
      order_step: {
        order_type: orderType,
        customer_type: customerInfo.customer_type,
        order_detail: JSON.stringify(orderDetail)
      }
    }

    dispatch({ type: "ORDER_STEP", payload: order })
    if(!orderStep || !orderStep.step_id) {
      let res = await api.post('order_portal/create_order_step', { order })
      if(!!res.data) order['step_id'] = res.data
    } else {
      order['step_id'] = orderStep.step_id
      await api.post('order_portal/update_order_step', { order })
    }
  }

  const submit = async () => {      
    if(!!validate()) return;
    
    let newStep = "/infinity/customer-info";
    let newAllowStep = [...allowPage, newStep];
    let packagePlan = {
      device: !!selectedDevice ? selectedDevice : null,
      plan: [selectedPackage[0]],
      addon: !!selectedAddon? selectedAddon : null,
      promo: !!selectedPromo ? selectedPromo : null
    }

    // mark item device stock
    const booked = await bookingInventory(selectedDevice, bookedId, customerInfo);
    if(!booked) return;

    systemAction.startLoading(dispatch);
    sendOrderStep(summary);
    orderAction.summary(dispatch, summary);
    orderAction.packagePlan(dispatch, packagePlan);
    systemAction.allowPage(dispatch, newAllowStep);
    history.push(newStep)
  }

  const onDeviceGroupChange = () => {
    if(!!packagePlan && packagePlan.device) {
      Object.keys(deviceGroup).map((key, index) =>
        Object.keys(deviceGroup[key]).forEach((parent, k) => {
          const match = deviceGroup[key][parent].find(d => d.bundle_item_id === packagePlan.device.bundle_item_id)
          if(!!match) {
            selectDeviceGroup(parent, deviceGroup[key][parent])
            setDeviceActive([index])
          }
        })
      )
    }
  }
  
  const onOfferingsLoad = () => {
    if(!!packagePlan && packagePlan.plan.length) {
      offerings.forEach((group, groupKey) => {
        group.member.forEach((member, memberKey) => {
          const match = packagePlan.plan.find(i => i.id === member.id)
          if(!!match) {
            selectPackage(member)
            setMainActive([String(groupKey)])
          }
        })
      })
    }
  }

  const onOrderTypeUpdate = () => {
    if(!!orderType && offerings.length == 0) {
      systemAction.startLoading(dispatch)
      sourceAction.offerings(dispatch, state.orderReducer)
        .finally(() => {
          systemAction.stopLoading(dispatch)
        })
    }
  }

  const onAddonLoad = () => {
   if(!!packagePlan && packagePlan.addon != null) {
      Object.keys(addonGroup).map((key, index) => {
        Object.keys(addonGroup[key]).forEach((parent, k) => {
          if(addonGroup[key][parent].bundle_item_id === packagePlan.addon.bundle_item_id) {
            selectAddon( addonGroup[key][parent])
            setAddonActive([index])
          }
        })
      })
    }
  }

  
  const onLoad = () => {
    new Sticky('.sticky');

    orderAction.customerInfo(dispatch, {...customerInfo, customerType});
    systemAction.allowPage(dispatch, ['/infinity/package-plan']);

    if(!bookedId) {
      orderAction.getBookedId(dispatch);
    }
  }

  const propsDeviceModal = {
    show: modalDevice,
    setShow: setModalDevice
  }
  
  useEffect(onLoad, []);
  useEffect(onOrderTypeUpdate, [orderType]);
  useEffect(onOfferingsLoad, [offerings]);
  useEffect(onDeviceGroupChange, [deviceGroup]);
  useEffect(onAddonLoad, [addonGroup]);

  return (
    <>
    <div className="container" data-sticky-container>
      <StepIndicator steps={steps} current={0} />

      <Row gutter={24} className="margin-bottom">
        <Col xs={24} md={16}>
          {selectedParent &&
            <Device devices={selectedChild} selectDevice={selectDevice} />
          }

          <PanelTimeline title={t('lblSectionProductInternet')}>

            {!!errorMessage.selectedPackage && !selectedPackage.length &&
              <p className="error-text">{errorMessage.selectedPackage}</p>
            }

            <Collapse
              ghost
              accordion
              className="accordion"
              expandIconPosition="end"
              activeKey={mainActive}
              onChange={(res) => setMainActive([res])}
            >
              {offerings.map((group, groupKey) => 
                <Panel header={group.name.toUpperCase()} key={groupKey}>
                  {group.member.map((member, memberKey) =>
                    <CardPackage
                      key={memberKey}
                      active={!!selectedPackage.find(obj => {
                        //setMainActive([groupKey])
                        return obj.id === member.id
                      })}
                      onClick={() => selectPackage(member)}
                    >
                      <h6 className="text-brand text-bold margin-rm flex flex-between">
                        <span>{member.name}</span>
                        <span>{member.price}</span>
                      </h6>
                      <Divider className="margin-sm-top margin-sm-bottom" />
                      <div className="flex">
                        <div className="flex-auto">
                          {member.spec &&
                          <React.Fragment>
                            {member.spec.download ? <span className="padding-right"><DownCircleOutlined /> {member.spec.download} </span> : ''}
                            {member.spec.upload ? <span className="padding-right"><UpCircleOutlined /> {member.spec.upload} </span> : ''}
                            {member.spec.quota ? <span className="padding-right"><GlobalOutlined /> {member.spec.quota} </span> : ''}
                            </React.Fragment>
                          }
                        </div>
                      </div>
                    </CardPackage>
                  )}
                </Panel>
              )}
            </Collapse>
          </PanelTimeline>

          <PanelTimeline title={t('lblSectionDevice') +' '+ t('lblSectionOptional')}>
            {!!deviceGroup &&
            <Collapse
              ghost
              accordion
              className="accordion margin-bottom"
              expandIconPosition="end"
              activeKey={deviceActive}
              onChange={(res) => setDeviceActive([res])}
            >
              {Object.keys(deviceGroup).map((key, index) =>
                <Panel header={key.toUpperCase()} key={index}>
                  <Row gutter={16} className="margin-bottom padding-sm-left padding-sm-right">
                  {Object.keys(deviceGroup[key]).map((parent, k) =>
                    <Col xs={24} md={8} key={k} className="padding-rm">
                      <CardPackage
                        active={parent === selectedParent}
                        onClick={() => selectDeviceGroup(parent, deviceGroup[key][parent])}
                      >
                        <div className="text-center">
                          <img
                            alt="thumb"
                            className="img-thumb"
                            src={mainThumbnail(deviceGroup[key][parent])}
                            onError={({target}) => target.src = noImage}
                          />
                        </div>
                        <h5 className="text-brand text-bold margin-sm-top margin-sm-bottom">
                          {parent}
                        </h5>
                        <p className="margin-rm">
                          { getRangePrice(deviceGroup[key][parent]) }
                        </p>
                      </CardPackage>
                    </Col>
                  )}
                  </Row>
                </Panel>
              )}
              {selectedParent &&
                <Device devices={selectedChild} selectDevice={selectDevice} {...propsDeviceModal} />
              }
            </Collapse>
            }
          </PanelTimeline>
          <PanelTimeline title={t('lblSectionAddon') +' '+ t('lblSectionOptional')} >
          {!!addonGroup &&
            <Collapse
              ghost
              accordion
              className="accordion margin-bottom"
              expandIconPosition="end"
              activeKey={addonActive}
              onChange={(res) => setAddonActive([res])}
            >
              {Object.keys(addonGroup).map((key, index) =>
                <Panel header={key.toUpperCase()} key={index}>
                  <Row gutter={16} className="margin-bottom padding-sm-left padding-sm-right">
                  {Object.keys(addonGroup[key]).map((parent, k) =>
                    <Col xs={24} md={8} key={parent} className="padding-rm">
                      <CardPackage
                        active={addonGroup[key][parent] == selectedAddon}
                        onClick={() => selectAddon(addonGroup[key][parent])}
                      >
                        <h5 className="text-brand text-bold margin-sm-top margin-sm-bottom">
                          {addonGroup[key][parent]['bundle_name']}
                        </h5>
                        <p className="margin-rm">
                          { currency(addonGroup[key][parent]['price_amount']) }
                        </p>
                      </CardPackage>
                    </Col>
                  )}
                  </Row>
                  
                </Panel>
              )}
            </Collapse>
            }
          </PanelTimeline>

          <PanelTimeline title="Promo" noLine>
            {!!promoGroup &&
            <Row gutter={16} className="margin-bottom padding-sm-left padding-sm-right">
              {promoGroup.map(item =>
                <Col xs={24} md={12} key={item.item_id} className="padding-rm">
                  <CardPackage
                    active={item.item_id === selectedPromo.item_id}
                    onClick={() => selectPromo(item)}
                  >
                    <h5 className="text-brand text-bold margin-sm-bottom">
                      {item.name}
                    </h5>
                    <p className="margin-rm">$ {item.price}</p>
                  </CardPackage>
                </Col>
              )}
            </Row>
            }
          </PanelTimeline>

        </Col>
            
        <Col xs={24} md={8}>
          <div className="sticky" data-margin-top="90">
            <Summary
              data={summary}
              submit={() => submit()}
              removeDevice={() => selectDeviceGroup(selectedParent)}
              mainLabel={t('lblSummaryMainInternet')}
            />
          </div>
        </Col>
        
        <Col xs={24}>
          <Button className="margin-top" type="default" onClick={() => history.goBack()}>
            <LeftOutlined />
            {t('btnBack')}
          </Button>
        </Col>
        
      </Row>
    </div>
    <OrderType {...propsOrderType} />
    </>
  );
}
