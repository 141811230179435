import React, { createRef, useContext, useEffect, useState } from "react";
import { Carousel, Button, Modal } from 'antd';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import Magnifier from "react-magnifier";
import Style from './style.module.scss';
import * as _ from 'lodash';
import { def, env } from "config/const";
import { Context } from "state/store";
import currency from "helpers/currency";
import getChildFilter from "helpers/getChildFilter";
import sourceAction from "state/actions/sourceAction";
import noImage from "assets/images/no-image.png";
import { useTranslation } from "react-i18next";

export default function Device({ devices, selectDevice, show, setShow }) {
  let slider = createRef();
  const [state, dispatch] = useContext(Context);
  const { t } = useTranslation();
  const {customerInfo, packagePlan} = state.orderReducer;
  const [images, setImages] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState({});
  const [selectedFilter, setSelectedFilter] = useState({});
  const [filter, setFilter] = useState({});
  const [isHasStock, setIsHasStock] = useState(false);
  const [stockList, setStockList] = useState({});

  const changeFilter = (key, item) => {
    let sf = selectedFilter;
        sf[key] = item;

    setSelectedFilter({...sf});
  }

  const checkList = (key, item) => {
    let tmpFilter = {...selectedFilter}
        tmpFilter[key] = item

    return !!_.find(devices, {details: {attribute: tmpFilter}})
  }

  const onSelectedFilter = () => {
    let filtered = _.find(devices, {
      details: {
        attribute: selectedFilter
      }
    })

    let device = !!filtered ? filtered : devices[0];
        device = {
          ...device,
          bundle_name: device.bundle_name,
          descriptions: device.item_description,
          price_amount: currency(device.price_amount)
        }
    setSelectedDevice(device);
  }

  const onSelectedDevice = () => {
    if(Object.keys(selectedDevice).length) {
      if(!!selectedDevice.images) {
        let img = [];
        selectedDevice.images.map(obj =>
          img.push(
            _.startsWith(obj.image_file, 'https://')
              ? obj.image_file
              : env.bucket + obj.image_file
          )
        );
        setImages(img);
      }

      let isHasStock = false;
      const customerType = customerInfo.customerType;
      selectedDevice.sku_code.map((item) => isHasStock = stockList[item] > 0);
      setIsHasStock(def.byPassStock.includes(customerType) ? true : isHasStock);
    }
  }

  const onLoadStockList = () => {      
    let filters = getChildFilter(devices);
    let deviceFilter = {}

    if(!!filters['Color']) {
      if(!!filters['Memory capacity (RAM, Internal)']) {
        deviceFilter = {
          'Color': filters['Color'][0],
          'Memory capacity (RAM, Internal)': filters['Memory capacity (RAM, Internal)'][0]
        }
      } else {
        deviceFilter = {
          'Color': filters['Color'][0]
        }
      }
    }
    setSelectedFilter(deviceFilter);
    setFilter(filters)

    if(!!packagePlan && packagePlan.device) {
      const deviceInfo = devices.find(d => d.bundle_item_id === packagePlan.device.bundle_item_id)
      // check stock
      if(!!deviceInfo) {
        selectDevice(deviceInfo)
      }
    }
  }

  const onLoad = () => {
    const customerType = customerInfo.customerType;
    if(def.byPassStock.includes(customerType)) {
      setStockList({})
    } else {
      sourceAction.stockItem(dispatch, devices, customerInfo)
        .then((data) => setStockList(data))
    }
  }

  useEffect(onLoad, [devices]);
  useEffect(onSelectedFilter, [selectedFilter]);
  useEffect(onLoadStockList, [stockList]);
  useEffect(onSelectedDevice, [selectedDevice]);

  return (
    <Modal
      title={<h4>{t("deviceModalTitle")}</h4>}
      width={1000}
      closable
      maskClosable={true}
      onCancel={() => setShow(false)}
      open={show}
      centered
      okButtonProps={{ style: { display: 'none' } }}
    >
      <div className="margin-lg-bottom flex flex-wrap flex-center" id="device-carousel">
        <div className={Style.carouselBlock}>
          {images.length > 0 &&
            <Button className={Style.carouselPrev} type="link" icon={<LeftOutlined />} onClick={() => slider.prev()} />
          }

          {images.length > 0
            ? <Carousel
                ref={node => slider= node}
                className={Style.carousel}
                dots={{className:Style.carouselDots}}
                autoplaySpeed={5000}
                pauseOnHover
                pauseOnFocus
              >
                {images.map((item, key) =>
                  <Magnifier
                    key={key}
                    src={item}
                    width={240}
                    zoomFactor={2}
                  />
                )}
              </Carousel>
            : <img
                width="180"
                alt="noImage"
                className="padding-top"
                src={noImage}
              />
          }

          {images.length > 0 &&
            <Button className={Style.carouselNext} type="link" icon={<RightOutlined />} onClick={() => slider.next()} />
          }

          <div className={Style.carouselAction}>
          </div>
        </div>

        <div className={Style.deviceDetail}>
          <h4 className="margin-top">{selectedDevice?.bundle_name}</h4>

          <label>Price: </label>
          <h5 className={Style.price}>{selectedDevice?.price_amount}</h5>

          {/* <label>Varians: </label>
          <div className="margin-sm-bottom">
            {devices.map(item =>
              <Button
                type="default"
                shape="round"
                size="small"
                key={item.sku_code}
                onClick={() => setSelectedDevice(item)}
                className={[
                  Style.btnVariant,
                  item.sku_code === selectedDevice.sku_code ? Style.btnSelected : null
                ]}
              >
                {item.bundle_name}
              </Button>
            )}
          </div> */}

          {Object.keys(filter).map(key =>
          <React.Fragment key={key}>
            <label>{key}: </label>
            <div className="margin-sm-bottom">
              {filter[key].map(item =>
                <Button
                  type="default"
                  shape="round"
                  size="small"
                  key={item}
                  onClick={() => changeFilter(key, item)}
                  disabled={!checkList(key, item)}
                  className={[
                    Style.btnVariant,
                    selectedDevice?.details?.attribute[key] === item ? Style.btnSelected : null
                  ]}
                >
                  {!checkList(key, item)
                    ? item + ' (SOLD OUT)'
                    : item
                  }
                </Button>
              )}
            </div>
          </React.Fragment>
          )}

          <label>Description: </label>
          <div>
            {selectedDevice?.item_description}
          </div>

          <Button
            type="primary"
            className="margin-top"
            disabled={!isHasStock}
            onClick={() => selectDevice(selectedDevice)}
          >
            {isHasStock
              ? 'Choose'
              : 'SOLD OUT'
            }
          </Button>
        </div>
      </div>
    </Modal>
  );
};