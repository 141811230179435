import React from 'react';
import { useTranslation } from "react-i18next";
import { Descriptions } from 'antd';

export default function ReviewCustomerInfo({customerInfo}) {
  const { t } = useTranslation();
  const customerDetail = customerInfo.customerDetail;
  const inputIdentityLabel = t('inputIdentityNumber').split('/');
  
  return (
    <>
      <Descriptions title={t('lblDataCustomer')} size="small" column={1} bordered>
        <Descriptions.Item label={t('inputFullname')}>
          {customerDetail.salutation} {customerDetail.full_name}
        </Descriptions.Item>
        <Descriptions.Item label={
          <>
            {inputIdentityLabel[0] + '/' + inputIdentityLabel[1] + '/'}<br />
            {inputIdentityLabel[2]}
          </>
        }>
          {customerDetail.identity_number}
        </Descriptions.Item>
          <Descriptions.Item label={t('inputEmail')}>
          {customerDetail.email}
        </Descriptions.Item>
        {customerDetail.mobile_phone &&
          <Descriptions.Item label={t('inputMobile')}>
            {customerDetail.mobile_phone}
          </Descriptions.Item>
        }
        {customerDetail.phone &&
          <Descriptions.Item label={t('inputPhoneHome')}>
            {customerDetail.phone}
          </Descriptions.Item>
        }
        {customerDetail.work_phone &&
          <Descriptions.Item label={t('inputPhoneOffice')}>
            {customerDetail.work_phone}
          </Descriptions.Item>
        }
        {customerDetail.birth_date &&
          <Descriptions.Item label={t('inputDateOfBirth')}>
            {typeof customerDetail.birth_date === 'string'
              ? customerDetail.birth_date
              : customerDetail.birth_date.format('DD MMMM YYYY')
            }
          </Descriptions.Item>
        }
        {customerDetail.date_of_arrival &&
          <Descriptions.Item label={t('inputDateOfArrival')}>
            {typeof customerDetail.date_of_arrival === 'string'
              ? customerDetail.date_of_arrival
              : customerDetail.date_of_arrival.format('DD MMMM YYYY')
            }
          </Descriptions.Item>
        }
        <Descriptions.Item label={t('inputNationality')}>
          {customerDetail.nationality}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};