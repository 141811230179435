import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, message, Upload } from "antd";
import { UploadOutlined } from '@ant-design/icons';

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

export default function UploadImage({setImage, label}) {
  const { t } = useTranslation();
  const [name, setName] = useState(null);

  const handleChange = info => {
    // allow 2MB file image
    let file = info.fileList.pop()
    if(file.size < 10000000) {
      setName(file.name)
      getBase64(file.originFileObj,image =>
        setImage({
          src: image,
          ext: '.' + file.name.split('.').pop(),
          size: file.size
        })
      )
    } else {
      message.error(t('msgSystemTrouble'))
    }
  };

  return (
    <>
      <Upload
        showUploadList={false}
        beforeUpload={() => false}
        onChange={handleChange}
        accept="image/x-png,image/gif,image/jpeg" 
      >
        <Button type="primary" ghost>
          <UploadOutlined /> {label}
        </Button>
      </Upload>
      <span className="margin-sm-left">{name}</span>
    </>
  );
};