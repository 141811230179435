import React, { useEffect, useState } from "react";
import { Button, Card, List, Empty } from 'antd';
import { useTranslation } from "react-i18next";
import currency from "helpers/currency";
import Style from './style.module.scss';
import { def } from "config/const";

export default function Summary({
  data = [],
  submit,
  removeDevice,
  mainLabel = '',
  showQty = false,
  muted = false,
  removeAddon,
}) {
  const { t } = useTranslation();
  const [total, setTotal] = useState(0);
  const [main, setMain] = useState([]);
  const [voice, setVoice] = useState([]);
  const [device, setDevice] = useState([]);
  const [deviceDeposit, setDeviceDeposit] = useState([]);
  const [deliveryFee, setDeliveryFee] = useState([]);
  const [other, setOther] = useState([]);
  const [promo, setPromo] = useState([]);
  const [addon, setAddon] = useState([]);
  const [simCard, setSimCard] = useState([]);

  const toNumber = string => {
    return typeof string === 'string' ? Number(string.replace(/[^0-9.-]/g, "")) : string
  }
  
  const onDataLoad = () => {
    let sum = 0;
    data.forEach(elm => {
      let perItem = toNumber(elm.price) * toNumber(elm.quantity);
      sum = sum + perItem;

      if(elm.child) {
        elm.child.forEach(obj => {
          if(obj.isCount) sum = sum + (toNumber(obj.price) * toNumber(obj.quantity))
        })
      }
    });
    setTotal(sum);

    // main
    setMain( data.filter(obj => obj.group === def.group.main) )
    // voice
    setVoice( data.filter(obj => obj.group === def.group.voice) )
    // delivery fee
    setDeliveryFee( data.filter(obj => obj.group === def.group['delivery fee']) )
    // device
    setDevice( data.filter(obj => obj.group === def.group.device) )
    // device deposit
    setDeviceDeposit( data.filter(obj => obj.group === def.group['device deposit']) )
    // other
    setOther( data.filter(obj => obj.group === def.group.other) )
    // promo
    setPromo( data.filter(obj => obj.group === def.group.promo) )
    // addon
    setAddon( data.filter(obj => obj.group === def.group.addon) )
    // sim card
    setSimCard( data.filter(obj => obj.group === def.group.simCard) )
  }

  useEffect(onDataLoad, [data]);
  
  const ListSummary = ({obj, forceHideQty}) => {
    return (
      <>
        <li className={Style.listItem}>
          <h6 className="margin-rm">
            <span>{obj.name}</span>
            {showQty && !forceHideQty &&
              <span> (x{obj.quantity})</span>
            }
          </h6>
          <div className={obj.isCount ? Style.price : Style.priceMuted}>
            <h5 className="margin-rm-bottom margin-sm-left">
              {currency(toNumber(obj.price) * toNumber(obj.quantity), 0)}
            </h5>
          </div>
        </li>

        {obj.child &&
          <>
          {obj.child.map((child, key) =>
            <li className={Style.listItem} key={key}>
              <div>
                <h6 className={Style.child}>
                  {'- ' + child.name}
                </h6>
              </div>
              <div className={child.isCount ? Style.price : Style.priceMuted}>
              <h5 className="margin-rm-bottom margin-sm-left">{currency(toNumber(child.price) * toNumber(child.quantity), 0)}</h5>
              </div>
            </li>
          )}
          </>
        }
      </>
    )
  }

  return (
    <Card className={`${Style.summary} ${!!muted ? Style.muted : ''}`}>
      {!!muted ? (
        <div className="ant-descriptions-title">{t('lblDataSummaryPackage')}</div>
      ) : (
        <div className={Style.summaryTitle}>
          {t('lblSummary')}
        </div>        
      )}
      <div className={Style.summaryItem}>
        {data.length === 0 &&
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        }
        {data.length > 0 &&
          <ul className={Style.list}>
            {main.length > 0 &&
            <>
              <li className={Style.groupName}>{mainLabel}</li>
              {main.map((obj, key) =>
                <ListSummary obj={obj} key={key} />
              )}
            </>
            }

            {simCard.length > 0 &&
            <>
              <li className={Style.groupName}>
                <span>{t('lblSummarySimCard')}</span>
              </li>
              {simCard.map((obj, key) =>
                <ListSummary obj={obj} forceHideQty={true} key={key} />
              )}
            </>
            }

            {voice.length > 0 &&
            <>
              <li className={Style.groupName}>{t('lblSummaryVoice')}</li>
              {voice.map((obj, key) =>
                <ListSummary obj={obj} key={key} />
              )}
            </>
            }

            {deliveryFee.length > 0 &&
            <>
              <li className={Style.groupName}>{t('lblSummaryDeliveryFee')}</li>
              {deliveryFee.map((obj, key) =>
                <ListSummary obj={obj} key={key} />
              )}
            </>
            }

            {device.length > 0 &&
            <>
              <li className={Style.groupName}>
                <span>{t('lblSummaryDevice')}</span>
                {!muted &&
                  <div className={Style.removeDevice} onClick={() => removeDevice()}></div>
                }
              </li>
              {device.map((obj, key) =>
                <ListSummary obj={obj} key={key} />
              )}
            </>
            }

            {deviceDeposit.length > 0 &&
            <>
              <li className={Style.groupName}>{t('lblSummaryDeviceDeposit')}</li>
              {deviceDeposit.map((obj, key) =>
                <ListSummary obj={obj} forceHideQty={true} key={key} />
              )}
            </>
            }

            {addon.length > 0 &&
            <>
              <li className={Style.groupName}>
                <span>{t('lblSummaryAddon')}</span>
              </li>
              {addon.map((obj, key) =>
                <ListSummary obj={obj} forceHideQty={true} key={key} />
              )}
            </>
            }

            {other.length > 0 &&
            <>
              <li className={Style.groupName}>{t('lblSummaryOther')}</li>
              {other.map((obj, key) =>
                <ListSummary obj={obj} key={key} />
              )}
            </>
            }

            {promo.length > 0 &&
            <>
              <li className={Style.groupName}>Promo</li>
              {promo.map((obj, key) =>
                <ListSummary obj={obj} key={key} />
              )}
            </>
            }
          </ul>
          }
      </div>
      <div className={Style.summaryTotal}>
        <List
          itemLayout="horizontal"
        >
        <List.Item>
          <div>
            <h6 className="margin-rm">{t('lblSummaryTotal')}</h6>
          </div>
          <div className={Style.price}>
            <h4 className="margin-rm">{currency(total, 0)}</h4>
          </div>
        </List.Item>
        </List>
      </div>

      {!muted &&
        <div className={Style.summaryAction}>
          <Button type="primary" block onClick={() => submit()}>
            Next
          </Button>
        </div>
      }
    </Card>
  );
};