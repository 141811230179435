import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Row, Col, Collapse, Divider, Button, Switch, Modal, Form } from 'antd';
import { env } from "config/const";
import * as _ from 'lodash';
import currency from "helpers/currency";
import {
  PhoneOutlined,
  MailOutlined,
  GlobalOutlined,
  WifiOutlined,
  LeftOutlined,
  CheckOutlined,
  CloseOutlined,
  ConsoleSqlOutlined
} from '@ant-design/icons';

import { addSummary, removeSummary } from "helpers/buildSummary";
import { Context } from "state/store";
import { def } from "config/const";
import api from "helpers/api";
import sourceAction from "state/actions/sourceAction";
import systemAction from "state/actions/systemAction";
import orderAction from "state/actions/orderAction";
import scrollToClass from "helpers/scrollToClass";
import StepIndicator from "components/stepIndicator";
import PanelTimeline from "components/panelTimeline";
import CardPackage from "components/cardPackage";
//import QuantityEditor from "components/quantityEditor";
import Summary from "components/summary";
import ObjectValidator from "helpers/objectValidator";
import Device from "components/device";
import deviceSort from "helpers/deviceSort";
import getRangePrice from "helpers/getRangePrice";
import mainThumbnail from "helpers/mainThumbnail";
import noImage from "assets/images/no-image.png";
import useCheckMark from "hook/useCheckMark";
import useAutoNumber from "hook/useAutoNumber";
import Sticky from "sticky-js";
import OrderType from "components/orderType";
import icEffect from 'helpers/icEffect';

const { Panel } = Collapse;
const customerType = "MOBI-POSTPAID";
const network = "5G"
const orderTypeField = {
  order_type: {
    yes: 'Port-IN',
    no: 'New Order'
  },
  mobile_phone: 'required',
}

export default function PackagePlan() {
  const { t } = useTranslation();
  const history = useHistory();
  const {bookingInventory, bookingNumber} = useCheckMark();
  const {generateNumber} = useAutoNumber();
  const [state, dispatch] = useContext(Context);
  const {orderStep, orderType, customerInfo, bookedId, packagePlan, autoNumber } = state.orderReducer;
  const {offerings} = state.sourceReducer;
  const {allowPage} = state.systemReducer;
  const {user} = state.userReducer;
  const [summary, setSummary] = useState([]);
  const [deviceGroup, setDeviceGroup] = useState({});
  const [autoNumberValue, setAutoNumberValue] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});
  const [selectedPackage, setSelectedPackage] = useState([]);
  const [selectedParent, setSelectedParent] = useState('');
  const [selectedChild, setSelectedChild] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState({});
  const [mainActive, setMainActive] = useState([]);
  const [deviceActive, setDeviceActive] = useState([]);
  const [addonGroup, setAddonGroup] = useState({});
  const [addonActive, setAddonActive] = useState([]);
  const [selectedAddon, setSelectedAddon] = useState({});
  const [modalDevice, setModalDevice] = useState(false);
  const [modalOrderType, setModalOrderType] = useState(true);
  const [simCardGroup, setSimCardGroup] = useState({});
  const [simCardActive, setSimCardActive] = useState([]);
  const [selectedSimCard, setSelectedSimCard] = useState(null);
  const [deviceFlag, setDeviceFlag] = useState(false);
  const [esimFlag, setEsimFlag] = useState(false);
  
  const steps = [t('step1'), t('step2'), t('step3')];

  const propsOrderType = {
    field: orderTypeField,
    usedProvider: [1, 2, 4],
    modalOrderType,
    setModalOrderType,
    submitOrderType: val => submitOrderType(val),
    orderType,
    customerType
  }

  const submitOrderType = value => {
    const nationality = icEffect.nationality(value.identity_number)
    let customerDetail = {identity_number: value.identity_number,
      nationality: nationality,
      salutation: "Mr"
    }
    orderAction.type(dispatch, value.order_type)
      .then(() => {
        // set mobile phone to global state
        if(value.order_type === orderTypeField.order_type.yes) {
          setAutoNumberValue(true)
          if(value.mobile_phone != null) {
            
            customerDetail = {...customerDetail,
              mobile_phone: value.mobile_phone
            }
          }
          orderAction.previousProvider(dispatch, value.previous_provider_id);
        }
        orderAction.customerInfo(dispatch, {...customerInfo, customerDetail})
        setModalOrderType(false);
      })
      .catch(err => console.log(err))
  }

  const getDeliveryFee = () => {
    let params = {
      tag: ['130'],
      rep_id: user.rep_id,
      customer_type: customerInfo.customerType,
      sales_channel: 7,
      type: 'delivery-item'
    }

    console.log('getDeliveryFee')
    api.post('order_portal/get_order_catalog', params)
      .then(result => {
        if(result.data.length) {
          let res = result.data[0]

          setSummary(
            addSummary([{
              ...res,
              isCount: true,
              group: def.group["delivery fee"]
            }])
          );
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  // effect after click package
  const selectPackage = value => {
    systemAction.startLoading(dispatch)
    // unselect device
    setSelectedParent('');
    setSelectedChild([]);
    setSelectedDevice({});
    setSelectedAddon({});
    setSelectedSimCard(null);
    setDeviceFlag(false);
    setEsimFlag(false);

    let tmpSummary = [];
    let selected = selectedPackage;
    let exist = selected.find(obj => obj.id === value.id);

    if(!!exist) {
      // unselect
      selected = selected.filter(obj => obj.id !== value.id);
      setSelectedPackage(selected);
      setSummary(() => {
        let noDevice = removeSummary(def.group.device, summary, 'group')
            noDevice = removeSummary(def.group.promo, noDevice, 'group')
            noDevice = removeSummary(def.group.addon, noDevice, 'group')
            noDevice = removeSummary(def.group.simCard, noDevice, 'group')

        return removeSummary(def.group.main, noDevice, 'group')
      });
      systemAction.stopLoading(dispatch)
    } else {
      // select
      value['quantity'] = 1;

      // category number
      let name = value.name.toUpperCase();
      value['number_category'] = name.search('GOLDEN') !== -1 ? 'Golden' : 'Normal';

      // single select
      selected = [ value ];
      

      // single or multi select
      // if(orderType === 'Port-IN' || isLogined) {
      //   selected = [ value ];
      // } else {
      //   selected = [ ...selectedPackage, value ];
      // }

      setSelectedPackage(selected);
      tmpSummary = [{
        ...value,
        isCount: true,
        group: def.group.main
      }];

      sourceAction.dependencyCatalog(dispatch, state.orderReducer, value.id, value.id)
        .then(result => {
          // get bundle contract
          let bundleContractId = result.bundle_contract.length > 0
            ? result.bundle_contract[0].id
            : null;
          orderAction.bundleContract(dispatch, bundleContractId);

          // get member
          let child = [];
          let member = result.member_item
          member.forEach(obj => {
            if(obj.tag_id !== def.groupTag.internet) {
              child.push({
                ...obj
              })
            }
          });

          // get deposit
          let deposit = result.deposit
          if(deposit != null){
            deposit.forEach(obj => {
              child.push({
                ...obj,
                name: 'Deposit',
                isCount: true
              })
            });
          }
          
          // sub item of main
          tmpSummary[0]['child'] = child;
          
          // get relation
          let relation = typeof result.relation === 'object'
            ? Object.values(result.relation)
            : result.relation

          // populate relation
          relation.forEach(obj => {
            if(def.groupTag.device.includes(obj.tag_id)){
              tmpSummary.push({
                ...obj,
                group: def.group.device,
                isCount: true
              })
            } else {
              tmpSummary.push({
                ...obj,
                group: def.group.other,
                isCount: true
              })
            }
          });
          
          setSummary(
            addSummary(tmpSummary)
          );

          let addon = {};
          if (selected.length === 1  && typeof selected[0].devices['ADDON'] !== 'undefined') {
            addon = selected[0].devices['ADDON'];
          }

          setAddonGroup(
            selected.length === 1 && !!addon
              ? addon
              : {}
          );

          setSimCardGroup(selected[0].sim_option)

          let device = {}
          if (selected.length === 1 && typeof selected[0].devices !== 'undefined'){
            Object.keys(selected[0].devices).forEach(key => {
              if(key !== 'ADDON'){
                device[key] = selected[0].devices[key];
              }
            })
          }

        
          
          // get device group by package mamber
          setDeviceGroup(
            selected.length === 1 && !!device
              ? deviceSort(device)
              : {}
          );
          systemAction.stopLoading(dispatch)
          
        })
        .catch(error => {
          console.log(error)
          systemAction.stopLoading(dispatch)
        })
    }

    
  }

  const selectSimCard = async (data) => {
    if(selectedSimCard !== data) {
      // select
      setSelectedSimCard(data);
      if(data.bundle_item_id != 0){
        setEsimFlag(true);
      }else{
        setEsimFlag(false);
      }

      let tmpSummary = [{
        ...data,
        isCount: true,
        name: t('lblSummarySimCard'),
        group: def.group.simCard,
      }]

      setSummary(tempSummary => {
        // remove some item before add summary
        let rmSummary = removeSummary(def.group.simCard, tempSummary, 'group')

        // push to summary
        return addSummary(tmpSummary, rmSummary)
      });
      
    }
    // sim card must be choose!
    /*else {
      if(!!packagePlan && packagePlan.simCard) {
        packagePlan.simCard = null;
      }
      // unselect 
      setSelectedSimCard({});

      // pop from summary
      setSummary(() => {

        return removeSummary(def.group.simCard, summary, 'group')
      });
    }*/
    
  }

  const selectDeviceGroup = (parent, list = []) => {
    if(selectedParent !== parent) {
      setSelectedParent(parent);
      setSelectedChild(list);
      if(!!packagePlan && packagePlan.device != null) {
        if(!!packagePlan.device.bundle_name){
          selectDevice(packagePlan.device);
        }else{
          setModalDevice(true);
        }
      }else{
        setModalDevice(true);
      }
    } else {
      if(!!packagePlan && packagePlan.device){
        packagePlan.device = null;
      }
      // unselect 
      setSelectedParent('');
      setSelectedChild([]);
      setSelectedDevice({});

      // pop from summary
      setSummary(() => {
        let noDevice = removeSummary(def.group.device, summary, 'group')
            noDevice = removeSummary(def.group.promo, noDevice, 'group')
        return removeSummary(def.group['device deposit'], noDevice, 'group')
      });
    }
  }

  const selectDevice = async (data) => {
    systemAction.startLoading(dispatch)
    setModalDevice(false);
    // select
    setSelectedDevice(data);
    setDeviceFlag(true);

    let tmpSummary = [{
      ...data,
      isCount: true,
      group: def.group.device
    }]

    // get promo
    const promo = await sourceAction.promoBundle(dispatch, state.orderReducer, selectedPackage[0].id, data.bundle_item_id)
    if(!!promo && promo.length > 0) {
      promo.map(obj =>
        tmpSummary.push({
          ...obj,
          isCount: true,
          group: def.group.promo,
        })
      )
    }

    const dependency = await sourceAction.dependencyCatalog(dispatch, state.orderReducer, data.bundle_item_id)
    // get relation
    if(dependency.relation != null){
      dependency.relation.map(obj =>
        tmpSummary.push({
          ...obj,
          isCount: true,
          group: def.group.device,
        })
      );
    }

    // get device deposit
    if(dependency.deposit != null){
      dependency.deposit.forEach(obj => {
        tmpSummary.push({
          ...obj,
          isCount: true,
          name: t('lblSummaryDeviceDeposit'),
          group: def.group['device deposit'],
        })
      });
    }

    setSummary(tempSummary => {
      // remove some item before add summary
      let rmSummary = removeSummary(def.group.device, tempSummary, 'group')
          rmSummary = removeSummary(def.group.promo, rmSummary, 'group')
          rmSummary = removeSummary(def.group['device deposit'], rmSummary, 'group')

      // push to summary
      return addSummary(tmpSummary, rmSummary)
    });
    systemAction.stopLoading(dispatch)
  }

  const selectAddon = async (data) => {
    
    if(selectedAddon !== data) {
      // select
      setSelectedAddon(data);

      let tmpSummary = [{
        ...data,
        isCount: true,
        name: t('lblSummaryAddon'),
        group: def.group.addon,
      }]
      

      setSummary(tempSummary => {
        // remove some item before add summary
        let rmSummary = removeSummary(def.group.addon, tempSummary, 'group')

        // push to summary
        return addSummary(tmpSummary, rmSummary)
      });
    } else {
      if(!!packagePlan && packagePlan.addon){
        packagePlan.addon = null;
      }
      // unselect 
      setSelectedAddon({});

      // pop from summary
      setSummary(() => {

        return removeSummary(def.group.addon, summary, 'group')
      });
    }
    
  }

  const getThumb = (data) => {
    let thumb = '';

    if(!thumb && !!data && data.length) {
      thumb = _.startsWith(data[0].image_file, 'https://')
        ? data[0].image_file
        : env.bucket + data[0].image_file;
    }

    return thumb;

  }

  const validate = () => {
    const schema = {
      selectedPackage: { require: () => !!selectedPackage.length ? '' :'Please choose plan' },
      simOption: { require: () => !!selectedSimCard ? '' : 'Please choose sim option' },
    }
    const error = ObjectValidator(schema)
    setErrorMessage(error)
    scrollToClass('error-text')
    return error
  }

  const sendOrderStep = async (data, bookNumber = {}) => {
    let orderDetail = [];

    // order_detail
    data.forEach(obj => {
      if(!!obj.item_id) {
        Array(Number(obj.quantity)).fill().map(() =>
          orderDetail.push(
            {item_id: obj.item_id, quantity: 1}
          )
        )
      }
    })

    let order = {
      order_step: {
        ...bookNumber,
        order_detail: JSON.stringify(orderDetail)
      }
    }
    
    dispatch({ type: "ORDER_STEP", payload: order })
    if(!orderStep || !orderStep.step_id) {
      let res = await api.post('order_portal/create_order_step', { order })
      if(!!res.data) order['step_id'] = res.data
    } else {
      order['step_id'] = orderStep.step_id
      await api.post('order_portal/update_order_step', { order })
    }
  }

  const submit = async () => {
    if(!!validate()) return;

    let newStep = '/mobi-5g/' + (autoNumberValue ? 'customer-info' : 'choose-number');
    let newAllowStep = [...allowPage, newStep];

    let packagePlan = {
      device: !!selectedDevice ? selectedDevice : null,
      plan: [selectedPackage[0]],
      addon: !!selectedAddon? selectedAddon : null
    }

    // mark item device stock
    const booked = await bookingInventory(selectedDevice, bookedId, customerInfo);
    if(!booked) return;

    // grab mobile phone
    let mobilePhone;
    if (!customerInfo || !customerInfo.customerDetail || !customerInfo.customerDetail.mobile_phone) {
      mobilePhone = null;
    } else {
      mobilePhone = customerInfo.customerDetail.mobile_phone;
    }
    const resultNumber = await generateNumber(selectedPackage, mobilePhone, autoNumberValue);

    //check valid delivery flag
    let delivery_form;
    if(esimFlag == true && deviceFlag == false){
      
      delivery_form = false;
    }else{
      delivery_form = true;
    }
    orderAction.customerInfo(dispatch, {...customerInfo, delivery_form, esimFlag});

    // auto number
    let bookNumber = {};
    if(orderType !== 'Port-IN' && autoNumberValue) {
        const resultBookNumber = await bookingNumber(resultNumber);
        if(!resultBookNumber) return;
    }
    
    systemAction.startLoading(dispatch);
    sendOrderStep(summary, bookNumber);
    orderAction.summary(dispatch, summary);
    orderAction.autoNumber(dispatch, autoNumberValue);
    orderAction.simNumber(dispatch, resultNumber);
    orderAction.packagePlan(dispatch, packagePlan);
    systemAction.allowPage(dispatch, newAllowStep);
    history.push(newStep);
  }

  const onDeviceGroupChange = () => {
    if(!!packagePlan && packagePlan.device) {
      Object.keys(deviceGroup).map((key, index) =>
        Object.keys(deviceGroup[key]).forEach((parent, k) => {
          const match = deviceGroup[key][parent].find(d => d.bundle_item_id === packagePlan.device.bundle_item_id)
          if(!!match) {
            selectDeviceGroup(parent, deviceGroup[key][parent])
            setDeviceActive([index])
          }
        })
      )
    }
  }

  const onOfferingsLoad = () => {
    if(!!packagePlan && packagePlan.plan.length) {
      offerings.forEach((group, groupKey) => {
        group.member.forEach((member, memberKey) => {
          const match = packagePlan.plan.find(i => i.id === member.id)
          if(!!match) {
            selectPackage(member)
            setMainActive([String(groupKey)])
          }
        })
      })
    }
    if(!!autoNumber) setAutoNumberValue(autoNumber)
  }

  const onOrderTypeUpdate = () => {
    if(!!orderType && offerings.length == 0) {
      systemAction.startLoading(dispatch)
      sourceAction.offerings(dispatch, state.orderReducer)
        .finally(() => {
          systemAction.stopLoading(dispatch)
        })
    }
  }

  const onAddonLoad = () => {
    if(!!packagePlan && packagePlan.addon != null) {
       Object.keys(addonGroup).map((key, index) => {
         Object.keys(addonGroup[key]).forEach((parent, k) => {
           if(addonGroup[key][parent].bundle_item_id === packagePlan.addon.bundle_item_id) {
             selectAddon( addonGroup[key][parent])
             setAddonActive([index])
           }
         })
       })
     }
   }

  const onLoad = () => {
    new Sticky('.sticky');

    if(!bookedId) {
      orderAction.getBookedId(dispatch);
    }

    
    orderAction.customerInfo(dispatch, {...customerInfo, customerType, network});
    systemAction.allowPage(dispatch, ['/mobi-5g/package-plan']);

    if(orderType === 'Port-IN') setAutoNumberValue(true)
  }

  const propsDeviceModal = {
    show: modalDevice,
    setShow: setModalDevice
  }

  useEffect(onLoad, []);
  useEffect(onOrderTypeUpdate, [orderType]);
  useEffect(onOfferingsLoad, [offerings]);
  useEffect(onDeviceGroupChange, [deviceGroup]);
  useEffect(onAddonLoad, [addonGroup]);

  return (
    <>
    <div className="container" data-sticky-container>
      <StepIndicator steps={steps} current={0} />

      <Row gutter={24} className="margin-bottom">
        <Col xs={24} md={16}>

          <PanelTimeline title="Mobi Plan">

            {!!errorMessage.selectedPackage && !selectedPackage.length &&
              <p className="error-text">{errorMessage.selectedPackage}</p>
            }

            <Collapse
              ghost
              accordion
              className="accordion"
              expandIconPosition="end"
              activeKey={mainActive}
              onChange={(res) => setMainActive([res])}
            >
              {offerings.map((group, groupKey) => 
                <Panel header={group.name.toUpperCase()} key={groupKey}>
                  {group.member.map((member, memberKey) =>
                    <CardPackage
                      key={memberKey}
                      active={!!selectedPackage.find(obj => obj.id === member.id)}
                      onClick={() => selectPackage(member)}
                    >
                      <h6 className="text-brand text-bold margin-rm flex flex-between">
                        <span>{member.name}</span>
                        <span>{member.price}</span>
                      </h6>
                      <Divider className="margin-sm-top margin-sm-bottom" />
                      <div className="flex">
                        <div className="flex-auto">
                          {member.spec &&
                          <React.Fragment>
                            {member.spec.call ? <span className="padding-right"><PhoneOutlined /> {member.spec.call}</span> : ''}
                            {member.spec.text ? <span className="padding-right"><MailOutlined /> {member.spec.text} </span> : ''}
                            {member.spec.data ? <span className="padding-right"><GlobalOutlined /> {member.spec.data} </span> : ''}
                            {member.spec.roaming ? <span className="padding-right"><WifiOutlined /> {member.spec.roaming} </span> : ''}
                          </React.Fragment>
                          }
                        </div>
                        {/* {orderType !== 'Port-IN' &&
                          <QuantityEditor
                            quantity={initQuantity(member.id)}
                            setQuantity={(qty, event) => setQuantity(qty, member.id, event)}
                            disabled={!selectedPackage.find(obj => obj.id === member.id)}
                          />
                        } */}
                      </div>
                    </CardPackage>
                  )}
                </Panel>
              )}
            </Collapse>

            {orderType !== 'Port-IN' &&
            <div className="margin-md-top">
              <p>
                <span className="text-bold">{t('lblAutomaticNumber')}</span>
                <br />
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  defaultChecked={autoNumber}
                  className="margin-sm-right"
                  onClick={value => setAutoNumberValue(value)}
                />
              </p>
            </div>
            }
          </PanelTimeline>
          
          <PanelTimeline title={t('lblSectionProductSimCard')}>
          {!!errorMessage.simOption &&
            <p className="error-text">
              {errorMessage.simOption}
            </p>
          }
          {!!simCardGroup &&
                  <Row gutter={16} className="margin-bottom padding-sm-left padding-sm-right">
                  {Object.keys(simCardGroup).map((parent, k) =>
                    <Col xs={24} md={8} key={parent} className="padding-rm">
                      <CardPackage
                        active={simCardGroup[parent] == selectedSimCard}
                        onClick={() => selectSimCard(simCardGroup[parent])}
                      >
                        <h5 className="text-brand text-bold margin-sm-top margin-sm-bottom">
                          {simCardGroup[parent]['bundle_name']}
                        </h5>
                        <p className="margin-rm">
                          { currency(simCardGroup[parent]['price_amount']) }
                        </p>
                      </CardPackage>
                    </Col>
                  )}
                  </Row>
              }
          </PanelTimeline>
          
          <PanelTimeline title={t('lblSectionDevice') +' '+ t('lblSectionOptional')}>
            {!!deviceGroup &&
            <Collapse
              ghost
              accordion
              className="accordion margin-bottom"
              expandIconPosition="end"
              activeKey={deviceActive}
              onChange={(res) => setDeviceActive([res])}
            >
              {Object.keys(deviceGroup).map((key, index) =>
                <Panel header={key.toUpperCase()} key={index}>
                  <Row gutter={16} className="margin-bottom padding-sm-left padding-sm-right">
                  {Object.keys(deviceGroup[key]).map((parent, k) =>
                    <Col xs={24} md={8} key={k} className="padding-rm">
                      <CardPackage
                        active={parent === selectedParent}
                        onClick={() => selectDeviceGroup(parent, deviceGroup[key][parent])}
                      >
                        <div className="text-center">
                          <img
                            alt="thumb"
                            className="img-thumb"
                            src={mainThumbnail(deviceGroup[key][parent])}
                            onError={({target}) => target.src = noImage}
                          />
                        </div>
                        <h5 className="text-brand text-bold margin-sm-top margin-sm-bottom">
                          {parent}
                        </h5>
                        <p className="margin-rm">
                          { getRangePrice(deviceGroup[key][parent]) }
                        </p>
                      </CardPackage>
                    </Col>
                  )}
                  </Row>
                </Panel>
              )}
              {selectedParent &&
                <Device devices={selectedChild} selectDevice={selectDevice} {...propsDeviceModal} />
              }
            </Collapse>
            }
          </PanelTimeline>

          <PanelTimeline title={t('lblSectionAddon') +' '+ t('lblSectionOptional')} noLine>
          {!!addonGroup &&
            <Collapse
              ghost
              accordion
              className="accordion margin-bottom"
              expandIconPosition="end"
              activeKey={addonActive}
              onChange={(res) => setAddonActive([res])}
            >
              {Object.keys(addonGroup).map((key, index) =>
                <Panel header={key.toUpperCase()} key={index}>
                  <Row gutter={16} className="margin-bottom padding-sm-left padding-sm-right">
                  {Object.keys(addonGroup[key]).map((parent, k) =>
                    <Col xs={24} md={8} key={parent} className="padding-rm">
                      <CardPackage
                        active={addonGroup[key][parent] == selectedAddon}
                        onClick={() => selectAddon(addonGroup[key][parent])}
                      >
                        <h5 className="text-brand text-bold margin-sm-top margin-sm-bottom">
                          {addonGroup[key][parent]['bundle_name']}
                        </h5>
                        <p className="margin-rm">
                          { currency(addonGroup[key][parent]['price_amount']) }
                        </p>
                      </CardPackage>
                    </Col>
                  )}
                  </Row>
                  
                </Panel>

                
              )}
            </Collapse>
            }
          </PanelTimeline>

        </Col>
          
        <Col xs={24} md={8}>
          <div className="sticky" data-margin-top="90">
            <Summary
              data={summary}
              submit={() => submit()}
              removeDevice={() => selectDeviceGroup(selectedParent)}
              mainLabel={t('lblSummaryMainSim')}
              showQty={true}
            />
          </div>
        </Col>
        
        <Col xs={24}>
          <Button className="margin-top" type="default" onClick={() => history.goBack()}>
            <LeftOutlined />
            {t('btnBack')}
          </Button>
        </Col>
      </Row>

    </div>
    <OrderType {...propsOrderType} />
    </>
  );
}
