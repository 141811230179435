import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from "react-i18next";
import { Row, Col, Form, Select, Divider, DatePicker, Input } from 'antd';
import { Context } from 'state/store';
import sourceAction from 'state/actions/sourceAction';
import deliveryDate from 'helpers/deliveryDate';

const { Option } = Select;

export default function DeliveryPickup({
  data,
  form,
  pickupOnly = false,
  deliveryType,
  customerType,
  setDeliveryType = () => {},
  setSameAddress  = () => {}
}) {
  const { t } = useTranslation();
  const [state, dispatch] = useContext(Context);
  const {isLogined} = state.userReducer;
  const {counterList, publicHoliday} = state.sourceReducer;
  const [selectedStore, setSelectedStore] = useState({});
  
  const disabledDate = (current) => {
    let delivery_type = !!data?.delivery_type ? Number(data?.delivery_type) : null
        delivery_type = !!deliveryType ? Number(deliveryType) : delivery_type

    return deliveryDate.isDisabled(current, delivery_type, publicHoliday)
  }

  const onDeliveryTypeChange = (value) => {
    setSameAddress(false)
    setDeliveryType(Number(value))
    form.setFieldsValue({
      delivery_date: null
    })
  }

  const onLoad = () => {
    if(!publicHoliday.length) {
      sourceAction.publicHoliday(dispatch);
    }
    
    sourceAction.counterList(dispatch, customerType).then(response => {
      if(!!data) {
        setSelectedStore(
          response.find(obj => obj.id === Number(data.store_id))
        )
      }
    });
  }

  const onSelectedStore = () => {
    form.setFieldsValue({
      store_address: selectedStore?.address,
      store_name: selectedStore?.name
    })
  }

  useEffect(onLoad, []);
  useEffect(onSelectedStore, [selectedStore]);

  return (
    <Form form={form} initialValues={data}>
      <Row gutter={16}>
        <Col xs={24} md={8}>
          <Form.Item
            name="delivery_type"
            hidden={pickupOnly}
            rules={[
              { required: true, message: 'Delivery Type is required' }
            ]}
          >
            <Select
              size="large"
              placeholder={t('inputSelect') +' '+ t('inputDeliveryType') + '*'}
              onSelect={(val) => onDeliveryTypeChange(val)}
            >
              {isLogined &&
                <Option value="3">Instant Delivery</Option>
              }
              <Option value="2">Delivery</Option>
              <Option value="1">Pick Up</Option>
            </Select>
          </Form.Item>
        </Col>
        
        <Col xs={24} md={8}>
          <Form.Item
            name="delivery_date"
            hidden={pickupOnly}
            rules={[
              { required: !pickupOnly, message: 'Date is required' }
            ]}
          >
            <DatePicker
              size="large"
              className="width-full"
              placeholder={t('inputDeliveryDate') + '*'}
              format="DD MMMM YYYY"
              disabled={!data?.delivery_type && !deliveryType}
              disabledDate={disabledDate}
            />
          </Form.Item>
        </Col>
      </Row>
      {(deliveryType === 1 || Number(data?.delivery_type) === 1) &&
      <>
        {!pickupOnly &&
        <Divider className="margin-sm-top" />
        }
        <Row gutter={16}>
          <Col xs={24} md={pickupOnly ? 12 : 8}>
            <Form.Item
              name="store_id"
              rules={[
                { required: true, message: 'Store is required' }
              ]}
            >
              <Select
                size="large"
                placeholder={t('inputStoreSelect') + '*'}
                notFoundContent="No data"
                onSelect={(value) => setSelectedStore(
                  counterList.find(obj => obj.id === Number(value))
                )}
              >
                {counterList.map(d => <Option key={d.id}>{d.name}</Option>)}
              </Select>
            </Form.Item>
            <Form.Item hidden name="store_name"><Input /></Form.Item>
          </Col>
          <Col xs={24} md={pickupOnly ? 12 : 8}>
            <p className="text-bold">{!!selectedStore ? selectedStore.address : ''}</p>
            <Form.Item hidden name="store_address"><Input /></Form.Item>
          </Col>
        </Row>
      </>
      }      
    </Form>
  );
};