import React, { useState, useEffect } from 'react';
import { Input, Dropdown, Tooltip, Button, Menu, Modal } from 'antd';
import { InfoCircleOutlined, ScanOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import * as _ from 'lodash';

import Barcode from './barcode';
import QrCode from './qrCode';

export default function ReviewNumbers({
  packagePlan,
  autoNumber,
  simNumber,
  setResult,
  isLogined,
  esimFlag,
  errorMessage
}) {
  const { t } = useTranslation();
  const [slotNumber, setSlotNumber] = useState({});
  const [itemKey, setItemKey] = useState({});
  const [showQRcode, setShowQRcode] = useState(false);
  const [showBarcode, setShowBarcode] = useState(false);

  const propsBarcode = {
    show: showBarcode,
    setShow: res => setShowBarcode(res),
    setResult: res => setImsi(res, itemKey.packageId, itemKey.key)
  }

  const propsQRcode = {
    show: showQRcode,
    setShow: res => setShowQRcode(res),
    setResult: res => setImsi(res, itemKey.packageId, itemKey.key)
  }

  const setImsi = (imsi, productId, key) => {
    let tmpSlotNumber = {...slotNumber}
        tmpSlotNumber[productId][key].imsi = imsi
 
    setSlotNumber(tmpSlotNumber)
  }

  const getProductName = itemId => {
    let plan = packagePlan.find(obj => Number(obj.id) === Number(itemId))
    return !!plan.name ? plan.name : plan.name
  }

  const onLoad = () => {
    setSlotNumber(simNumber)
  }

  const onSetSlotNumber = () => {
    setResult(slotNumber)
  }

  useEffect(onLoad, []);
  useEffect(onSetSlotNumber, [slotNumber]);
  
  const menu = (
    <Menu>
      <Menu.Item key="1" icon={<ScanOutlined />} onClick={() => setShowBarcode(true)}>
        Barcode Scanner
      </Menu.Item>
      <Menu.Item key="2" icon={<ScanOutlined />} onClick={() => setShowQRcode(true)}>
        QRcode Scanner
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <div className="ant-descriptions-title margin-bottom">{t('lblDataNumberSelection')}</div>

      <div className="ant-table ant-table-bordered">
        <div className="ant-table-container">
          <div className="ant-table-content">
            <table style={{tableLayout: 'auto'}}>
              <colgroup></colgroup>
              <thead className="ant-table-thead">
                <tr>
                  <th className="ant-table-cell">{t('lblSummaryMainSim')}</th>
                  <th className="ant-table-cell">{t('lblMobileNumber')}</th>
                  {isLogined && esimFlag === false &&
                    <th className="ant-table-cell">{t('lblImsiNumber')}</th>
                  }
                </tr>
              </thead>
              <tbody className="ant-table-tbody">
              {Object.keys(slotNumber).map((productId) =>
              <React.Fragment key={productId}>
                {slotNumber[productId].map((obj, key) =>
                  <tr className="ant-table-row ant-table-row-level-0" key={key}>
                    <td className="ant-table-cell">{getProductName(productId)}</td>
                    <td className="ant-table-cell">{obj.number}</td>
                    {isLogined && esimFlag === false &&
                      <td className="ant-table-cell">
                        {!autoNumber
                          ? <>{obj.imsi}</>
                          : <>
                            <div className="flex">
                              <Input
                                size="large"
                                className="width-full"
                                placeholder="IMSI Number*"
                                minLength={15}
                                maxLength={15}
                                value={obj.imsi}
                                onChange={e => setImsi(e.target.value.replace(/[^\d]/gi, ''), productId, key)}
                                suffix={
                                  <Tooltip title="IMSI number must be numeric and 15 characters">
                                    <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                  </Tooltip>
                                }
                              />
                              <Dropdown
                                overlay={menu}
                                trigger={['click']}
                                onVisibleChange={() => setItemKey({
                                  packageId: productId,
                                  key: key
                                })}
                              >
                                <Button size="large">
                                  <ScanOutlined />
                                </Button>
                              </Dropdown>
                            </div>
                            {!_.isEmpty(errorMessage) && !obj.imsi &&
                              <p className="margin-rm error-text">
                                {errorMessage.imsi}
                              </p>
                            }
                            </>
                        }
                      </td>
                    }
                  </tr>
                )}
              </React.Fragment>
              )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Modal
        width={360}
        footer={null}
        closable={false}
        maskClosable={false}
        onCancel={() => setShowBarcode(false)}
        className="scanner"
        open={showBarcode}
        centered
      >
        <Barcode {...propsBarcode} />
      </Modal>

      <Modal
        width={360}
        footer={null}
        closable={false}
        maskClosable={true}
        className="scanner"
        onCancel={() => setShowQRcode(false)}
        open={showQRcode}
        centered
      >
        <QrCode {...propsQRcode} />
      </Modal>
    </>
  );
};