import { useContext } from "react";
import sourceAction from "state/actions/sourceAction";
import { Context } from "state/store";

const categoryNumber = {
  'Normal': 1,
  'Golden': 2
}

const useAutoNumber = () => {
  const [state, dispatch] = useContext(Context);
  const {orderType} = state.orderReducer;

  const generateNumber = async (selectedPackage, mobilePhone, autoNumber) => {
    let book_number = {}
    if(!autoNumber) return null;

    await Promise.all(
      selectedPackage.map(async obj => {
        let quantity = obj.quantity
        let numbers = []

        if(orderType === 'Port-IN') {
          numbers.push({
            id: "",
            number: mobilePhone,
            category: 3,
            imsi: null,
            take: quantity
          })
        } else {
          // Old Api MSISDN -- START
          let params = {
            category: "Recycled", 
            sim_no: "",
          }
          let numberList = await sourceAction.msisdn(dispatch, params)
  
          // recall if Normal number is empty
          // if(numberList.length === 0 && obj.number_category === 'Normal') {
          //   params.category = 'Recycled'
          //   numberList = await sourceAction.msisdn(dispatch, params)
          // }
          
          if(!!numberList && numberList.length) {
            Array(quantity).fill().forEach((v, i) => {
              numbers.push({
                id: numberList[i].msisdnId,
                number: numberList[i].msisdnNo,
                category: categoryNumber[obj.number_category],
                imsi: null
              })
            });
          }
          //Old Api MSISDN -- END
          
          // New Api MSISDN -- START
          /* let params = {
            category: categoryNumber[obj.number_category],
            sim_no: "",
          }
          let numberList = await sourceAction.msisdn(dispatch, params);

          if(!!numberList && numberList.length) {
            Array(quantity).fill().forEach((v, i) => {
              numbers.push({
                id: numberList[i].id,
                number: numberList[i].msisdn,
                category: categoryNumber[obj.number_category],
                imsi: null
              })
            });
          } */
          // New Api MSISDN -- END
        }

        book_number[obj.id] = numbers;
      })
    )
    
    return book_number;
  }

  return {
    generateNumber
  }
};

export default useAutoNumber;