import React, { useEffect, useState, useRef } from "react";
import { message, Button } from "antd";
import { CameraOutlined } from "@ant-design/icons";
import Quagga from 'quagga';
import Styles from './style.module.scss';

export default function Barcode({
  show,
  setShow,
  setResult
}) {
  const refScanner = useRef(null);
  const [errorScan, setErrorScan] = useState(null);
  const [barcode, setBarcode] = useState(null);
  const [start, setStart] = useState(null);
  const [camera, setCamera] = useState([]);

  const onLoad = () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      setShow(false);
      message.info("Can't access camera");
      return;
    }

    navigator.permissions.query({name: 'camera'})
      .then((permissionObj) => {
        if(permissionObj.state === 'denied') {
          message.info('Please allow camera permision!', 3)
        }
      })
      .catch((error) => {
        console.log('Got error :', error);
      })
    
    navigator.mediaDevices.enumerateDevices()
      .then(function (devices) {
        let allCamera = devices.filter(d => d.kind === 'videoinput');
        setCamera(allCamera[allCamera.length - 1].deviceId)
      })
      .catch(function (err) {
        console.log(err.name + ": " + err.message);
      });
  }

  const onClickStart = () => {
    if(start) {
      if(barcode) {
        startBarcode(camera);
        setBarcode(null)
      } else {
        startBarcode(camera);
      }
    } else if (start === false) {
      Quagga.stop();
      refScanner.current.innerHTML = '';
    }
  }

  const onGetBarcode = () => {
    if (!!barcode) {
      refScanner.current.innerHTML = '';
      setStart(null)
      Quagga.stop();
    }
  }

  useEffect(onLoad, []);
  useEffect(onClickStart, [start]);
  useEffect(onGetBarcode, [barcode]);
  useEffect(() => {
    if (errorScan) message.error(errorScan);
  }, [errorScan]);


  const startBarcode = deviceId => {
    Quagga.init({
      inputStream: {
        type: "LiveStream",
        constraints: {
          width: 341,
          height: 341,
          facingMode: "environment", // or user
          deviceId
        },
        area: {
          top: "0",
          right: "0",
          left: "0",
          bottom: "0"
        },
      },
      locator: {
        patchSize: "large",
        halfSample: true,
      },
      decoder: {
        readers: [
          "code_128_reader",
          "ean_reader"
        ],
        multiple: false
      },
      numOfWorkers: 4,
      locate: true
    }, function (err) {
      if (err) {
        if (String(err).includes('Permission denied')) {
          message.info('Please allow camera permision!', 5)
        }
        setStart(false);
        return
      }
      Quagga.start();
    });

    Quagga.onProcessed(function (result) {
      var drawingCtx = Quagga.canvas.ctx.overlay,
        drawingCanvas = Quagga.canvas.dom.overlay;

      if (result) {
        if (result.boxes) {
          drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute("width")), parseInt(drawingCanvas.getAttribute("height")));
          result.boxes.filter(function (box) {
            return box !== result.box;
          }).forEach(function (box) {
            Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, { color: "green", lineWidth: 2 });
          });
        }

        if (result.box) {
          Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, { color: "#00F", lineWidth: 2 });
        }

        if (result.codeResult && result.codeResult.code) {
          Quagga.ImageDebug.drawPath(result.line, { x: 'x', y: 'y' }, drawingCtx, { color: 'red', lineWidth: 3 });
        }
      }
    });

    Quagga.onDetected(res => {
      if (
        !barcode &&
        res.codeResult.code !== '%' &&
        res.codeResult.code !== '+'
      ) {
        setBarcode({
          code: res.codeResult.code,
          format: res.codeResult.format
        })
      } else {
        setErrorScan('Please try again!')
      }
    });
  }


  return (
    <div className={Styles.scanArea}>
      <p className={Styles.title}>Barcode Scanner</p>
      <div ref={refScanner} id="interactive" className="viewport" />

      {!start && !barcode &&
        <div className={`${Styles.vCenter} ${Styles.iconBg}`}>
          <CameraOutlined />
        </div>
      }
      
      {barcode &&
        <div className={`${Styles.vCenter} text-center`}>
          <p className="margin-rm">Code : <b>{barcode.code}</b></p>
          <p>Format : {barcode.format}</p>
        </div>
      }

      <div className={Styles.action}>
        <Button
          type="ghost"
          onClick={() => setStart(!start)}
        >
          {start && !barcode ? 'Stop' : 'Start'}
        </Button>
        {!barcode &&
        <Button
          type="ghost"
          disabled={start}
          onClick={() => { if (!start) setShow(!show) }}
        >
          Close
        </Button>
        }
        {barcode &&
        <Button
          type="ghost"
          onClick={() => {
            setResult(barcode)
            setBarcode(null)
            setShow(!show)
          }}
        >
          Oke
        </Button>
        }
      </div>
    </div>
  );
}
