import React from 'react';
import { Button, Input, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

export default function ReviewOtp({
  otp,
  otpCode,
  setOtpCode,
  sendOtp,
  errorMessage
}) {
  const { t } = useTranslation();
  
  return (
    <div className="text-center">
      <p>{t('lblOtpGetTheCode')}</p>
      
      <div className="width-full">
        <small>{t('lblOtpVerification')}</small>

        <div className="flex width-max-md margin-auto">
          <Button
            size="large"
            type="primary"
            className="margin-rm"
            onClick={sendOtp}
          >
            Get Code
          </Button>
          <Input
            placeholder="Enter Code"
            disabled={!otp}
            value={otpCode}
            minLength={6}
            maxLength={6}
            onChange={e => setOtpCode(e.target.value.replace(/[^0-9]/gi, ''))}
            prefix={!otp ? '____' : otp.prefix}
            suffix={
              <Tooltip title="Six number code">
                <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
              </Tooltip>
            }
          />
        </div>
        {!!errorMessage.otpCode &&
          <p className="error-text">
            {errorMessage.otpCode}
          </p>
        }
      </div>
    </div>
  );
};