import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { message, Row, Col, Card, Input, Button, Descriptions, notification } from "antd";
import { CheckCircleOutlined, LeftOutlined, ShoppingCartOutlined, WarningOutlined } from '@ant-design/icons';
import * as moment from "moment";

import api from "helpers/api";
import paymentAction from "state/actions/paymentAction";
import systemAction from "state/actions/systemAction";
import orderAction from "state/actions/orderAction";
import { Context } from "state/store";
import Style from './style.module.scss';
import currency from "helpers/currency";

const nowYear = moment().format('YY');

export default function Payment() {
  const { t } = useTranslation();
  const history = useHistory();
  const {orderId} = useParams();
  const {pathname} = useLocation();
  const [state, dispatch] = useContext(Context);
  const {isLogined, user} = state.userReducer;
  const {orderResult} = state.orderReducer;
  const {bill, type} = state.paymentReducer;
  const [byLink, setByLink] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});
  const [paymentType, setPaymentType] = useState(null);
  const [paymentResult, setPaymentResult] = useState(null);
  const [billUp, setBillUp] = useState(null);

  const paymentConfig = () => {
    window.PaymentSession.configure({
      fields: {
        // ATTACH HOSTED FIELDS TO YOUR PAYMENT PAGE FOR A CREDIT CARD
        card: {
          number: "#card-number",
          securityCode: "#security-code",
          expiryMonth: "#expiry-month",
          expiryYear: "#expiry-year"
        }
      },
      //SPECIFY YOUR MITIGATION OPTION HERE
      frameEmbeddingMitigation: ["javascript"],
      callbacks: {
        initialized: function (response) {
          // HANDLE INITIALIZATION RESPONSE
          systemAction.stopLoading(dispatch);
        },
        validate: function (result) {
          console.log(result)
        },
        formSessionUpdate: function (response) {
          // HANDLE RESPONSE FOR UPDATE SESSION
          if (response.status) {
            if (response.status === "ok") {
              processPaymentCC(response.session.id);
            } else if (response.status === "fields_in_error")  {
              let err = response.errors
              let error = {...errorMessage}

              if(!!err.cardNumber) error.cardNumber = 'Card number invalid or missing.'
              if(!!err.securityCode) error.securityCode = 'Security code invalid.'
              if(!!err.expiryMonth) error.expiryMonth = 'Expiry month invalid or missing.'
              if(!!err.expiryYear) error.expiryYear = 'Expiry year invalid or missing.'

              setErrorMessage(error)
              showNotif('error', 'Please check from.')
              systemAction.stopLoading(dispatch)
            } else if(response.status === "system_error") {
              systemAction.stopLoading(dispatch)
              showNotif('error', 'Form Session not found or expired.')
            }
          } else {
            systemAction.stopLoading(dispatch);
            console.log("Session update failed: " + response);
          }
        }
      }
    });

    window.PaymentSession.onFocus(
      ['card.number','card.expiryMonth', 'card.expiryYear', 'card.securityCode'],
      function(selector, role) {
        setErrorMessage({})
      }
    );
  }

  const showNotif = (type = 'success', message) => {
    notification.open({
      message: type === 'success' ? 'Success' : 'Oops Sorry...',
      description: message,
      icon: type === 'success'
        ? <CheckCircleOutlined style={{ color: 'green' }} />
        : <WarningOutlined style={{ color: 'red' }} />,
    });
  }

  const getCheckoutByOrderId = (orderId) => {
    systemAction.startLoading(dispatch);
    api.post('order_portal/checkout_session_order', { order_id: orderId })
      .then(result => {
        setBillUp(result.data)
        systemAction.stopLoading(dispatch)
      })
      .catch(error => {
        let delay = 3;

        message.error(error.response.data, delay)
        setTimeout(() => {
          systemAction.stopLoading(dispatch)
          history.push('/')
        }, delay * 1000)
      })
  }

  const processPaymentCC = (sessionId) => {
    let params = {
      sessionId: sessionId,
      apiOperation: 'PAY',
      amount: billUp.accountState,
      packageName: billUp.packageName[0].name,
      orderId: billUp.orderId,
      transactionId: billUp.transactionId,
      currency: billUp.currency,
      hostedSession: true,
      protocol: ''
    }
    api.post('order_portal/process_hosted_payment', { data: params })
      .then(({data}) => {
        if(data.result === 'SUCCESS' && data.response.gatewayCode === 'APPROVED') {
          setPaymentResult(data)
          systemAction.stopLoading(dispatch)
          showNotif('success', 'Your payment has been received.')
        } else {
          systemAction.errorLog(dispatch, data, 'process_hosted_payment')
          systemAction.stopLoading(dispatch)
          showNotif('error', 'Your payment failed.')
        }
      })
      .catch(error => {
        console.log(error)
        systemAction.stopLoading(dispatch)
        message.error(t('msgSystemTrouble'), 3)
      })
  }

  const payWithCC = () => {
    setErrorMessage({});
    systemAction.startLoading(dispatch);
    window.PaymentSession.updateSessionFromForm('card');
  }

  const payWithWallet = () => {
    systemAction.startLoading(dispatch);

    let params = {
      amount: billUp.accountState,
      dealer_id: user.rep_id,
      subscription_id: orderResult.subscription_id,
      session_id: user.session_id,
      usage_type: 4
    }
    
    api.post('order_portal/process_wallet_payment', params, { timeout: 15000 })
      .then(result => {
        setPaymentResult(result.data)
        systemAction.stopLoading(dispatch)
        showNotif('success', 'Your payment has been received.')
      })
      .catch(error => {
        console.log(error)
        systemAction.stopLoading(dispatch);
        message.error(!!error.data ? error.data : t('msgSystemTrouble'), 5);
      })
  }

  const finish = () => {
    orderAction.reset(dispatch);
    paymentAction.reset(dispatch);
    systemAction.resetPage(dispatch);
    history.push('/');
  }

  const onPaymentTypeChange = () => {
    setErrorMessage({
      cardNumber: null,
      securityCode: null,
      expiryMonth: null,
      expiryYear: null
    })
  }

  const onBillUpChange = () => {
    if(!!billUp) {
      let script = document.createElement('script');
          script.src = billUp.sessionjsurl;
          script.id = 'session-js';
      document.head.appendChild(script);
      
      script.onload = () => {
        paymentConfig();
      }
    }
  }

  const onLoad = () => {
    const parentPathname = pathname.split('/')[1];

    if (parentPathname === 'retrive_payment' && !!orderId) {
      // from link email
      getCheckoutByOrderId(orderId)
      setPaymentType('cc')
      setByLink(true)
    } else if (!!orderResult && !!orderResult.subscription_id) {
      // from order
      setPaymentType(type)
      setBillUp(bill)
    } else {
      orderAction.reset(dispatch);
      history.push('/')
    }
  }

  useEffect(onLoad, []);
  useEffect(onBillUpChange, [billUp]);
  useEffect(onPaymentTypeChange, [paymentType]);

  return (
    <div className="container margin-bottom">
      <h3 className="text-center margin-md-bottom">Payment</h3>
      {paymentType === 'wallet' && !paymentResult && 
      <Card className={`${Style.paymentCard} width-max-xl margin-auto`}>
        <div className={Style.headCard}>
          <div className={Style.name}>
            <ShoppingCartOutlined />
            <span>{!!billUp ? billUp.packageName[0].name : ''}</span>
          </div>
          <div className={Style.price}>
            ${!!billUp ? billUp.accountState : 0}
          </div>
        </div>
        
        <div className="flex">
          {!byLink && isLogined && 
          <Button className="margin-sm-right" type="default" size="large" onClick={() => history.goBack()}>
            <LeftOutlined />
            {t('btnBack')}
          </Button>
          }
          <Button type="primary" size="large" block onClick={() => payWithWallet()}>
          {t('btnPayWallet')}
          </Button>
        </div>
      </Card>
      }


      {paymentType === 'cc' && !paymentResult && 
      <Card className={`${Style.paymentCard} width-max-xl margin-auto`}>
        <div className={Style.headCard}>
          <div className={Style.name}>
            <ShoppingCartOutlined />
            <span>{!!billUp ? billUp.packageName[0].name : ''}</span>
          </div>
          <div className={Style.price}>
            ${!!billUp ? billUp.accountState : 0}
          </div>
        </div>
        <Row gutter={16} className="margin-bottom">
          <Col xs={24} lg={16}>
            <label>Card Number</label>
            <Input id="card-number" maxLength="16" readOnly />
            <p className="error-text card-number">
              {errorMessage?.cardNumber}
            </p>
          </Col>
          <Col xs={24} lg={8}>
            <label>CVV</label>
            <Input id="security-code" type="password" maxLength="4" readOnly />
            <p className="error-text">
              {errorMessage?.securityCode}
            </p>
          </Col>
          <Col xs={24} lg={14}>
            <label>Expiration Date</label>
            <Row gutter={16}>
              <Col xs={12}>
                <select id="expiry-month" className={Style.selectBox} readOnly>
                  <option value="">Select Month</option>
                  <option value="01">January</option>
                  <option value="02">February</option>
                  <option value="03">March</option>
                  <option value="04">April</option>
                  <option value="05">May</option>
                  <option value="06">June</option>
                  <option value="07">July</option>
                  <option value="08">August</option>
                  <option value="09">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </select>
                <p className="error-text">
                  {errorMessage?.expiryMonth}
                </p>
              </Col>
              <Col xs={12}>
                <select id="expiry-year" className={Style.selectBox} readOnly>
                  <option value="">Select Year</option>
                  {Array(15).fill().map((_, i) =>
                    <option key={i} value={Number(nowYear) + i}>{Number(nowYear) + i}</option>
                  )}
                </select>
                <p className="error-text">
                  {errorMessage?.expiryYear}
                </p>
              </Col>
            </Row>
          </Col>
          <Col xs={24} lg={10}>
            <div className={Style.cardImage}>
              <img src={require('assets/images/payment/visa.jpg')} alt="visa" />
              <img src={require('assets/images/payment/mastercard.jpg')} alt="mastercard" />
              <img src={require('assets/images/payment/amex.jpg')} alt="amex" />
            </div>
          </Col>
        </Row>
        <div className="flex">
          {!byLink && isLogined && 
          <Button className="margin-sm-right" type="default" size="large" onClick={() => history.goBack()}>
            <LeftOutlined />
            {t('btnBack')}
          </Button>
          }
          <Button type="primary" size="large" block onClick={() => payWithCC()}>
            {t('btnPayNow')}
          </Button>
        </div>
      </Card>
      }
      

      {!!paymentResult &&
      <Card className={`${Style.paymentCard} width-max-xl margin-auto`}>
        <Descriptions title="Details" size="small" column={1} bordered>
          <Descriptions.Item label="Package">
            {!!billUp ? billUp.packageName[0].name : ''}
          </Descriptions.Item>
          <Descriptions.Item label="Amount">
            {paymentType === 'wallet' ? currency(paymentResult.gross_amount) : paymentResult.order.totalCapturedAmount}
          </Descriptions.Item>
          <Descriptions.Item label="Payment Status">
            {paymentType === 'wallet' ? 'APPROVED' : paymentResult.response.gatewayCode}
          </Descriptions.Item>
          <Descriptions.Item label="Order ID">
            {!!billUp ? billUp.orderId : ''}
          </Descriptions.Item>
          <Descriptions.Item label="Transactions Time">
            {moment(
              paymentType === 'wallet' ? paymentResult.transaction_time : paymentResult.order.creationTime
            ).format('DD MMMM YYYY, HH:mm:ss')}
          </Descriptions.Item>
        </Descriptions>
        <Button type="primary" size="large" className="margin-top" block onClick={() => finish()}>
          Finish
        </Button>
      </Card>
      }

    </div>
  );
}
