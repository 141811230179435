import React from "react";
import { useRouteMatch, Route, Switch } from "react-router-dom";
import RouteGuard from "router/routeGuard";
import CustomerInfo from 'pages/easi-recharge/customerInfo';
import OrderReview from 'pages/easi-recharge/orderReview';
import NotFound from "pages/notFound";

export default function RouteEasiRecharge() {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/customer-info`} component={CustomerInfo} />
      <RouteGuard path={`${path}/order-review`} component={OrderReview} />
      <Route path="*" component={NotFound}/>
    </Switch>
  );
}
