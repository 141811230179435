import storage from 'helpers/storage';
import moment from 'moment';

const user = storage.get('user', {});
const isLogined = Object.keys(user).length > 0 &&
                  moment(user.expiry_date) > moment()

const initState = {
  isLogined: isLogined,
  user: {
    ...(isLogined ? user : {}),
    rep_id: isLogined ? user.rep_id : 1
  }
}

if(!isLogined) storage.remove();

const userReducer = (state = initState, action) => {
  switch (action.type) {
    case 'USER_LOGINED':
      return {
        ...state,
        user: action.payload,
        isLogined: true
      }
    case 'USER_LOGOUT':
      return {
        ...initState,
        isLogined: false
      };
    default: return state
  }
}

export default userReducer


