import React, { useEffect, useState } from "react";
import { Row, Col, Select, Button, Form, InputNumber } from "antd";
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import api from 'helpers/api';
import UpperFirst from "helpers/upperFirst";

const { Option } = Select;

export default function ChoosePackage({data, form, customerType, rep_id}) {
  const [catalog, setCatalog] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [count] = useState(1);

  const onLoad = () => {
    let params = {
      tag: ["143"],
      customer_type: customerType,
      rep_id: rep_id,
      sales_channel: 7
    }

    api.post('order_portal/getTopUpVoucher', params)
      .then(result => {
        setCatalog(result.data);
      })
      .catch(error => {
        console.log(error);
      })
  }

  const onSelectedProduct = () => {
    let price = !!selectedProduct.price_amount ? Number(selectedProduct.price_amount.replace(/[^0-9.-]/g, "")) : 0

    form.setFieldsValue({
      name: selectedProduct.item_name,
      product_price: price,
      product_total_price: price * count
    })
  }

  const onCount = () => {
    let price = !!selectedProduct.price_amount ? Number(selectedProduct.price_amount.replace(/[^0-9.-]/g, "")) : 0

    form.setFieldsValue({
      product_count: count,
      product_total_price: price * count
    })
  }

  useEffect(onLoad, []);
  useEffect(onSelectedProduct, [selectedProduct]);
  useEffect(onCount, [count]);

  return (
    <Form
      form={form}
      initialValues={!!data
        ? data
        : {
            product_count: count,
            product_price: 0,
            product_total_price: 0
          }
      }
    >
      <Row gutter={16}>
        <Col xs={24} md={8}>
          <Form.Item
            name="product"
            rules={[
              { required: true, message: 'Package is required' }
            ]}
          >
            <Select
              size="large"
              placeholder="Select Package*"
              notFoundContent="No data"
              onSelect={val => setSelectedProduct(
                catalog.find(obj => obj.bundle_item_id === Number(val))
              )}
            >
              {catalog.map((d) => (
                <Option key={d.bundle_item_id}>
                  {UpperFirst(d.item_name)} | {d.price_amount}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item hidden name="name"><InputNumber /></Form.Item>
          <Form.Item hidden name="product_price"><InputNumber /></Form.Item>
          <Form.Item hidden name="product_total_price"><InputNumber /></Form.Item>
          <Form.Item hidden name="product_count"><InputNumber /></Form.Item>
        </Col>
      </Row>  
    </Form>
  );
}
