import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Row, Col, Form, Input, Select, DatePicker } from 'antd';
import { MailOutlined, LoadingOutlined } from '@ant-design/icons';
import { Context } from 'state/store';
import moment from 'moment';
import useDebounce from 'hook/useDebounce';
import sourceAction from 'state/actions/sourceAction';
import icEffect from 'helpers/icEffect';

const allowAge = 12;
const { Option } = Select;
const star = (type) => {
  if(type === 'required') return '*';
  return '';
}
const disabledDate = (current) => {
  return current && current > moment().endOf('day');
}
const disabledDateArrival = (current) => {
  return current && current < moment().endOf('day');
}

export default function CustomerDetail({data, form, field, customerType}) {
  const { t } = useTranslation();
  const birthdateRef = useRef();
  const [state, dispatch] = useContext(Context);
  // const {countryList, customerClassType} = state.sourceReducer;
  const {countryList} = state.sourceReducer;
  const [autofillNationality, setAutofillNationality] = useState(true);
  const [identityNumber, setIdentityNumber] = useState('');
  const [hideOriginCountry, setHideOriginCountry] = useState(true);
  const [isYellowRedCard, setIsYellowRedCard] = useState(false);
  const [pickerOpen, setPickerOpen] = useState(false);
  const [pickerType, setPickerType] = useState('year');
  const [allowFinExpiry, setAllowFinExpiry] = useState(3);
  const debounceIdentityNumber = useDebounce(identityNumber, 500);
  const [nationality, setNationality] = useState('');
  const [autofillIdentityNumber, setAutofillIdentityNumber] = useState(false);

  const panelChange = () => {
    if(pickerType === 'month') {
      setPickerType('date')
    }
  }

  const panelClose = open => {
    if(!open && pickerType === 'date') {
      setPickerOpen(false);
    }
  }

  const datePick = () => {
    if(pickerType === 'year') {
      setPickerType('month');
    } else if(pickerType === 'month') {
      setPickerType('date')
    } else {
      setPickerOpen(false);
    }
  }

  // const onSelectCusClassType = val => {
  //   const classType = customerClassType.find(obj => obj.id === val)
  //   form.setFieldsValue({
  //     class_type: classType.name
  //   });
  // }
  
  const fillNationality = () => {
    //autofill nationality
    // const nationality = icEffect.nationality(identityNumber)

    if(!!nationality) {
      setAutofillNationality(true);
      form.setFieldsValue({
        nationality: nationality
      });
    } else {
      setAutofillNationality(!identityNumber && !!nationality);
      form.setFieldsValue({
        nationality: !!data ? data.nationality : null
      });
    }

    // IC yellow or red card
    const isYellowRed = icEffect.yellowRedCard(identityNumber)
    setIsYellowRedCard(isYellowRed)

    // show hide origin country
    const originCountry = icEffect.originCountry(identityNumber)
    setHideOriginCountry(!originCountry)

    return () => {
      // skip when init render
      form.validateFields(['fin_expiry_date'])
    }
  }
  
  const onLoad = () => {
    const allowCustomerType = ['MOBI-PREPAID', 'RES', 'TOURIST'];
    if(allowCustomerType.includes(customerType)) {
      setAllowFinExpiry(0);
    }

    if(!countryList.length) {
      sourceAction.countryList(dispatch);
    }

    if(!!data?.identity_number) {
      setIdentityNumber(data.identity_number)
      setAutofillIdentityNumber(true);
    }

    if(!!data?.nationality) {
      setNationality(data.nationality)
    }

    // if(!customerClassType.length) {
    //   sourceAction.customerClassType(dispatch);
    // }
  }
  
  useEffect(onLoad, []);
  useEffect(fillNationality, [debounceIdentityNumber]);

  return (
    <Form
      form={form}
      initialValues={!!data ? data : {salutation: "Mr"}}
    >
      <Row gutter={16}>
        {!!field.salutation &&
          <Col xs={24} md={3} >
            <Form.Item name="salutation">
              <Select size="large">
                <Option value="Mr">Mr</Option>
                <Option value="Ms">Ms</Option>
              </Select>
            </Form.Item>
          </Col>
        }
        
        {(!!field.full_name || !!field.passport_full_name) &&
          <Col xs={24} md={8}>
            <Form.Item
              name="full_name"
              rules={[
                { 
                  required: field.full_name || field.passport_full_name === 'required',
                  message: `${field.full_name ? 'Full Name' : 'Passport Full Name'} is required` 
                },
                { 
                  min: 3,
                  message: `${field.full_name ? 'Full Name' : 'Passport Full Name'} must be at least 3 characters`
                }
              ]}
            >
              <Input
                size="large"
                placeholder={
                  !!field.passport_full_name 
                  ? t('inputPassportFullname') + star(field.passport_full_name)
                  : t('inputFullname') + star(field.full_name)
                }
              />
            </Form.Item>
          </Col>
        }
        
        {(!!field.identity_number || !!field.passport_number) &&
          <Col xs={24} md={8}>
            <Form.Item
              name="identity_number"
              rules={[
                {
                  required: field.identity_number || field.passport_number === 'required',
                  message: `${field.identity_number ? 'Identity Number' : 'Passport Number'} is required` 
                },
                { 
                  max: 20,
                  message: `${field.identity_number ? 'Identity Number' : 'Passport Number'} no more than 20 characters`
                },
                {
                  pattern: new RegExp(/^[a-zA-Z0-9]+$/g),
                  message: "Invalid format, just Alphanumeric is allowed"
                }
              ]}
            >
              <Input
                size="large"
                disabled={autofillIdentityNumber}
                onChange={({target}) => setIdentityNumber(target.value)}
                placeholder={
                  !!field.passport_number
                  ? t('inputPassportNumber') + star(field.passport_number)
                  : t('inputIdentityNumber') + star(field.identity_number)
                }
              />
            </Form.Item>
          </Col>
        }
        
        {(!!field.fin_expiry_date) &&
          <Col xs={24} md={5}>
            <Form.Item
              name="fin_expiry_date"
              rules={[
                {
                  required: field.fin_expiry_date === 'required',
                  message: `${field.passport_number ? t('inputIdentityExpiry') : t('inputIdentityExpiry')} is required` 
                },
                { validator: async (_, val) => !val || isYellowRedCard || allowFinExpiry <= (Math.abs(moment().diff(val, 'months')))
                    ? Promise.resolve()
                    : Promise.reject(`Expiration not less than ${allowFinExpiry} month`)
                }
              ]}
            >
              <DatePicker
                size="large"
                className="width-full"
                placeholder={
                  !!field.passport_number
                  ? t('inputIdentityExpiry') + (isYellowRedCard ? '' : star(field.fin_expiry_date))
                  : t('inputIdentityExpiry') + (isYellowRedCard ? '' : star(field.fin_expiry_date))
                }
                format="DD MMMM YYYY"
                disabledDate={disabledDateArrival}
              />
            </Form.Item>
          </Col>
        }
      </Row>

      <Row gutter={16}>
        {!!field.date_of_arrival &&
          <Col xs={24} md={12}>
            <Form.Item
              name="date_of_arrival"
              rules={[
                { required: field.date_of_arrival === 'required', message: 'Date of Arrival is required' }
              ]}
            >
              <DatePicker
                size="large"
                className="width-full"
                placeholder={t('inputDateOfArrival') + star(field.date_of_arrival)}
                format="DD MMMM YYYY"
                disabledDate={disabledDateArrival}
              />
            </Form.Item>
          </Col>
        }
        
        {!!field.flight_number &&
          <Col xs={24} md={12}>
            <Form.Item
              name="flight_number"
              rules={[
                { required: field.flight_number === 'required', message: 'Flight Number is required' }
              ]}
            >
              <Input size="large" placeholder={t('inputFlightNumber') + star(field.flight_number)} type="text" />
            </Form.Item>
          </Col>
        }
      </Row>

      <Row gutter={16}>
        {!!field.email &&
          <Col xs={24} md={
            !field.mobile_phone || !field.phone || !field.work_phone ? 12 : 6
          }>
            <Form.Item
              name="email"
              rules={[
                { required: field.email === 'required', message: 'Email is required' },
                { type: 'email', message: 'Email is not a valid' }
              ]}
            >
              <Input
                size="large"
                type="email" 
                placeholder={t('inputEmail') + star(field.email)}
                suffix={<MailOutlined className="ant-picker-suffix" />}
              />
            </Form.Item>
          </Col>
        }
        
        {!!field.mobile_phone &&
          <Col xs={24} md={6}>
            <Form.Item
              name="mobile_phone"
              rules={[
                { 
                  required: field.mobile_phone === 'required',
                  message: 'Mobile Phone is required'
                },
                {
                  pattern: new RegExp(/^(6737\d{6}|6738\d{6}|673228\d{4}|673229\d{4}|7\d{6}|8\d{6}|228\d{4}|229\d{4})$/g),
                  message: "Invalid format number"
                }
              ]}
            >
              <Input name="mobile_phone" size="large" placeholder={t('inputMobile') + star(field.mobile_phone)} />
            </Form.Item>
          </Col>
        }
        
        {!!field.phone &&
          <Col xs={24} md={6}>
            <Form.Item
              name="phone"
              rules={[
                { required: field.phone === 'required', message: 'Land Line Number is required' },
                { pattern: '^[0-9]+$', message: "Invalid format number" },
                { min: 7, max: 10, message: "Land Line Number must be between 7 and 10 characters" }
              ]}
            >
              <Input size="large" placeholder={t('inputPhoneHome') + star(field.phone)} />
            </Form.Item>
          </Col>
        }
        
        {!!field.work_phone &&
          <Col xs={24} md={6}>
            <Form.Item
              name="work_phone"
              rules={[
                { required: field.work_phone === 'required', message: 'Office Phone is required' },
                { pattern: '^[0-9]+$', message: "Invalid format number" },
                { min: 7, max: 10, message: "Office Phone must be between 7 and 10 characters" }
              ]}
            >
              <Input size="large" placeholder={t('inputPhoneOffice') + star(field.work_phone)} />
            </Form.Item>
          </Col>
        }
      </Row>

      <Row gutter={16}>
        {!!field.birth_date &&
          <Col xs={24} md={8}>
            <Form.Item
              name="birth_date"
              rules={[
                { required: field.birth_date === 'required', message: 'Date of Birth is required' },
                { validator: async (_, val) => !val || allowAge <= moment().diff(val, 'years') 
                    ? Promise.resolve()
                    : Promise.reject(`Minimum Age is ${allowAge} years old`)
                }
              ]}
            >              
              <DatePicker
                ref={birthdateRef}
                size="large"
                className="width-full"
                inputReadOnly
                open={pickerOpen}
                showToday={false}
                picker={pickerType}
                format="DD MMMM YYYY"
                placeholder={t('inputDateOfBirth') + star(field.birth_date)}
                disabledDate={disabledDate}
                defaultPickerValue={moment().subtract(25, 'years')}
                onClick={() => setPickerOpen(true) }
                onChange={datePick}
                onPanelChange={panelChange}
                onOpenChange={panelClose}
              />
            </Form.Item>
            
          </Col>
        }
        
        {!!field.nationality &&
          <Col xs={24} md={8}>
            <Form.Item
              name="nationality"
              hidden={field.nationality === 'hidden'}
              rules={[
                { 
                  required: field.nationality === 'required' || field.nationality === 'hidden',
                  message: 'Nationality is required'
                }
              ]}
            >
              <Select
                size="large"
                disabled={autofillNationality}
                placeholder={t('inputSelect') +' '+ t('inputNationality') + star(field.nationality)}
                onChange={val => setHideOriginCountry(val === 'Brunei Darussalam')}
              >
                <Option value="Brunei Darussalam">Brunei Darussalam</Option>
                <Option value="Permanent Resident">Permanent Resident</Option>
                <Option value="Foreigner">Foreigner</Option>
              </Select>
            </Form.Item>
          </Col>
        }

        {!!field.origin_country && !hideOriginCountry &&
          <Col xs={24} md={8}>
            <Form.Item
              name="origin_country"
              rules={[
                { 
                  required: field.origin_country === 'required',
                  message: 'Our Country is required'
                }
              ]}
            >
              <Select
                showSearch
                size="large"
                placeholder={t('inputSelect') +' '+ t('inputOriginCountry') + star(field.origin_country)}
              >
                {countryList.map(d => <Option key={d.name}>{d.name}</Option>)}
              </Select>
            </Form.Item>
          </Col>
        }
      </Row>

      {/* <Row gutter={16}>
        {!!field.class_type &&
          <Col xs={24} md={6} >
            <Form.Item name="class_type_id">
              <Select
                size="large"
                placeholder={t('inputSelect') +' '+ t('inputClassType') + star(field.class_type)}
                onSelect={val => onSelectCusClassType(val)}
              >
                {customerClassType.map(obj =>
                  <Option key={obj.id} value={obj.id}>{_.startCase(_.toLower(obj.name))}</Option>
                )}
              </Select>
            </Form.Item>
            <Form.Item hidden name="class_type"><Input /></Form.Item>
          </Col>
        }
      </Row> */}
    </Form>
  );
};