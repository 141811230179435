import i18n from 'i18next';
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: {
          step1: 'Package Plan',
          step2: 'Customer Information',
          step3: 'Finish',
          btnBack: 'Back',
          btnNext: 'Next',
          btnProceed: 'Proceed',
          btnClear: 'Clear',
          btnSubmit: 'Submit',
          btnPay: 'Pay',
          btnPayNow: 'Pay now',
          btnPayWallet: 'Pay with wallet',
          btnFinish: 'Finish',
          btnYes: 'Yes',
          btnNo: 'No',
          inputUsername: 'Username',
          inputPassword: 'Password',
          inputExistingProvider: 'Your existing service Provider',
          inputFullname: 'Full Name',
          inputPassportFullname: 'Passport Full Name',
          inputIdentityNumber: 'IC Number / Passport Number',
          inputPassportNumber: 'Passport Number',
          inputIdentityExpiry: 'IC / Passport Expiration Date',
          inputPassportExpiry: 'Passport Expiration Date',
          inputFlightNumber: 'Flight Number',
          inputEmail: 'Email',
          inputMobile: 'Mobile Number',
          inputPhoneHome: 'Land Line Number',
          inputPhoneOffice: 'Office Phone',
          inputDateOfBirth: 'Date of Birth',
          inputDateOfArrival: 'Date of Arrival',
          inputNationality: 'Nationality',
          inputClassType: 'Classification Type',
          inputOriginCountry: 'Country',
          inputDeliveryType: 'delivery type',
          inputDeliveryDate: 'Delivery / Pick Up Date',
          inputStoreSelect: 'Select Store',
          inputDistrict: 'District',
          inputSimpang: 'Simpang',
          inputMukim: 'Mukim',
          inputVillage: 'Village',
          inputStreet: 'Street',
          inputPostalCode: 'Postal Code',
          inputPostalCodeOther: 'Postal Code Other',
          inputBlock: 'Block',
          inputHomeNumber: 'House number',
          inputBuildingName: 'Building Name',
          inputFloor: 'Floor',
          inputUnit: 'Unit',
          inputSelect: 'Select',
          lblServices: 'Please choose your service',
          lblExistingCustomer: 'Do you have an existing service with another provider?',
          lblSectionCustomerData: 'Customer Details',
          lblSectionBillingInfo: 'Billing Info',
          lblSectionBillingAddress: 'Billing Address',
          lblSectionBillingCycle: 'Billing Cycle',
          lblSectionServiceAddress: 'Service Address',
          lblSectionPickup: 'Pick Up Address',
          lblSectionDeliveryPickup: 'Delivery / Pick Up',
          lblSectionSimCardType: 'SIM Card Type',
          lblSectionProduct: 'Choose Package',
          lblSectionProductInternet: 'Internet Package Plan',
          lblSectionProductSimCard: 'SIM Option',
          lblSectionDevice: 'Device',
          lblSectionAddon: 'Addon',
          lblSectionOptional: 'Option',
          lblSectionChooseNumber: 'Choose Number',
          lblAutomaticNumber: 'Automatic Number Selection',
          lblMobileNumber: 'Mobile Number',
          lblImsiNumber: 'IMSI Number',
          lblSummary: 'Summary',
          lblSummaryMainDefault: 'Package',
          lblSummaryMainInternet: 'Internet',
          lblSummaryMainSim: 'SIM Card Plan',
          lblSummaryVoice: 'Voice',
          lblSummaryDeliveryFee: 'Delivery Fee',
          lblSummaryDeviceDeposit: 'Device Deposit',
          lblSummaryDevice: 'Device',
          lblSummaryAddon: 'Addon',
          lblSummarySimCard: 'SIM Option',
          lblSummaryOther: 'Other',
          lblSummaryTotal: 'Total',
          lblDataCustomer: 'Customer Data',
          lblDataBillingAddress: 'Billing Address',
          lblDataServiceAddress: 'Service Address',
          lblDataDeliveryAddress: 'Delivery Address',
          lblDataSummaryPackage: 'Package Summary',
          lblDataNumberSelection: 'Your Number Selection',
          lblDataDelivery: 'Delivery',
          lblDataPickupAddress: 'Pick Up Address',
          lblDataDeliveryDate: 'Delivery Date',
          lblDataPickupDate: 'Pick up Date',
          lblThanks: 'Thank you!',
          lblThanksDescription: 'Payment link has sent to your email',
          lblThanksDescriptionPayment: 'Your Payment has been received',
          lblThanksDescriptionWithoutPayment: 'We will contact you for verification and installation confirmation.',
          lblTermUpload: '*File size max. 10MB per document. Required as a condition of service registration',
          lblOtpGetTheCode: 'Click the following button to get a verification code via SMS and continue the registration process.',
          lblOtpVerification:'Verification Code',
          lblTermCondition1: 'I have read and accept the',
          lblTermCondition2: 'terms & conditions',
          lblTermCondition3: 'and agree to register for DST services.',
          msgSystemTrouble: 'Sorry... System have trouble!',
          deviceModalTitle: 'Select Device',
          lblIndentityNumber: 'Please enter IC Number / Passport Number',
        }
      },
      bn: {
        translations: {
          step1: 'Pilih Pakej',
          step2: 'Butir-butir Pemohon',
          step3: 'Selesai',
          btnBack: 'Kembali',
          btnNext: 'Seterusnya',
          btnProceed: 'Teruskan',
          btnClear: 'Padam',
          btnSubmit: 'Hantar',
          btnPay: 'Bayar',
          btnPayNow: 'Bayar sekarang',
          btnPayWallet: 'Bayar dengan Dompet',
          btnFinish: 'Selesai',
          btnYes: 'Ya',
          btnNo: 'Tidak',
          inputUsername: 'Nama Pengguna',
          inpurPassword: 'Kata Laluan',
          inputExistingProvider: 'Penyedia perkhidmatan Awda yang ada',
          inputFullname: 'Nama Penuh',
          inputPassportFullname: 'Pasport Nama Penuh',
          inputIdentityNumber: 'Nombor Kad Pengenalan / no pasport',
          inputPassportNumber: 'Pasport Nombor',
          inputIdentityExpiry: 'IC / Passport Expiration Date',
          inputPassportExpiry: 'Passport Expiration Date',
          inputFlightNumber: 'Nombor Penerbangan',
          inputEmail: 'E-mel',
          inputMobile: 'No. Telefon(HP)',
          inputPhoneHome: 'No. Telefon(Rumah)',
          inputPhoneOffice: 'No. Telefon(Pejabat)',
          inputDateOfBirth: 'Tarikh Lahir',
          inputDateOfArrival: 'Tarikh Ketibaan',
          inputNationality: 'Kewarganegaraan',
          inputClassType: 'Classification Type',
          inputOriginCountry: 'Negara',
          inputDeliveryType: 'jenis penghantaran',
          inputDeliveryDate: 'Penghantaran / tarikh pengambilan',
          inputStoreSelect: 'Pilih kedai',
          inputDistrict:'Daerah',
          inputSimpang: 'Simpang',
          inputMukim: 'Mukim',
          inputVillage: 'Village',
          inputStreet: 'Jalan',
          inputPostalCode: 'Poskod',
          inputPostalCodeOther: 'Poskod Lain',
          inputBlock: 'Blok',
          inputHomeNumber: 'No. Rumah',
          inputBuildingName: 'Nama Bangunan',
          inputFloor: 'Tingkat',
          inputUnit: 'Unit',
          inputSelect: 'Pilih',
          lblServices: 'Sila pilih perkhidmatan anda',
          lblExistingCustomer: 'Adakah Awda mempunyai perkhidmatan yang ada dengan penyedia lain?',
          lblSectionCustomerData: 'Butir-butir Pemohon',
          lblSectionBillingInfo: 'Informasi Tagihan',
          lblSectionBillingAddress: 'Alamat Bil',
          lblSectionBillingCycle: 'Siklus Tagihan',
          lblSectionServiceAddress: 'Alamat Pemasangan',
          lblSectionPickup: 'Tempat Pengambilan',
          lblSectionDeliveryPickup: 'Penghantaran / Ambil Sendiri',
          lblSectionSimCardType: 'Pilih Jenis SIM Kad',
          lblSectionProduct: 'Pilih Pakej',
          lblSectionProductInternet: 'Pilih Pakej Internet',
          lblSectionProductSimCard: 'Pilihan SIM',
          lblSectionDevice: 'Peranti',
          lblSectionOptional: 'Pilihan',
          lblSectionChooseNumber: 'Pilih Nombor',
          lblAutomaticNumber: 'Pilihan SIM secara automatik',
          lblMobileNumber: 'Nombor Telefon',
          lblImsiNumber: 'Nombor IMSI',
          lblSummary: 'Ringkasan Pelan',
          lblSummaryMainDefault: 'Pakej',
          lblSummaryMainInternet: 'Pelan Internet',
          lblSummaryMainSim: 'Pelan SIM Kad',
          lblSummaryVoice: 'Panggilan',
          lblSummaryDeliveryFee: 'Bayaran Penghataran',
          lblSummaryDeviceDeposit: 'Device Deposit',
          lblSummaryDevice: 'Peranti',
          lblSummaryAddon: 'Tambahan',
          lblSummarySimCard: 'Pilih SIM Card',
          lblSummaryOther: 'Lain-lain',
          lblSummaryTotal: 'Jumlah',
          lblDataCustomer: 'Data Pelanggan',
          lblDataBillingAddress: 'Alamat Bill',
          lblDataServiceAddress: 'Alamat Pemasangan',
          lblDataDeliveryAddress: 'Alamat Penghantaran',
          lblDataSummaryPackage: 'Package Summary',
          lblDataNumberSelection: 'Nombor Pilihan Awda',
          lblDataDelivery: 'Penghantaran',
          lblDataPickupAddress: 'Tempat Pengambilan',
          lblDataDeliveryDate: 'Tarikh penghantaran',
          lblDataPickupDate: 'Tarikh pengambilan',
          lblThanks: 'Terima Kasih!',
          lblThanksDescription: 'Pautan pembayaran telah dihantar ke e-mel anda',
          lblThanksDescriptionPayment: 'Pembayaran anda telah diterima',
          lblThanksDescriptionWithoutPayment: 'Kami akan segera menghubungi awda untuk pengesahan dan makluman lanjut mengenai jadual pemasangan.',
          lblTermUpload: '*Maxima muat naik 10MB/document. Diperlukan sebagai syarat pendaftaran perkhidmatan',
          lblOtpGetTheCode: 'Sila klik pada pautan berikut untuk mendapatkan kod pengesahan melalui SMS dan melanjutkan process pendaftaran.',
          lblOtpVerification:'Kod Pengesahan',
          lblTermCondition1: 'Saya bersetuju dengan',
          lblTermCondition2: 'terma & syarat',
          lblTermCondition3: 'yang berkenaan dan saya bersedia mendaftar untuk perkhidmatan DST.',
          deviceModalTitle: 'Pilih Peranti',
          lblIndentityNumber: 'Sila masukkan Nombor IC / Nombor Passport',
        }
      }
    },
    debug: false,
    fallbackLng: 'en',
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false, // we use content as key
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
