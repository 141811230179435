import _ from "lodash";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { def } from "config/const";
import { Context } from "state/store";
import orderAction from "state/actions/orderAction";
import sourceAction from "state/actions/sourceAction";
import { addSummary, removeSummary } from "helpers/buildSummary";

const useDependency = () => {
  const { t } = useTranslation();
  const [state, dispatch] = useContext(Context);
  const orderReducer = state.orderReducer;
  const {customerType} = orderReducer.customerInfo;
  const {summary, packagePlan} = orderReducer;

  const plan = async (nationality) => {
    let tmpSummary = [...summary]
        tmpSummary = removeSummary(def.group.promo, tmpSummary, 'group');
        tmpSummary = removeSummary(def.group.other, tmpSummary, 'group');
        tmpSummary = removeSummary(def.group.voice, tmpSummary, 'group');
        
    const result = await sourceAction.dependencyCatalog(dispatch, orderReducer, nationality, 'plan')

    // get bundle contract
    if(result.bundle_contract.length > 0) {
      let bundleContractId = result.bundle_contract[0].id
      orderAction.bundleContract(dispatch, bundleContractId);
    }

    // get member
    let child = [];
    if(!!result.member_item){
      result.member_item.forEach(obj => {
        if(obj.tag_id !== def.groupTag.main && obj.tag_id !== def.groupTag.internet) {
          if(customerType == 'RES') {
            tmpSummary.push({
              ...obj,
              item_id: null,
              group: def.group.other
            })
          } else {
            child.push({
              ...obj
            })
          }
        }
      });
    }

    // get deposit
    if(!!result.deposit){
      result.deposit.forEach(obj => {
        if(customerType == 'RES') {
          tmpSummary.push({
            ...obj,
            name: 'DEPOSIT',
            isCount: true,
            item_id: null,
            group: def.group.other
          })
        } else {
          child.push({
            ...obj,
            name: 'Deposit',
            isCount: true
          })
        }
      });
    }
    
    // sub item of main
    if(child.length > 0) {
      const keyMain = _.findKey(tmpSummary, {group: 1});
      tmpSummary[keyMain]['child'] = child;
    }
    
    // get relation
    let relation = typeof result.relation === 'object'
      ? Object.values(result.relation)
      : result.relation

    // populate relation
    relation.forEach(async obj => {
      if(def.groupTag.voice.includes(obj.tag_id) && customerType == 'RES'){
        tmpSummary.push({
          ...obj,
          group: def.group.voice,
          isCount: true
        })
      } else if(def.groupTag.device.includes(obj.tag_id)){
        tmpSummary.push({
          ...obj,
          group: def.group.device,
          isCount: true
        })
      } else if(def.groupTag.promo.includes(obj.tag_id) && customerType == 'RES') {
        const data = await sourceAction.promotionMember(dispatch, obj.id)
        let promo = data.member_item;

        tmpSummary.push({
          ...obj,
          child: promo,
          group: def.group.promo,
          isCount: true
        })
      } else {
        tmpSummary.push({
          ...obj,
          group: def.group.other,
          isCount: true
        })
      }
    });

    orderAction.summary(dispatch, addSummary(tmpSummary));
  }

  const device = async (nationality) => {
    if(!!packagePlan.device) {
      let tmpSummary = [...summary]
      const dependency = await sourceAction.dependencyCatalog(dispatch, orderReducer, nationality, 'device')

      // get relation
      if(dependency.relation != null){
        dependency.relation.map(obj =>
          tmpSummary.push({
            ...obj,
            isCount: true,
            group: def.group.device,
          })
        );
      }

      // get device deposit
      if(dependency.deposit != null){
        dependency.deposit.forEach(obj => {
          tmpSummary.push({
            ...obj,
            isCount: true,
            name: t('lblSummaryDeviceDeposit'),
            group: def.group['device deposit'],
          })
        });
      }

      orderAction.summary(dispatch, addSummary(tmpSummary));
    }
  }

  return {
    plan,
    device
  }
};

export default useDependency;