import React, { useContext, useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Row, Col, Checkbox, Descriptions, Typography, message, Card } from "antd";
import * as moment from "moment";

import { Context } from "state/store";
import { easiRechargeParams } from "helpers/paramsOrder";
import ObjectValidator from "helpers/objectValidator";
import api from "helpers/api";
import paymentAction from "state/actions/paymentAction";
import systemAction from "state/actions/systemAction";
import orderAction from "state/actions/orderAction";
import Summary from "components/summary";
import ReviewCustomerInfo from "./components/reviewCustomerInfo";
import Style from './style.module.scss';

const { Text } = Typography;

export default function OrderReview() {
  const { t } = useTranslation();
  const history = useHistory();
  const [state, dispatch] = useContext(Context);
  const {user} = state.userReducer;
  const order = state.orderReducer;
  const [errorMessage, setErrorMessage] = useState({})
  const [paymentResult, setPaymentResult] = useState(null);
  const [tnc, setTnc] = useState(false)

  const validate = () => {
    const schema = {
      tnc: { require: () => !!tnc ? '' :'Please confirm Terms & Conditions' }
    }
    const error = ObjectValidator(schema)
    setErrorMessage(error)
    return error
  }

  const submit = () => {
    if(!!validate()) return;
    systemAction.startLoading(dispatch);

    const params = {
      msisdn: order.customerInfo.msisdn,
      voucher_id: order.packagePlan.product,
      user_id: user.id,
      session_id: user.session_id,
    }
    api.post('order_portal/wallet_easi_recharge', params, { timeout: 15000 })
      .then(result => {
        systemAction.stopLoading(dispatch)
        setPaymentResult(result.data)
      })
      .catch(error => {
        console.log(error);
        systemAction.stopLoading(dispatch)
        message.error(t('msgSystemTrouble'), 3)
      })
  }

  const finish = () => {
    orderAction.reset(dispatch);
    paymentAction.reset(dispatch);
    systemAction.resetPage(dispatch);
    history.push('/')
  }

  const onLoad = () => {
    systemAction.stopLoading(dispatch);
  }
  useEffect(onLoad, []);

  return (
    <div className="container">
      {!paymentResult &&
      <Row gutter={24} className="margin-bottom review">
        <Col xs={24} lg={16} className="info">
          <ReviewCustomerInfo {...order} />
        </Col>
        <Col xs={24} lg={8} className="summary">
          <Summary
            data={order.summary}
            mainLabel={t('lblSummaryMainDefault')}
            muted
          />
        </Col>
        <Col xs={24} lg={24} className="text-center">
          <Checkbox checked={tnc} onChange={() => setTnc(!tnc) }>
						<span>I have read and accept the </span>
            <Link to="/terms-and-conditions" target="_blank">
              terms &amp; conditions
            </Link>  
            <span> and agree to register for DST services</span>
          </Checkbox>
          {!!errorMessage.tnc && !tnc &&
            <p>
              <Text type="danger" className="text-small">{errorMessage.tnc}</Text>
            </p>
          }
          <Row>
            <Col xs={24} lg={24}>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                className="margin-top"
                onClick={() => submit()}
              >
                {t('btnPayWallet')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      }

      {!!paymentResult &&
      <Card className={`${Style.paymentCard} width-max-xl margin-auto`}>
        <Descriptions title="Details" size="small" column={1} bordered>
        <Descriptions.Item label="Package">
            EASI RECHARGE
          </Descriptions.Item>
          <Descriptions.Item label="Mobile Number">
          {order.customerInfo.msisdn}
          </Descriptions.Item>
          <Descriptions.Item label="Amount">
            ${order.packagePlan.product_price}
          </Descriptions.Item>
          <Descriptions.Item label="Payment Status">
            SUCCESS
          </Descriptions.Item>
          <Descriptions.Item label="Transactions Time">
            {moment(paymentResult.transaction_time).format('DD MMMM YYYY, HH:mm:ss')}
          </Descriptions.Item>
        </Descriptions>
        <Button type="primary" size="large" className="margin-top" block onClick={() => finish()}>
          Finish
        </Button>
      </Card>
      }

    </div>
  );
}
