import React, { useEffect, useState } from "react";
import { Row, Col, Select, Button, Form, InputNumber } from "antd";
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import api from 'helpers/api';
import UpperFirst from "helpers/upperFirst";

const { Option } = Select;

export default function ChoosePackage({data, form, customerType, rep_id}) {
  const [catalog, setCatalog] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [count, setCount] = useState(1);
  const minCount = 1;
  const maxCount = 50;

  const onLoad = () => {
    let params = {
      tag: ["124"],
      customer_type: customerType,
      rep_id: rep_id,
      sales_channel: 7
    }

    api.post('order_portal/get_order_catalog', params)
      .then(result => {
        setCatalog(result.data);
      })
      .catch(error => {
        console.log(error);
      })
  }

  const onSelectedProduct = () => {
    let price = !!selectedProduct.price ? Number(selectedProduct.price) : 0

    form.setFieldsValue({
      name: selectedProduct.name,
      product_price: price,
      product_total_price: price * count
    });
  }

  const onCount = () => {
    let price = !!selectedProduct.price ? Number(selectedProduct.price) : 0

    form.setFieldsValue({
      product_count: count,
      product_total_price: price * count
    });
  }

  useEffect(onLoad, []);
  useEffect(onSelectedProduct, [selectedProduct]);
  useEffect(onCount, [count]);

  return (
    <Form
      form={form}
      initialValues={!!data
        ? data
        : {
            product_count: count,
            product_price: 0,
            product_total_price: 0
          }
      }
    >
      <Row gutter={16}>
        <Col xs={24} md={8}>
          <Form.Item
            name="product"
            rules={[
              { required: true, message: 'Package is required' }
            ]}
          >
            <Select
              size="large"
              placeholder="Select Package*"
              notFoundContent="No data"
              onSelect={val => setSelectedProduct(
                catalog.find(obj => obj.id === Number(val))
              )}
            >
              {catalog.map((d) => (
                <Option key={d.id}>
                  {UpperFirst(d.name)} | ${d.price}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item hidden name="name"><InputNumber /></Form.Item>
          <Form.Item hidden name="product_price"><InputNumber /></Form.Item>
          <Form.Item hidden name="product_total_price"><InputNumber /></Form.Item>
        </Col>
        <Col xs={24} md={4} className="flex">
          <Button
            size="large"
            shape="circle"
            icon={<MinusOutlined />} 
            onClick={() => setCount(
              count > minCount ? (count - 1) : count
            )}
          />
          <Form.Item className="flex-auto" name="product_count">
            <InputNumber
              className="width-full hide-handler text-center"
              size="large"
              onChange={val => setCount(val)}
              min={minCount}
              max={maxCount}
            />
          </Form.Item>
          <Button
            size="large"
            shape="circle"
            icon={<PlusOutlined />} 
            onClick={() => setCount(
              count < maxCount ? (count + 1) : count
            )}
          />
        </Col>
      </Row>  
    </Form>
  );
}
