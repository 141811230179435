import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Row, Col, Form, Divider, Switch, Button } from "antd";
import { CheckOutlined, CloseOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';

import { addSummary, removeSummary } from "helpers/buildSummary";
import { Context } from "state/store";
import { def } from "config/const";
import api from "helpers/api";
import useCheckMark from "hook/useCheckMark";
import useDependency from "hook/useDependency";
import deliveryDate from 'helpers/deliveryDate';
import systemAction from "state/actions/systemAction";
import orderAction from "state/actions/orderAction";
import StepIndicator from "components/stepIndicator";
import PanelTimeline from "components/panelTimeline";
import CustomerDetail from "components/customerDetail";
import DeliveryPickup from "components/deliveryPickup";
import AddressForm from "components/addressForm";
import Summary from "components/summary";
import Sticky from "sticky-js";

const customerFormField = {
  salutation: true,
  full_name: 'required',
  identity_number: 'required',
  email: 'required',
  mobile_phone: 'required',
  phone: true,
  work_phone: true,
  birth_date: 'required',
  nationality: 'required',
  origin_country: 'required',
  fin_expiry_date: 'required'
}

export default function CustomerInfo() {
  const { t } = useTranslation();
  const {blackList} = useCheckMark();
  const dependency = useDependency();
  const history = useHistory();
  const [state, dispatch] = useContext(Context);
  const {publicHoliday} = state.sourceReducer;
  const {orderStep, customerInfo, summary} = state.orderReducer;
  const {isLogined, user} = state.userReducer;
  const {allowPage} = state.systemReducer;
  const [formCustomerDetail] = Form.useForm();
  const [formDeliveryPickup] = Form.useForm();
  const [formServiceAddress] = Form.useForm();
  const [formBillingAddress] = Form.useForm();
  const [sameAddress, setSameAddress] = useState(false);
  const [deliveryType, setDeliveryType] = useState(null);
  const [nationality, setNationality] = useState(null);

  const steps = [t('step1'), t('step2'), t('step3')];

  const propsCustomerDetail = {
    data: !!customerInfo ? customerInfo.customerDetail : null,
    setNationality: val => { setNationality(val) },
    customerType: customerInfo.customerType,
    form: formCustomerDetail,
    field: customerFormField
  }

  const propsInstanDelivery = {
    delivery_type: "3",
    delivery_date: deliveryDate.instanDeliveryDate(publicHoliday)
  }

  const propsDeliveryPickup = {
    data: !!customerInfo
      ? customerInfo.deliveryPickup
      : (isLogined ? propsInstanDelivery : null),
    customerType: customerInfo.customerType,
    form: formDeliveryPickup,
    deliveryType,
    setDeliveryType,
    setSameAddress
  }

  const propsServiceAddress = {
    data: !!customerInfo ? customerInfo.serviceAddress : null,
    form: formServiceAddress
  }

  const propsBillingAddress = {
    data: !!customerInfo ? customerInfo.billingAddress : null,
    form: formBillingAddress
  }

  // get error or data by form
  const submitForm = (form) => {
    return form.validateFields()
      .then(res => res)
      .catch(err => err)
  }

  const sendOrderStep = (data) => {
    let orderDetail = [];

    // order_detail
    summary.forEach(obj => {
      if(!!obj.item_id) {
        orderDetail.push(
          {item_id: obj.item_id, quantity: obj.quantity}
        )
      }
    })

    // populate data for order step
    let order = {
      ...orderStep,
      ...data.customerDetail,
      ...data.deliveryPickup,
      birth_date: data.customerDetail.birth_date.format('YYYY-MM-DD'),
      gender: data.customerDetail.salutation === 'Mr' ? 'M' : 'F',
      customer_type: customerInfo.customerType,
      order_type: data.order_type,
      order_detail: JSON.stringify(orderDetail)
    }

    // send to api order step
    orderAction.orderStep(dispatch, order, data.billingAddress, data.serviceAddress)
  }

  const getDeliveryFee = data => {
    let params = {
      tag: ['130'],
      rep_id: user.rep_id,
      customer_type: customerInfo.customerType,
      sales_channel: 7,
      type: 'delivery-item'
    }
    return api.post('order_portal/get_order_catalog', params)
  }
  

  const checkDeliveryFee = async (data, callback) => {
    let add_summary = summary
    console.log('deliveryType')
    console.log(deliveryType)

    {/*if(deliveryType === 2) {
      let delivery = await getDeliveryFee(data)
      if(delivery.data.length) {
        let delivery_fee = delivery.data[0]

        // set summary
        add_summary = addSummary([
          {
            ...delivery_fee,
            group: def.group["delivery fee"],
            isCount: true
          },
        ], summary )

        orderAction.summary(dispatch, add_summary);
      }
    }*/}

    callback(add_summary)
  }

  const clearDeliveryFee = () => {
    let remove_summary = removeSummary(def.group['delivery fee'], summary, 'group')
    orderAction.summary(dispatch, remove_summary);
  }

  const submit = async() => {
    systemAction.startLoading(dispatch);
    let error = false;
    let billingAddress = await submitForm(formBillingAddress);
    let serviceAddress = await submitForm(formServiceAddress);

    let data = {
      customerType: customerInfo.customerType,
      sameAddress,
      customerDetail: await submitForm(formCustomerDetail),
      deliveryPickup: await submitForm(formDeliveryPickup),
      billingAddress: !sameAddress
        ? billingAddress
        : serviceAddress,
      serviceAddress: deliveryType === 2 
        ? serviceAddress
        : billingAddress,
      delivery_form: customerInfo.delivery_form,
      esimFlag: customerInfo.esimFlag,
    }
    
    // check errorFields
    Object.keys(data).map(obj => 
      error = error ? error : !!data[obj].errorFields
    );

    if (!error) {
      // check black list
      const isBlackList = await blackList(data.customerDetail.identity_number);
      if(isBlackList) return;

      let step = "/mobi/order-review"
      let newAllowStep = [...allowPage, step];

      systemAction.startLoading(dispatch);
      checkDeliveryFee(data, summary => {
        sendOrderStep({...data, summary})
        systemAction.allowPage(dispatch, newAllowStep);
        orderAction.customerInfo(dispatch, data);
        history.push(step)
      })
    }else{
      systemAction.stopLoading(dispatch)
    }
  }

  const onChangeNationality = () => {
    const fetchData = async () => {
      if(!!nationality) {
        dependency.plan(nationality);
        dependency.device(nationality);
      }
    }

    fetchData();
  }

  const onLoad = () => {
    new Sticky('.sticky');
    clearDeliveryFee();
    systemAction.stopLoading(dispatch);
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }
  
  useEffect(onChangeNationality, [nationality]);
  useEffect(onLoad, []);

  return (
    <div className="container" data-sticky-container >
      <StepIndicator steps={steps} current={1} />

      <Row gutter={24} className="margin-bottom">
        <Col xs={24} md={24}>
          <PanelTimeline title={t('lblSectionCustomerData')}>
            <CustomerDetail {...propsCustomerDetail} />
          </PanelTimeline>

          {customerInfo.delivery_form === true &&
          <PanelTimeline title={t('lblSectionDeliveryPickup')}>
            <DeliveryPickup {...propsDeliveryPickup} />
            {deliveryType === 2 &&
            <>
              <Divider className="margin-sm-top" />
              <AddressForm {...propsServiceAddress}  />
            </>
            }
          </PanelTimeline>
          }
          
          <PanelTimeline title={t('lblSectionBillingAddress')} noLine>
            {deliveryType === 2 &&
              <p className="text-bold margin-bottom">
                Is the Billing Address the same as the delivery address ?
                <br></br>
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  defaultChecked={sameAddress}
                  onClick={value => setSameAddress(value)}
                />
              </p>
            }
            {!sameAddress &&
              <AddressForm {...propsBillingAddress}  />
            }
          </PanelTimeline>
          
        </Col>
        <Col xs={24}>
          <div className="flex flex-right margin-top">
            <Button type="primary" htmlType="submit" onClick={() => submit()}>
              {t('btnNext')}
              <RightOutlined />
              </Button>
          </div>
          <Button className="margin-top" type="default" onClick={() => history.goBack()}>
            <LeftOutlined />
            {t('btnBack')}
          </Button>
        </Col>
      </Row>
    </div>
  );
}
