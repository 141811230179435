import React from 'react';
import { Descriptions } from 'antd';

export default function ReviewCustomerInfo({customerInfo}) {
  const customerDetail = customerInfo;
  
  return (
    <>
      <Descriptions title="CUSTOMER DATA" size="small" column={1} bordered>
        <Descriptions.Item label="Mobile Number">
          {customerDetail.msisdn}
        </Descriptions.Item>
        <Descriptions.Item label="Subscription ID">
          {customerDetail.subscriptionId}
        </Descriptions.Item>
        
      </Descriptions>
    </>
  );
};