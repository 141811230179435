import React, { useContext } from "react";
import { notification } from "antd";
import { WarningOutlined } from "@ant-design/icons";
import { env } from "config/const";
import api from "helpers/api";
import moment from 'moment';
import { Context } from "state/store";
import orderAction from "state/actions/orderAction";

const notif = message => notification.open({
  message: 'Oops Sorry...',
  description: message,
  icon: <WarningOutlined style={{ color: 'red' }} />,
});

const useOtp = () => {
  const [state, dispatch] = useContext(Context)
  const {customerInfo, otp} = state.orderReducer

  const sendOtp = phone_number => {
    if(env.bypassOtp) {
      orderAction.otp(
        dispatch,
        { prefix: Math.floor(1000 + Math.random() * 9000) }
      );
    } else {
      api.post('order_portal/send_sms_otp', {phone_number})
        .then(({data}) => {
          if(!!data && data.status) {
            orderAction.otp(dispatch, data);
          } else {
            notif(`Send OTP to ${phone_number} failed`);
          }
        }).catch(error => {
          notif(`Send OTP to ${phone_number} failed`);
        });
    }
  }

  const verifyOtp = async (otpCode) => {
    if(env.bypassOtp) return true;

    let notExpired = otp.expiry - moment().unix();

    if(notExpired > 0) {
      try {
        let params = {
          phone_number: customerInfo.customerDetail.mobile_phone,
          pv_code: otpCode,
          pv_expiry: otp.expiry,
          pv_hash: otp.hash,
        }

        const {data} = await api.post('order_portal/verification_otp', params);
        if(!data) notif('Verification Code is incorrect');
        return !!data;

      } catch (error) {
        if(error.data === 'Verification code has expired') {
          notif('Verification Code has expired. please click the "Get Code" button!');
        } else {
          notif(error.data);
        }
      }
    } else {
      notif('Verification Code has expired. please click the "Get Code" button!');
      return false;
    }
  }

  return {
    sendOtp,
    verifyOtp
  }
};

export default useOtp;